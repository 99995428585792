import React, { useContext } from "react";
import { FaFilePdf } from "react-icons/fa";
import { AccountShell } from "src/containers";
import { useAuthSWR } from "src/helpers";
import { Transaction } from "src/models";
import { Message, CircleTickIcon, CrossIcon } from "src/components";
import api from "src/api";
import { AuthContext } from "src/contexts";

const TableHeader: React.FC<{ name: string }> = ({ name }) => (
  <th className="px-6 py-3 border-b border-gray-200 bg-gray-100 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
    {name}
  </th>
);

const TableRow: React.FC<{ tx: Transaction }> = ({ tx }) => {
  return (
    <tr>
      <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 font-medium text-gray-500">
        {new Date(tx.created).toDateString()}
      </td>
      <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
        <span className="flex items-center">
          {tx.refunded ? `$${tx.amount.toFixed(2)} (Refunded)` : `$${tx.amount.toFixed(2)}`}{" "}
          {tx.paid ? (
            <CircleTickIcon className="flex-shrink-0 ml-1.5 h-5 w-5 text-green-400" />
          ) : (
            <CrossIcon />
          )}
        </span>
      </td>
      <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
        {tx.outcome}
      </td>
      <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
        {tx.receipt_url && (
          <a
            href={tx.receipt_url}
            target="_blank"
            rel="noopener noreferrer"
            className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-gray-600 hover:bg-gray-900 focus:outline-none focus:border-gray-700 focus:bg-gray-900 transition ease-in-out duration-150"
          >
            <FaFilePdf className="-ml-1 mr-2 h-5 w-5" />
            View receipt
          </a>
        )}
      </td>
    </tr>
  );
};

export default function AccountTransactions(): JSX.Element {
  const { data, error } = useAuthSWR(api.memberships.transactions.list);
  const { user } = useContext(AuthContext);

  return (
    <AccountShell>
      <div className="pb-5 border-b border-gray-200">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Transactions</h3>
        <p className="mt-2 max-w-4xl text-sm text-gray-500">
          These are payments made through Stripe. For payments made with PayPal please check your
          PayPal account.
        </p>
      </div>
      <div className="mt-6">
        {error && <Message type="ERROR" body={error} noDismiss />}
        {!data && (
          <div className="w-full mx-auto">
            <div className="animate-pulse flex space-x-4">
              <div className="flex-1 space-y-4 py-1">
                <div className="space-y-2">
                  <div className="h-10 bg-gray-400 rounded-sm" />
                  <div className="h-16 bg-gray-300 rounded-sm" />
                  <div className="h-16 bg-gray-300 rounded-sm" />
                  <div className="h-16 bg-gray-300 rounded-sm" />
                </div>
              </div>
            </div>
          </div>
        )}
        {data && user && (
          <>
            {user.user.is_active_bootcamp_user ? (
              <div className="mb-5">
                <p className="text-gray-500">Your subscription falls under a classroom.</p>
              </div>
            ) : (
              <div className="flex flex-col">
                <div className="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                  <div className="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
                    <table className="min-w-full">
                      <thead>
                        <tr>
                          <TableHeader name="Date" />
                          <TableHeader name="Amount" />
                          <TableHeader name="Outcome" />
                          <TableHeader name="Actions" />
                        </tr>
                      </thead>
                      <tbody className="bg-white">
                        {data.length === 0 ? (
                          <tr>
                            <td
                              className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 font-medium text-gray-900"
                              colSpan={3}
                            >
                              You haven&apos;t made any transactions
                            </td>
                          </tr>
                        ) : (
                          data.map((tx: Transaction) => <TableRow key={tx.created} tx={tx} />)
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </AccountShell>
  );
}
