import React, { useEffect, useState } from "react";
import { BADGE_EXPERIENCE, BADGENAMES, BADGES } from "src/utils";
import { ActivityBoard, LargeLoader, RightLongArrowIcon } from "src/components";
import { BadgeName, Course, Lecture, Merchant } from "src/models";
import { useFetch, useAuthSWR } from "src/helpers";
import api from "src/api";
import { SummaryCourseList } from "./roadmap";
import { NavLink } from "react-router-dom";

interface DashboardProps {
  userExp: { experience: number } | null;
  badge: { badge: BadgeName } | null;
  merchant: Merchant | null;
}

function ExperienceOverview({ userExp, badge }: DashboardProps) {
  const badgeLevels = [
    { name: BADGENAMES.EGG, level: 1 },
    { name: BADGENAMES.SHOE, level: 2 },
    { name: BADGENAMES.WINGS, level: 3 },
    { name: BADGENAMES.HORN, level: 4 },
    { name: BADGENAMES.RAINBOW, level: 5 }
  ];

  // get the badges that come equal to and before the current badge
  let achievedLevels: { name: string }[] = [];
  if (badge) {
    const achievedBadgeLevel = badgeLevels.filter((x) => x.name === badge.badge)[0];
    achievedLevels = badgeLevels.filter((x) => x.level <= achievedBadgeLevel.level);
  }
  return (
    <div className="flex space-x-3">
      <div className="flex flex-col rounded-md shadow overflow-hidden bg-gray-200 p-6 xl:p-8">
        <div className="flex flex-wrap justify-center">
          <div className="px-4 py-5">
            <dl>
              <dt className="text-sm leading-5 font-medium text-gray-700 truncate">
                My experience
              </dt>
              <dd className="mt-1 text-3xl text-center leading-9 font-semibold text-gray-900">
                {userExp ? userExp.experience : 0} XP
              </dd>
            </dl>
          </div>
        </div>
      </div>
      <div className="flex-1 flex flex-col rounded-md shadow overflow-hidden bg-white p-6 xl:p-8">
        <div className="flex">
          <div className="flex-1">
            <div className="flow-root">
              {!badge && (
                <div className="animate-pulse flex space-x-4">
                  <div className="flex-1 space-y-4 py-1">
                    <div className="h-4 bg-gray-300 rounded-sm" />
                    <div className="h-15 w-15 bg-gray-300 rounded-full mx-auto mt-1" />
                  </div>
                </div>
              )}
              <ul className="-mb-8">
                {achievedLevels.map((level: { name: string }) => {
                  const badgeName = level.name;
                  const badge = BADGES[badgeName];
                  return (
                    <li key={level.name}>
                      <div className="relative pb-8">
                        <span aria-hidden="true" />
                        <div className="relative flex space-x-3">
                          {badge && (
                            <>
                              <div>
                                <span className="h-8 w-8 flex items-center justify-center">
                                  <img
                                    className="h-8 w-8 rounded-full mx-auto mt-1"
                                    src={badge}
                                    alt=""
                                  />
                                </span>
                              </div>
                              <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                                <div>
                                  <p className="text-sm text-gray-500">
                                    Achieved level{" "}
                                    <span className="font-medium text-gray-900">
                                      {badgeName.charAt(0).toUpperCase() + badgeName.substring(1)}
                                    </span>{" "}
                                    at{" "}
                                    <span className="font-medium text-gray-900">
                                      {BADGE_EXPERIENCE[badgeName]} XP
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function RoadmapSummaryItem({
  slug,
  merchant,
  switchRoadmap
}: {
  slug: string;
  merchant: Merchant | null;
  switchRoadmap: () => void;
}) {
  const { data: roadmap } = useFetch(api.roadmaps.retrieve(slug));
  return (
    <div className="flex flex-col rounded-lg shadow overflow-hidden bg-white">
      <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
        <div className="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-nowrap">
          <div className="ml-4 mt-4">
            <h3 className="text-lg leading-6 font-medium text-gray-900">Current Roadmap</h3>
            {!roadmap && (
              <div className="animate-pulse flex space-x-4">
                <div className="flex-1 space-y-4 py-1">
                  <div className="h-3 bg-gray-300 rounded-sm w-2/3" />
                </div>
              </div>
            )}
            <p className="mt-1 text-sm text-gray-500">{roadmap && roadmap.title}</p>
          </div>
          {merchant && merchant.active_pro && (
            <div className="ml-4 mt-4 flex-shrink-0">
              <button
                onClick={switchRoadmap}
                type="button"
                className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Switch Roadmap
              </button>
            </div>
          )}
        </div>
      </div>

      <div className="flex-1 bg-white p-3 flex flex-col justify-between">
        <div className="flex-1">
          <div className="flex flex-wrap justify-center">
            <ul className="w-full">
              {!roadmap && (
                <>
                  {Array(10)
                    .fill(undefined)
                    .map((i, j) => {
                      return (
                        <div className="animate-pulse flex space-x-4" key={j}>
                          <div className="flex-1 space-y-2 py-4">
                            <div className="h-3 bg-gray-300 rounded-sm w-1/2" />
                            <div className="h-1 bg-gray-300 rounded-sm" />
                            <div className="h-2 bg-gray-300 rounded-sm w-1/3" />
                          </div>
                        </div>
                      );
                    })}
                </>
              )}
              {roadmap && (
                <SummaryCourseList
                  courses={roadmap.courses.filter(
                    (x: { archived: boolean; upcoming: boolean }) => !x.archived && !x.upcoming
                  )}
                  title={roadmap.title}
                />
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

function RecommendedLecture({
  recommendation,
  course,
  lecture
}: {
  recommendation: {
    message?: string;
    action?: string;
    course_slug: string;
    lecture_slug: string;
  };
  course: Course;
  lecture: Lecture;
}) {
  if (recommendation && recommendation.action) {
    return (
      <div className="w-full relative text-white shadow overflow-hidden flex rounded-md">
        <div className="w-full flex md:flex-col bg-gradient-to-br from-yellow-300 to-yellow-400">
          <div className="md:w-auto md:flex-auto flex flex-col items-start relative z-10 p-6 xl:p-8">
            <h2 className="text-2xl font-semibold mb-2 text-shadow">
              You&apos;ve finished the last course of your current roadmap
            </h2>
            <p className="font-medium text-white text-shadow mb-4">
              Upgrade your membership to start with the Advanced Roadmap
            </p>
            <NavLink
              className="mt-auto bg-red-900 bg-opacity-50 hover:bg-opacity-75 transition-colors duration-200 rounded-md font-semibold py-2 px-4 inline-flex"
              to={`/pricing`}
            >
              Become a Pro Django Dev{" "}
              <span role="img" aria-label="Rocket">
                🚀
              </span>
            </NavLink>
          </div>
        </div>
      </div>
    );
  }

  if (recommendation && recommendation.message) {
    return (
      <div className="w-full relative text-white shadow overflow-hidden flex rounded-md">
        <div className="w-full flex md:flex-col bg-gradient-to-br from-yellow-300 to-yellow-400">
          <div className="md:w-auto md:flex-auto flex flex-col items-start relative z-10 p-6 xl:p-8">
            <h2 className="text-2xl font-semibold mb-2 text-shadow">
              You&apos;ve finished the last course of your current roadmap
            </h2>
            <p className="font-medium text-white text-shadow mb-4">{recommendation.message}</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {!(recommendation && course && lecture) && <LargeLoader />}
      {recommendation && course && lecture && (
        <div className="w-full relative text-white shadow overflow-hidden flex rounded-md">
          <div className="w-full flex md:flex-col bg-gradient-to-br from-indigo-400 to-indigo-500">
            <div className="md:w-auto md:flex-auto flex flex-col items-start relative z-10 p-6 xl:p-8">
              <h2 className="text-2xl font-semibold mb-2 text-shadow">
                Recommendation: {course.title}
              </h2>
              <p className="font-medium text-white text-shadow mb-4">
                The next lesson is {lecture.order < 9 ? `0${lecture.order}` : lecture.order} -{" "}
                {lecture.title}
              </p>
              <NavLink
                className="mt-auto bg-indigo-900 bg-opacity-50 hover:bg-opacity-75 transition-colors duration-200 rounded-md font-semibold py-2 px-4 inline-flex"
                to={`/courses/${course.slug}/${lecture.slug}/`}
              >
                Go to next lesson
                <RightLongArrowIcon className={"h-6 w-6 ml-2"} />
              </NavLink>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default function Dashboard(): JSX.Element {
  const { data: userExp } = useAuthSWR(api.profiles.experience);
  const { data: merchant } = useAuthSWR(api.memberships.summary);
  const { data: badge } = useAuthSWR(api.profiles.badge);
  const { data: activity } = useAuthSWR(api.analytics.activity);
  const { data: recommendation } = useAuthSWR(api.analytics.recommendation);
  const { data: course } = useAuthSWR(
    recommendation ? api.courses.retrieve(recommendation.course_slug) : ""
  );
  const { data: lecture } = useAuthSWR(
    recommendation
      ? api.lectures.retrieve(recommendation.course_slug, recommendation.lecture_slug)
      : ""
  );

  function transformActivityData() {
    if (!activity) return [];
    return activity.map((ac: any) => {
      return {
        day: new Date(ac.timestamp).toISOString().split("T")[0],
        value: ac.lecture__experience
      };
    });
  }

  const [activeRoadmap, setActiveRoadmap] = useState<"django-basic" | "django-advanced">(
    "django-basic"
  );

  useEffect(() => {
    if (merchant && merchant.last_lecture_roadmap) {
      setActiveRoadmap(merchant.last_lecture_roadmap);
    }
  }, [course]);

  function switchRoadmap() {
    setActiveRoadmap(activeRoadmap === "django-basic" ? "django-advanced" : "django-basic");
  }

  return (
    <div className="bg-gray-100 pt-8 pb-20 px-4 sm:px-6 lg:pt-4 lg:pb-28 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <main className="min-w-0 flex-1 lg:flex">
          <div className="lg:hidden px-3 py-6">
            <RoadmapSummaryItem
              slug={activeRoadmap}
              key={activeRoadmap}
              merchant={merchant}
              switchRoadmap={() => switchRoadmap()}
            />
          </div>
          <section className="px-3 space-y-6 min-w-0 flex-1 h-full flex flex-col overflow-hidden lg:order-last">
            <div className="py-3 rounded-md overflow-hidden shadow bg-white">
              <div className="mx-auto text-center">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  Experience gained each day
                </h3>
              </div>
              <div className="flex flex-wrap justify-center" style={{ height: "170px" }}>
                <ActivityBoard data={transformActivityData()} />
              </div>
            </div>
            <div>
              <RecommendedLecture
                course={course}
                lecture={lecture}
                recommendation={recommendation}
              />
            </div>
            <div>
              <ExperienceOverview badge={badge} userExp={userExp} merchant={merchant} />
            </div>
          </section>

          <aside className="hidden lg:block lg:flex-shrink-0 lg:order-first">
            <div className="px-3 h-full relative flex flex-col w-96 bg-gray-100">
              <RoadmapSummaryItem
                slug={activeRoadmap}
                key={activeRoadmap}
                merchant={merchant}
                switchRoadmap={() => switchRoadmap()}
              />
            </div>
          </aside>
        </main>
      </div>
    </div>
  );
}
