import React from "react";
import { Field } from "formik";

const FieldLabel: React.FC<{ fieldName: string; label: string }> = ({ fieldName, label }) => {
  return (
    <label htmlFor={fieldName} className="block text-sm font-medium leading-5 text-gray-700">
      {label}
    </label>
  );
};

interface RequiredFieldProps {
  formik: any;
  fieldName: string;
  label: string;
  fieldType: string;
  as?: string;
  placeholder?: string;
  rows?: number;
}

export const RequiredField: React.FC<RequiredFieldProps> = ({
  formik,
  label,
  fieldName,
  fieldType,
  as = "input",
  placeholder,
  rows
}) => {
  const hasError = formik.touched[fieldName] && formik.errors[fieldName];
  return (
    <div className="mb-5 sm:col-span-6">
      <FieldLabel fieldName={fieldName} label={label} />
      <div className="mt-1 rounded-md shadow-sm">
        <Field
          as={as}
          name={fieldName}
          placeholder={placeholder}
          type={fieldType}
          id={fieldName}
          className={`appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm ${
            hasError
              ? "pr-10 border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red"
              : ""
          }`}
          rows={rows}
        />
      </div>
      {hasError && <p className="mt-2 text-sm text-red-600">{formik.errors[fieldName]}</p>}
    </div>
  );
};
