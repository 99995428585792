import { useState, useEffect, useRef, useContext } from "react";
import useSWR, { SWRResponse } from "swr";
import axios, { AxiosInstance } from "axios";
import { AuthContext } from "src/contexts";

function useAuthSWR(endpoint: string | null): SWRResponse<any, any> {
  const { authAxios } = useContext(AuthContext);
  return useSWR(endpoint, (url) => authAxios(url).then((res) => res.data), {
    revalidateOnFocus: false
  });
}

function useFetch(endpoint: string): SWRResponse<any, any> {
  return useSWR(endpoint, (url) => axios(url).then((res) => res.data));
}

export function useOldFetch(
  url: string,
  initialState: Record<string, unknown> | any[] | null,
  trigger = null,
  useAuth = false
): {
  data: any;
  error: boolean;
  loading: boolean;
  callbacks: {
    setError: React.Dispatch<React.SetStateAction<boolean>>;
    setLoading: React.Dispatch<any>;
    setData: React.Dispatch<any>;
  };
} {
  const [data, setData] = useState(initialState);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const { authAxios } = useContext(AuthContext);

  async function fetchUrl(source: any) {
    setError(false);
    setLoading(true);
    try {
      let ax: AxiosInstance = axios;
      if (useAuth) {
        ax = authAxios;
      }
      const response = await ax.get(url, {
        cancelToken: source.token
      });
      setData(response.data);
      setLoading(false);
    } catch (err) {
      if (axios.isCancel(err)) {
        // cancelled
      } else {
        setLoading(false);
        setError(err);
      }
    }
  }

  const callbacks = {
    setError,
    setLoading,
    setData
  };

  const deps = [];
  if (trigger !== undefined) {
    deps.push(trigger);
  }

  useEffect(() => {
    const source = axios.CancelToken.source();
    fetchUrl(source);
  }, deps);

  return { data, error, loading, callbacks };
}

function useAuthFetch(
  url: string,
  initialState: Record<string, unknown> | any[] | null,
  trigger: any = null
): {
  data: any;
  error: boolean;
  loading: boolean;
  callbacks: {
    setError: React.Dispatch<React.SetStateAction<boolean>>;
    setLoading: React.Dispatch<any>;
    setData: React.Dispatch<any>;
  };
} {
  return useOldFetch(url, initialState, trigger, true);
}

function useInterval(callback: (...args: any[]) => any, delay: number): void {
  const savedCallback = useRef<any>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

function useWindowSize(): {
  width: number;
  height: number;
} {
  function getSize() {
    return {
      width: window.innerWidth,
      height: window.innerHeight
    };
  }

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    function handleResize() {
      setWindowSize(getSize());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
}

export { useFetch, useAuthFetch, useInterval, useAuthSWR, useWindowSize };
