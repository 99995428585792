import React from "react";
import { LoadingPlaceholder } from "../components/icons/draw";

function Fallback(): JSX.Element {
  return (
    <div className="min-h-screen bg-gray-100 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="mt-8 sm:mx-auto">
        <div className="bg-white shadow sm:rounded-lg">
          <div className="px-4 sm:p-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900 text-center">
              Setting up the workspace!
            </h3>
            <div className="p-3">
              <LoadingPlaceholder />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Fallback;
