import React, { useContext, useEffect, useState } from "react";
import "../../assets/css/stripe.css";
import { loadStripe } from "@stripe/stripe-js";
import { CardElement, Elements, useStripe, useElements } from "@stripe/react-stripe-js";
import { mutate } from "swr";
import { Divider, Loader, LargeLoader, Message, PaymentMethodCard } from "src/components";
import { useAuthSWR } from "src/helpers";
import { AuthContext } from "src/contexts";
import { STRIPE_PUBLIC_KEY } from "src/config";
import { Plan, Status } from "src/models";
import errorHandler, { PLANS } from "src/utils";
import api from "src/api";
import { toast } from "react-hot-toast";
import { createOptions, SecureInfo } from "./common";

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

interface PaymentFormProps {
  callback: () => void;
  planID: Plan;
  currentPlan: Plan;
  status: Status;
  allowDefault?: boolean;
  payingDueInvoice?: boolean;
}

function CheckoutForm({
  callback,
  planID,
  currentPlan,
  status,
  allowDefault = true,
  payingDueInvoice = false
}: PaymentFormProps) {
  const [proRata, setProRata] = useState<number>(0);
  const [invoiceDue, setInvoiceDue] = useState<number>(0);
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);
  const [processing, setProcessing] = useState<boolean>(false);
  const [useAnotherCard, setUsingNewCard] = useState<boolean>(false);
  const { authAxios } = useContext(AuthContext);
  const { data: plan, error: planError } = useAuthSWR(api.payment.getPlanDetails(planID));
  const { data: member, error: memberError } = useAuthSWR(api.memberships.retrieve);

  const stripe = useStripe();
  const elements = useElements();

  const handleChange = ({ error }: { error: any }) => {
    if (error && error.message) setError(error.message);
  };

  const handleSuccess = () => {
    setError(null);
    setSuccess(true);
    setProcessing(false);
    localStorage.removeItem("latestInvoicePaymentIntentStatus");
    localStorage.removeItem("latestInvoiceId");
    localStorage.removeItem("planId");
    toast.success("Payment Successful!", {
      duration: 4000,
      icon: "🎉",
      iconTheme: {
        primary: "#000",
        secondary: "#fff"
      }
    });
    mutate(api.memberships.retrieve);
    mutate(api.memberships.transactions.list);
    mutate(api.memberships.summary);
    callback();
  };

  const handleError = (err: any) => {
    setError(errorHandler(err));
    setProcessing(false);
  };

  async function fetchProRata() {
    try {
      const response = await authAxios.get(api.billing.previewPackageChange(planID));
      setProRata(response.data.preview / 100);
    } catch (err) {
      setError(errorHandler(err));
    }
  }

  async function fetchDueInvoice() {
    try {
      const response = await authAxios.get(api.billing.fetchDueInvoice);
      setInvoiceDue(response.data.amount);
    } catch (err) {
      setError(errorHandler(err));
      setProcessing(false);
    }
  }

  const payDueInvoice = (paymentMethodID: string) => {
    const cardElement = elements!.getElement(CardElement);
    authAxios
      .post(api.billing.payDueInvoice, {
        paymentMethod: paymentMethodID,
        plan: planID
      })
      .then((res) => {
        const { payment_intent } = res.data;
        if (payment_intent) {
          const { client_secret, status } = payment_intent;
          if (status === "requires_action") {
            stripe!
              .confirmCardPayment(client_secret, {
                payment_method: {
                  card: cardElement!
                }
              })
              .then((result) => {
                if (result.error) {
                  handleError(result.error.message);
                  // The card was declined (i.e. insufficient funds, card has expired, etc)
                } else {
                  handleSuccess();
                }
              });
          } else {
            handleSuccess();
          }
        }
      })
      .catch((err: any) => {
        setProcessing(false);
        setError(errorHandler(err));
      });
  };

  function retryInvoiceWithNewPaymentMethod(paymentMethodId: string, invoiceId: string) {
    return (
      authAxios
        .post(api.billing.payDueInvoice, {
          paymentMethodId,
          invoiceId
        })
        .then((response) => {
          return response.data;
        })
        // If the card is declined, display an error to the user.
        .then((result) => {
          if (result.error) {
            // The card had an error when trying to attach it to a customer
            throw result;
          }
          return result;
        })
        // Normalize the result to contain the object returned
        // by Stripe. Add the addional details we need.
        .then(
          (result: {
            payment_intent: {
              status: string;
              client_secret: string;
            };
          }) => {
            return {
              // Use the Stripe 'object' property on the
              // returned result to understand what object is returned.
              invoice: result,
              paymentMethodId,
              isRetry: true
            };
          }
        )
        // Some payment methods require a customer to be on session
        // to complete the payment process. Check the status of the
        // payment intent to handle these actions.
        .then(handlePaymentThatRequiresCustomerAction)
        // No more actions required. Provision your service for the user.
        .then(handleSuccess)
        .catch((error) => {
          // An error has happened. Display the failure to the user here.
          // We utilize the HTML element we created.
          setError(errorHandler(error));
          setProcessing(false);
          setUsingNewCard(true);
        })
    );
  }

  interface Subscription {
    status: string;
    latest_invoice: {
      id: string;
      payment_intent: {
        status: string;
        client_secret: string;
      };
    };
  }

  interface Invoice {
    payment_intent: {
      status: string;
      client_secret: string;
    };
  }

  // TODO - typescript for this function is really complex
  function handlePaymentThatRequiresCustomerAction({
    subscription,
    invoice,
    paymentMethodId,
    isRetry
  }: any):
    | { subscription: Subscription; invoice: Invoice; paymentMethodId: string }
    | { subscription: Subscription; paymentMethodId: string }
    | PromiseLike<
        { subscription: Subscription; invoice: Invoice; paymentMethodId: string } | undefined
      >
    | undefined {
    if (subscription && subscription.status === "active") {
      // subscription is active, no customer actions required.
      return { subscription, paymentMethodId };
    }

    // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
    // If it's a retry, the payment intent will be on the invoice itself.
    const paymentIntent = invoice
      ? invoice.payment_intent
      : subscription!.latest_invoice.payment_intent;

    if (
      paymentIntent.status === "requires_action" ||
      (isRetry === true && paymentIntent.status === "requires_payment_method")
    ) {
      return stripe!
        .confirmCardPayment(paymentIntent.client_secret, {
          payment_method: paymentMethodId
        })
        .then((result) => {
          if (result.error) {
            // start code flow to handle updating the payment details
            // Display error message in your UI.
            // The card was declined (i.e. insufficient funds, card has expired, etc)
            if (result.error.message) {
              throw result.error.message;
            }
            throw result;
          } else if (result.paymentIntent.status === "succeeded") {
            // There's a risk of the customer closing the window before callback
            // execution. To handle this case, set up a webhook endpoint and
            // listen to invoice.paid. This webhook endpoint returns an Invoice.
            return {
              subscription,
              invoice,
              paymentMethodId
            };
          }
        });
    }
    // No customer action needed
    return { subscription, paymentMethodId };
  }

  function handleRequiresPaymentMethod({ subscription, paymentMethodId }: any) {
    if (subscription.status === "active") {
      // subscription is active, no customer actions required.
      return { subscription, paymentMethodId };
    }
    if (subscription.latest_invoice.payment_intent.status === "requires_payment_method") {
      // Store the latest invoice ID and status
      localStorage.setItem("planId", planID);
      localStorage.setItem("latestInvoiceId", subscription.latest_invoice.id);
      localStorage.setItem(
        "latestInvoicePaymentIntentStatus",
        subscription.latest_invoice.payment_intent.status
      );
      throw {
        message: "Your card was declined. Please try a different card to complete the payment"
      };
    } else {
      return { subscription, paymentMethodId };
    }
  }

  function createSubscription({ paymentMethodID }: { paymentMethodID: string }) {
    setProcessing(true);
    return (
      authAxios
        .post(api.payment.createPaymentMethod, {
          paymentMethod: paymentMethodID,
          plan: planID
        })
        .then((response) => {
          return response.data;
        })
        // If the card is declined, display an error to the user.
        .then((result) => {
          if (result.error) {
            // The card had an error when trying to attach it to a customer
            throw result;
          }
          return result;
        })
        // Normalize the result to contain the object returned
        // by Stripe. Add the additional details we need.
        .then((result) => {
          const subscription: Subscription = result;
          return {
            // Use the Stripe 'object' property on the
            // returned result to understand what object is returned.
            subscription,
            paymentMethodId: paymentMethodID
          };
        })
        // Some payment methods require a customer to do additional
        // authentication with their financial institution.
        // Eg: 2FA for cards.
        .then(handlePaymentThatRequiresCustomerAction)
        // If attaching this card to a Customer object succeeds,
        // but attempts to charge the customer fail. You will
        // get a requires_payment_method error.
        .then(handleRequiresPaymentMethod)
        // No more actions required. Provision your service for the user.
        .then(handleSuccess)
        .catch((error) => {
          // An error has happened. Display the failure to the user here.
          // We utilize the HTML element we created.
          setError(errorHandler(error));
          setProcessing(false);
          setUsingNewCard(true);
        })
    );
  }

  async function handleNewCardPayment({ invoiceId }: { invoiceId?: string | null }) {
    // Step 1: Create a payment method
    setError(null);
    setProcessing(true);
    const cardElement = elements!.getElement(CardElement);
    const { error, paymentMethod } = await stripe!.createPaymentMethod({
      type: "card",
      card: cardElement!
    });
    if (error) {
      setError(error.message);
      setProcessing(false);
    } else if (paymentMethod) {
      if (invoiceId) {
        // Retry Invoice
        retryInvoiceWithNewPaymentMethod(paymentMethod.id, invoiceId);
      } else {
        // Create subscription
        await createSubscription({ paymentMethodID: paymentMethod.id });
      }
    }
  }

  async function handleDefaultPayment({ invoiceId }: { invoiceId?: string | null }) {
    if (invoiceId) {
      // Retry Invoice
      retryInvoiceWithNewPaymentMethod(member.default_payment_method_id, invoiceId);
    } else {
      // Create subscription
      await createSubscription({ paymentMethodID: member.default_payment_method_id });
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async function handlePayDueInvoice() {
    // Step 1: Create new payment method and send to the server
    setError(null);
    setProcessing(true);
    const cardElement = elements!.getElement(CardElement);
    const { error, paymentMethod } = await stripe!.createPaymentMethod({
      type: "card",
      card: cardElement!
    });
    if (error) {
      setError(error.message);
      setProcessing(false);
    } else if (paymentMethod) {
      // Step 2: Send PaymentMethod to the server
      payDueInvoice(paymentMethod.id);
    }
  }

  async function handleSubmit(ev: any) {
    ev.preventDefault();

    const latestInvoicePaymentIntentStatus = localStorage.getItem(
      "latestInvoicePaymentIntentStatus"
    );
    const failedPlanId = localStorage.getItem("planId");

    if (latestInvoicePaymentIntentStatus === "requires_payment_method" && failedPlanId === planID) {
      const invoiceId = localStorage.getItem("latestInvoiceId");

      // create new payment method & retry payment on invoice with new payment method
      if (useAnotherCard) {
        await handleNewCardPayment({ invoiceId });
      } else {
        // or use existing payment method to pay for invoice
        await handleDefaultPayment({ invoiceId });
      }
    } else if (useAnotherCard) {
      // create new payment method & create subscription
      await handleNewCardPayment({ invoiceId: undefined });
    } else {
      // use default payment method
      await handleDefaultPayment({ invoiceId: undefined });
    }
  }

  useEffect(() => {
    async function handleFetch() {
      if (status !== "canceled") {
        await fetchProRata();
      }
      if (payingDueInvoice) {
        await fetchDueInvoice();
      }
    }
    handleFetch();
  }, []);

  useEffect(() => {
    if (member) {
      if (member.cards.length >= 1) {
        setUsingNewCard(false);
      } else {
        setUsingNewCard(true);
      }
    }
  }, [member]);

  const showProRata = currentPlan !== PLANS.DJANGO_FREE_TRIAL;

  function hasFailedInvoice() {
    const failedPlanId = localStorage.getItem("planId");
    return failedPlanId === planID;
  }

  return (
    <div className="sr-form-row">
      {!success && (
        <>
          <div>
            {error && <Message type="ERROR" body={error} onDismiss={() => setError(null)} />}
            {planError && <Message type="ERROR" body={errorHandler(planError)} noDismiss />}
            {memberError && <Message type="ERROR" body={errorHandler(memberError)} noDismiss />}
            {hasFailedInvoice() && (
              <p className="py-2 text-red-500">
                Your previous payment failed. Please try again or use another card
              </p>
            )}
            {!plan && <LargeLoader />}
            {plan && (
              <form onSubmit={handleSubmit}>
                {invoiceDue ? (
                  <h5 className="text-md">
                    Invoice due: <span className="font-bold">${invoiceDue}</span>
                  </h5>
                ) : (
                  <>
                    <h5 className="text-md">
                      Amount:{" "}
                      <span className="font-bold">
                        $
                        {proRata && showProRata ? (
                          <>
                            {proRata.toLocaleString(navigator.language, {
                              minimumFractionDigits: 2
                            })}
                          </>
                        ) : (
                          <>
                            {plan.amount.toLocaleString(navigator.language, {
                              minimumFractionDigits: 2
                            })}
                          </>
                        )}
                      </span>
                    </h5>
                    <h5 className="text-md">Selected plan: {plan.name}</h5>
                    {proRata > 0 && showProRata && (
                      <>
                        <p className="mt-3 text-gray-700">
                          You will be changing package and charged{" "}
                          <span className="font-bold">
                            $
                            {proRata.toLocaleString(navigator.language, {
                              minimumFractionDigits: 2
                            })}{" "}
                          </span>
                          now.
                        </p>
                      </>
                    )}
                  </>
                )}
                {!member && <LargeLoader />}
                {member && member.cards.length >= 1 && allowDefault ? (
                  <div className="mt-3">
                    <div className="w-full mb-3">
                      <label
                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        htmlFor="grid-state"
                      >
                        Payment Method
                      </label>
                      <div className="relative">
                        <select
                          onChange={() => setUsingNewCard(!useAnotherCard)}
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="grid-state"
                        >
                          <option>Default card</option>
                          <option>New card</option>
                        </select>
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                          <svg
                            className="fill-current h-4 w-4"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                          </svg>
                        </div>
                      </div>
                    </div>
                    {useAnotherCard ? (
                      <label>
                        <CardElement onChange={handleChange} {...createOptions()} />
                      </label>
                    ) : (
                      <>
                        {member.cards.map((card: { id: number; default: boolean }) => {
                          return (
                            <div key={card.id}>
                              {card.default && (
                                <PaymentMethodCard
                                  card={card}
                                  cards={member.cards}
                                  withActions={false}
                                />
                              )}
                            </div>
                          );
                        })}
                      </>
                    )}
                  </div>
                ) : (
                  <div className="mt-3">
                    <label>
                      <CardElement onChange={handleChange} {...createOptions()} />
                    </label>
                  </div>
                )}
                <>
                  {!success && (
                    <button
                      type="submit"
                      disabled={processing}
                      className={`${
                        processing
                          ? "cursor-not-allowed bg-indigo-300"
                          : "bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:shadow-outline transition duration-300 ease-in-out"
                      } mt-3 w-full items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white`}
                    >
                      {processing && <Loader />}
                      {processing ? "Processing…" : "Start Subscription"}
                    </button>
                  )}
                </>
                <Divider />
                <SecureInfo />
              </form>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export function PaymentForm({
  callback,
  planID,
  currentPlan,
  status,
  allowDefault = true,
  payingDueInvoice = false
}: PaymentFormProps): JSX.Element {
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm
        planID={planID}
        callback={callback}
        currentPlan={currentPlan}
        status={status}
        allowDefault={allowDefault}
        payingDueInvoice={payingDueInvoice}
      />
    </Elements>
  );
}
