import React, { Fragment, useContext, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import api from "src/api";
import { Button, Message, RequiredField } from "src/components";
import { AuthContext } from "src/contexts";

function CancelSubscriptionModal({
  open,
  setOpen,
  callback
}: {
  open: boolean;
  setOpen: (arg: boolean) => void;
  callback: () => void;
}) {
  const [error, setError] = useState(null);
  const { authAxios } = useContext(AuthContext);
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        open={open}
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-md sm:w-full sm:p-6">
              <div className="sm:flex sm:items-start">
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">Cancel membership</h3>
                  <div className="mt-2">
                    <p className="text-sm leading-5 text-gray-500">
                      Sorry to see you go! I&apos;m always trying to make JustDjango better so
                      please leave a comment about your experience. Don&apos;t hold back!
                    </p>
                    <p className="text-sm leading-5 text-gray-500 mt-5">
                      Select &quot;End Membership&quot; below to cancel your membership. This will
                      cancel all future charges on any of your payment methods. You will still have
                      access to the learning material until your next billing date.
                    </p>

                    <Formik
                      initialValues={{ reason: "Other", comment: "" }}
                      validationSchema={Yup.object().shape({
                        comment: Yup.string().required("Please leave a comment - it really helps!")
                      })}
                      onSubmit={(values, { setSubmitting, resetForm }) => {
                        setSubmitting(true);
                        authAxios
                          .post(api.memberships.cancel, values)
                          .then(() => {
                            setSubmitting(false);
                            setOpen(false);
                            resetForm();
                            callback();
                          })
                          .catch((err) => {
                            setSubmitting(false);
                            setError(err.response.data.message);
                          });
                      }}
                    >
                      {(formik) => (
                        <Form>
                          {error && (
                            <Message type="ERROR" body={error} onDismiss={() => setError(null)} />
                          )}
                          <div className="mt-6 grid grid-cols-1 row-gap-6 col-gap-4 sm:grid-cols-6">
                            <RequiredField
                              label="How can JustDjango be better?"
                              formik={formik}
                              as="textarea"
                              fieldName="comment"
                              fieldType="text"
                            />
                          </div>

                          <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                            <span className="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                              <Button
                                disabled={formik.isSubmitting}
                                loading={formik.isSubmitting}
                                label={formik.isSubmitting ? "Submitting" : "End Membership"}
                                type="submit"
                                className={`${
                                  formik.isSubmitting ? "bg-red-500" : "bg-red-600 hover:bg-red-500"
                                } inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 text-base leading-6 font-medium text-white shadow-sm focus:outline-none focus:border-red-700 focus:shadow-outline-red transition ease-in-out duration-150 sm:text-sm sm:leading-5`}
                              />
                            </span>
                            <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                              <button
                                onClick={() => setOpen(false)}
                                type="button"
                                className="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                              >
                                Cancel
                              </button>
                            </span>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export function CancelSubscriptionForm({
  callback
}: {
  callback: () => Promise<boolean>;
}): JSX.Element {
  const [open, setOpen] = useState(false);
  return (
    <>
      <CancelSubscriptionModal open={open} setOpen={setOpen} callback={() => callback()} />
      <div>
        <h3 className="text-lg leading-6 font-medium text-gray-900">Cancel Membership</h3>
        <div className="mt-2 text-sm leading-5 text-gray-500">
          Once you&apos;ve cancelled your membership, you will lose access to all of the content on
          JustDjango
        </div>
        <div className="mt-5">
          <button
            onClick={() => setOpen(true)}
            type="button"
            className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:border-red-500 focus:bg-red-500 transition ease-in-out duration-150"
          >
            Cancel membership
          </button>
        </div>
      </div>
    </>
  );
}
