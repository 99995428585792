import React, { useContext, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { AccountShell, SocialAccountsForm } from "src/containers";
import { Loader, RequiredField, Message } from "src/components";
import { AuthContext } from "src/contexts";
import errorHandler from "src/utils";
import api from "src/api";
import { useAuthSWR } from "../../helpers";

function SaveButton({ submitting }: { submitting: boolean }): JSX.Element {
  return (
    <div className="mt-8 border-t border-gray-200 pt-5">
      <div className="flex justify-end">
        <span className="ml-3 inline-flex rounded-md shadow-sm">
          {submitting ? (
            <button
              type="submit"
              className="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
            >
              <span className="mr-3">
                <Loader />
              </span>
              Saving
            </button>
          ) : (
            <button
              type="submit"
              className="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
            >
              Save
            </button>
          )}
        </span>
      </div>
    </div>
  );
}

function ChangePasswordForm() {
  const { authAxios } = useContext(AuthContext);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  return (
    <div>
      {error && <Message type="ERROR" body={error} onDismiss={() => setError(null)} />}
      {success && (
        <Message
          type="SUCCESS"
          body={"Your password has been changed"}
          onDismiss={() => setSuccess(false)}
        />
      )}
      <Formik
        initialValues={{ currentPassword: "", password: "", confirmPassword: "" }}
        validationSchema={Yup.object({
          currentPassword: Yup.string().required("Required").min(6, "Must be 6 characters or more"),
          password: Yup.string().required("Required").min(6, "Must be 6 characters or more"),
          confirmPassword: Yup.string()
            .required("Required")
            .min(6, "Must be 6 characters or more")
            .oneOf([Yup.ref("password")], "Passwords must match")
        })}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          authAxios
            .post(api.auth.changePassword, {
              new_password1: values.password,
              new_password2: values.confirmPassword,
              old_password: values.currentPassword
            })
            .then(() => {
              setSuccess(true);
              setSubmitting(false);
              resetForm();
            })
            .catch((err) => {
              setError(errorHandler(err));
              setSubmitting(false);
            });
        }}
      >
        {(formik) => (
          <Form>
            <div className="mt-4 pt-8">
              <div>
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  Manage your Password
                </h3>
              </div>
              <div className="mt-6 grid grid-cols-1 row-gap-6 col-gap-4 sm:grid-cols-6">
                <RequiredField
                  label="Current Password"
                  formik={formik}
                  fieldName="currentPassword"
                  fieldType="password"
                />
                <RequiredField
                  label="New Password"
                  formik={formik}
                  fieldName="password"
                  fieldType="password"
                />
                <RequiredField
                  label="Confirm Password"
                  formik={formik}
                  fieldName="confirmPassword"
                  fieldType="password"
                />
              </div>
              <SaveButton submitting={formik.isSubmitting} />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

function SetPasswordForm() {
  const { authAxios } = useContext(AuthContext);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  return (
    <div>
      {error && <Message type="ERROR" body={error} onDismiss={() => setError(null)} />}
      {success && (
        <Message
          type="SUCCESS"
          body={"Your password has been set"}
          onDismiss={() => setSuccess(false)}
        />
      )}
      <Formik
        initialValues={{ set_new_password: "", set_confirmPassword: "" }}
        validationSchema={Yup.object({
          set_new_password: Yup.string()
            .required("Required")
            .min(6, "Must be 6 characters or more"),
          set_confirmPassword: Yup.string()
            .required("Required")
            .min(6, "Must be 6 characters or more")
            .oneOf([Yup.ref("set_new_password")], "Passwords must match")
        })}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          authAxios
            .post(api.account.setPassword, {
              password: values.set_new_password,
              confirm_password: values.set_confirmPassword
            })
            .then(() => {
              setSuccess(true);
              setSubmitting(false);
              resetForm();
            })
            .catch((err) => {
              setError(errorHandler(err));
              setSubmitting(false);
            });
        }}
      >
        {(formik) => (
          <Form>
            <div className="mt-4 pt-8">
              <div>
                <h3 className="text-lg leading-6 font-medium text-gray-900">Set your Password</h3>
                <p className="mt-1 text-gray-500">
                  You have logged in with Google. To also login with a password, set the password
                  below.
                </p>
              </div>
              <div className="mt-6 grid grid-cols-1 row-gap-6 col-gap-4 sm:grid-cols-6">
                <RequiredField
                  label="New Password"
                  formik={formik}
                  fieldName="set_new_password"
                  fieldType="password"
                />
                <RequiredField
                  label="Confirm Password"
                  formik={formik}
                  fieldName="set_confirmPassword"
                  fieldType="password"
                />
              </div>
              <SaveButton submitting={formik.isSubmitting} />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

function SkeletonLoader() {
  return (
    <div className="w-full mx-auto">
      <div className="py-5 animate-pulse flex space-x-4">
        <div className="flex-1 space-y-4 py-1">
          <div className="h-4 bg-gray-300 rounded-sm" style={{ width: "100px" }} />
          <div className="space-y-2">
            <div className="h-3 bg-gray-300 rounded-sm w-1/4" />
            <div className="h-12 bg-gray-300 rounded-sm" />
          </div>
          <div className="space-y-2">
            <div className="h-3 bg-gray-300 rounded-sm w-1/4" />
            <div className="h-12 bg-gray-300 rounded-sm" />
          </div>
          <div className="space-y-2">
            <div className="h-3 bg-gray-300 rounded-sm w-1/4" />
            <div className="h-12 bg-gray-300 rounded-sm" />
          </div>
        </div>
      </div>
      <div className="py-5 mt-10 animate-pulse flex space-x-4">
        <div className="flex-1 space-y-4 py-1">
          <div className="h-4 bg-gray-300 rounded-sm" style={{ width: "100px" }} />
          <div className="space-y-2">
            <div className="h-12 bg-gray-300 rounded-sm w-1/4" />
          </div>
        </div>
      </div>
      <div className="py-5 mt-10 animate-pulse flex space-x-4">
        <div className="flex-1 space-y-4 py-1">
          <div className="h-4 bg-gray-300 rounded-sm" style={{ width: "100px" }} />
          <div className="h-3 bg-gray-300 rounded-sm w-1/3" />
          <div className="mt-2 h-3 bg-gray-300 rounded-sm w-1/3" />
          <div className="space-y-2">
            <div className="h-12 bg-gray-300 rounded-sm w-1/4" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default function AccountProfile(): JSX.Element {
  const { data } = useAuthSWR(api.account.hasPassword);
  return (
    <AccountShell>
      {!data && <SkeletonLoader />}
      {data && (
        <>
          {data.has_password ? <ChangePasswordForm /> : <SetPasswordForm />}
          {data.has_password && <SocialAccountsForm />}
          {/* <CloseAccountForm /> */}
        </>
      )}
    </AccountShell>
  );
}
