import React, { useContext, useEffect, useRef, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { mutate } from "swr";
import { Player, DefaultUi, Vimeo } from "@vime/react";
import "@vime/core/themes/default.css";
import { useHotkeys } from "react-hotkeys-hook";
import { CircleTickIcon, Loader } from "src/components";
import { history } from "src/helpers";
import { AuthContext } from "src/contexts";
import api from "src/api";
import { Lecture } from "src/models";

export function VideoPlayer({ lecture }: { lecture: Lecture }): JSX.Element {
  const { courseSlug } = useParams<{ courseSlug: string }>();
  const { authAxios } = useContext(AuthContext);
  const [loadingNext, setLoading] = useState(false);
  const [autoplay, setAutoPlay] = useState(() => {
    const storageVal = localStorage.getItem("autoplay");
    if (storageVal) {
      const currentVal = JSON.parse(storageVal);
      if (currentVal) {
        return currentVal;
      }
      return false;
    }
    return false;
  });
  const [completed, setComplete] = useState(false); // have to manage the state here because the user can complete a lecture

  useEffect(() => {
    // is it completed or complete?
    setComplete(lecture.completed);
  }, [lecture]);

  function handleSubmitActivity() {
    if (autoplay && lecture.next) {
      setLoading(true);
    }
    return authAxios
      .post(api.analytics.activities.create, {
        lecture_slug: lecture.slug,
        course_slug: courseSlug
      })
      .then(async () => {
        // mutate updates the cache on all these fetches:
        await mutate(api.courses.progress(courseSlug));
        await mutate(api.sections.list(courseSlug));
        await mutate(api.profiles.experience);
        await mutate(api.profiles.badge);
        await mutate(api.analytics.dashboard);
      })
      .catch(() => {
        setLoading(false);
      });
  }

  function handleToggleAutoplay() {
    setAutoPlay(!autoplay);
    localStorage.setItem("autoplay", JSON.stringify(!autoplay));
  }

  function handleEnd() {
    handleSubmitActivity().then(() => {
      if (autoplay && lecture.next) {
        setLoading(false);
        history.push(`/courses/${courseSlug}/${lecture.next}`);
      }
    });
  }

  function handleRateChange(rate: number) {
    localStorage.setItem("playbackRate", JSON.stringify(rate));
  }

  function handleMarkAsComplete() {
    setLoading(true);
    authAxios
      .post(api.analytics.activities.create, {
        lecture_slug: lecture.slug,
        course_slug: courseSlug
      })
      .then(() => {
        setComplete(true);
        mutate(api.courses.progress(courseSlug));
        mutate(api.sections.list(courseSlug));
        mutate(api.profiles.experience);
        mutate(api.profiles.badge);
        mutate(api.analytics.dashboard);
      })
      .catch(() => null)
      .finally(() => setLoading(false));
  }

  function getCurrentPlaybackSpeed() {
    const playbackRateStorage = localStorage.getItem("playbackRate");
    if (playbackRateStorage) {
      const currentPlaybackRate = JSON.parse(playbackRateStorage);
      if (currentPlaybackRate >= 0.5 && currentPlaybackRate <= 2) {
        return currentPlaybackRate;
      }
    }
    return 1;
  }

  const player = useRef<HTMLVmPlayerElement>(null);

  const onPlaybackReady = () => {
    player.current && (player.current.playbackRate = getCurrentPlaybackSpeed());
  };

  const onSeekBackward = () => {
    const currentTime = player.current?.currentTime;
    if (currentTime !== undefined) {
      if (currentTime < 5) {
        player.current && (player.current.currentTime = 0);
      }
      player.current && (player.current.currentTime = currentTime - 5);
    }
  };

  const onSeekForward = () => {
    const currentTime = player.current?.currentTime;
    const duration = player.current?.duration;
    if (currentTime !== undefined && duration !== undefined) {
      if (currentTime > duration - 5) return;
      player.current && (player.current.currentTime = currentTime + 5);
    }
  };

  useHotkeys("left", () => {
    onSeekBackward();
  });

  useHotkeys("right", () => {
    onSeekForward();
  });

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "center"
      }}
    >
      {lecture.content && lecture.content.path ? (
        <div>
          <Player
            playsinline
            ref={player}
            playbackRate={getCurrentPlaybackSpeed()} // updates but video does not load with correct rate
            onVmPlaybackReady={onPlaybackReady}
            onVmPlaybackRateChange={(event) => handleRateChange(event.detail)}
            onVmPlaybackEnded={handleEnd}
            autoplay={autoplay} // does not work on initial load and in some cases when navigating to previous video
            isVideoView={true}
          >
            <Vimeo videoId={lecture.content.path} />
            <DefaultUi />
          </Player>
          <div className="flex justify-between items-center sm:mt-4">
            <div className="flex">
              {lecture.prev && (
                <NavLink to={`/courses/${courseSlug}/${lecture.prev}`}>
                  <button
                    type="button"
                    className="m-2 ml-0 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-50 focus:outline-none focus:border-indigo-300 focus:shadow-outline-indigo active:bg-indigo-200 transition ease-in-out duration-150"
                  >
                    Previous lecture
                  </button>
                </NavLink>
              )}
              {lecture.next && (
                <NavLink to={`/courses/${courseSlug}/${lecture.next}`}>
                  <button
                    type="button"
                    className="m-2 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150"
                  >
                    Next lecture
                  </button>
                </NavLink>
              )}
              {lecture.files && (
                <a
                  href={lecture.files}
                  download
                  className="m-2 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-gray-600 hover:bg-gray-900 focus:outline-none focus:border-gray-700 focus:bg-gray-900 transition ease-in-out duration-150"
                >
                  Download lecture files
                </a>
              )}
              <div className="m-2 inline-flex items-center px-3 py-2">
                <div className="absolute flex items-center h-5">
                  <input
                    checked={autoplay}
                    id="offers"
                    type="checkbox"
                    className="form-checkbox h-4 w-4 transition duration-150 ease-in-out"
                    onChange={() => handleToggleAutoplay()}
                  />
                </div>
                <div className="pl-7 text-sm leading-5">
                  <label htmlFor="offers" className="font-medium text-gray-200">
                    Autoplay
                  </label>
                </div>
                {loadingNext && (
                  <div className="bg-gray-200 px-3 py-2 rounded-sm ml-3">
                    <Loader light={false} />
                    <span className="text-gray-500">Loading next video..</span>
                  </div>
                )}
              </div>
            </div>
            {!lecture.completed && !completed && (
              <button
                onClick={() => handleMarkAsComplete()}
                type={"button"}
                className="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 duration-300 ease-in-out transition"
              >
                <CircleTickIcon className="-ml-1 mr-2 h-5 w-5" />
                Mark as Complete
              </button>
            )}
          </div>
        </div>
      ) : (
        <>
          <p>We couldn&apos;t load this video. Is this an error?</p>
          <NavLink to="/contact">Let us know so we can fix it ASAP!</NavLink>
        </>
      )}
    </div>
  );
}
