import React, { useContext, useState } from "react";
import { NavLink, Redirect } from "react-router-dom";
import { Formik, Form } from "formik";
import axios from "axios";
import * as Yup from "yup";
import { Loader, RequiredField, Message } from "src/components";
import errorHandler from "src/utils";
import api from "src/api";
import { AuthContext } from "src/contexts";

export default function ForgotPassword(): JSX.Element {
  const [error, setError] = useState<any>(null);
  const [success, setSuccess] = useState<boolean>(false);

  const { user } = useContext(AuthContext);
  if (user) {
    return <Redirect to={{ pathname: "/dashboard" }} />;
  }

  return (
    <div className="bg-gray-100 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">
          Reset your password
        </h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          {error && <Message type="ERROR" body={error} onDismiss={() => setError(null)} />}
          {success && (
            <Message
              type="SUCCESS"
              body="Your password has successfully been reset. An email has been sent with instructions."
              onDismiss={() => setSuccess(false)}
            />
          )}
          <Formik
            initialValues={{ email: "" }}
            validationSchema={Yup.object({
              email: Yup.string().email("Invalid email address").required("Required")
            })}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              setSubmitting(true);
              axios
                .post(api.auth.resetPassword, { email: values.email })
                .then(() => setSuccess(true))
                .catch((err) => setError(errorHandler(err)))
                .finally(() => {
                  setSubmitting(false);
                  resetForm();
                });
            }}
          >
            {(formik) => (
              <Form>
                <RequiredField
                  label="Email address"
                  formik={formik}
                  fieldName="email"
                  fieldType="email"
                />

                <div className="mt-6 flex items-center justify-between">
                  <div className="text-sm leading-5">
                    <NavLink
                      to="/login"
                      className="font-medium text-red-600 hover:text-red-500 focus:outline-none focus:underline transition ease-in-out duration-150"
                    >
                      Already have an account?
                    </NavLink>
                  </div>
                </div>

                <div className="mt-6">
                  <span className="block w-full rounded-md shadow-sm">
                    {formik.isSubmitting ? (
                      <button
                        type="button"
                        className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red active:bg-red-700 transition duration-150 ease-in-out"
                      >
                        <Loader />
                        Submitting
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red active:bg-red-700 transition duration-150 ease-in-out"
                      >
                        Submit
                      </button>
                    )}
                  </span>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
