import React, { ReactNode } from "react";
import { NavLink } from "react-router-dom";
import {
  APIIcon,
  BrowserIcon,
  DigitalOceanIcon,
  DjangoIcon,
  PackageIcon,
  PlansContainer,
  ReactIcon,
  RedisIcon,
  SecurityIcon,
  StripeIcon,
  TestIcon,
  DockerIcon
} from "src/components";
import {
  DeveloperFullIcon,
  DocumentsIcon,
  ProjectIcon,
  SyllabusIcon
} from "../components/icons/draw";

import ImageDashboardScreenshot from "../assets/images/dashboard_screenshot.png";
import ImageAppScreenshot from "../assets/images/appscreenshot.png";

function Features() {
  return (
    <div className="features px-4 md:px-8">
      <div className="container mx-auto">
        <div className="flex flex-col md:flex-row items-center content-center py-12 lg:pt-16 lg:pb-32">
          <div className="feature-1 md:w-1/2 text-center mb-4 lg:mb-0 relative">
            <img
              src={ImageAppScreenshot}
              className="lazy mx-auto z-10 relative w-2/3 md:w-4/5 lg:w-4/5"
              alt="JustDjango Classroom"
            />
          </div>

          <div className="w-full md:w-1/2 ">
            <h2 className="text-xl md:text-2xl lg:text-3xl font-semibold">
              Access more than 50 hours of video courses
            </h2>
            <p className="text-md lg:text-lg pt-4 pb-8 text-gray-700">
              Whether you&apos;re at a beginner or intermediate level, learn what&apos;s best for
              you.
            </p>
            <ul className="mt-10">
              <li>
                <div className="flex">
                  <div>
                    <h5 className="text-lg leading-6 font-medium text-gray-900">
                      The most comprehensive Django curriculum
                    </h5>
                    <p className="mt-2 text-base leading-6 text-gray-600">
                      By following our courses, you will cover many aspects of fullstack development
                      with Django.
                    </p>
                  </div>
                </div>
              </li>
              <li className="mt-10">
                <div className="flex">
                  <div>
                    <h5 className="text-lg leading-6 font-medium text-gray-900">
                      Suitable for all types of learners
                    </h5>
                    <p className="mt-2 text-base leading-6 text-gray-600">
                      Whether you&apos;re a beginner or comfortable with Django, we have courses
                      that can help you level up further.
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div className="flex flex-col md:flex-row items-center content-center py-12 lg:pb-16 lg:pt-16">
          <div className="feature-2 md:w-1/2 text-center order-first md:order-last mb-4 lg:mb-0 relative">
            <img
              src={ImageDashboardScreenshot}
              className="lazy mx-auto z-10 relative w-2/3 md:w-4/5 lg:w-2/3"
              alt="JustDjango Dashboard"
            />
          </div>

          <div className="w-full md:w-1/2 order-last md:order-first">
            <h2 className="text-xl md:text-2xl lg:text-3xl font-semibold">
              Measurable improvement
            </h2>
            <p className="text-md lg:text-lg pt-4 pb-8 text-gray-700">
              Utilise a minimalist dashboard to keep track of your progress.
            </p>
            <ul className="mt-10">
              <li>
                <div className="flex">
                  <div>
                    <h5 className="text-lg leading-6 font-medium text-gray-900">Simple Progress</h5>
                    <p className="mt-2 text-base leading-6 text-gray-600">
                      Each course has it&apos;s own progress, as well as an overall activity bar for
                      quick glance of how active you&apos;ve been.
                    </p>
                  </div>
                </div>
              </li>
              <li className="mt-10">
                <div className="flex">
                  <div>
                    <h5 className="text-lg leading-6 font-medium text-gray-900">
                      Badges and Experience
                    </h5>
                    <p className="mt-2 text-base leading-6 text-gray-600">
                      Evaluate your skills with <span className="italic">experience</span> and{" "}
                      <span className="italic">badges</span> to see how far you&apos;ve come.
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

const courseContentItems = [
  {
    id: 1,
    text: "Understand the essentials of Django",
    subtitle: "Models, Views, Templates, Forms, URLs and more",
    icon: <DjangoIcon />
  },
  {
    id: 2,
    text: "Build basic CRUD applications",
    subtitle: "Like blogs, notepads and todo apps.",
    icon: <BrowserIcon />
  },
  {
    id: 3,
    text: "Understand security concepts",
    subtitle: "Like sessions, middleware and user types",
    icon: <SecurityIcon />
  },
  {
    id: 4,
    text: "Build powerful applications",
    subtitle: "Leverage popular API's like Stripe to power-up your Django project",
    icon: <StripeIcon />
  }
];

const secondCourseItems = [
  {
    id: 4,
    text: "Learn how to evaluate third-party packages",
    subtitle: "Django-allauth, DRF and many others",
    icon: <PackageIcon />
  },
  {
    id: 5,
    text: "Django deployment essentials",
    subtitle: "Use cloud services like AWS, Digital Ocean and Heroku",
    icon: <DigitalOceanIcon />
  },
  {
    id: 6,
    text: "How to build Rest APIs",
    subtitle: "Master the Django Rest Framework",
    icon: <APIIcon />
  },
  {
    id: 7,
    text: "Integrate asynchronous task handlers",
    subtitle: "Technologies such as Celery and Redis",
    icon: <RedisIcon />
  }
];

const thirdCourseItems = [
  {
    id: 6,
    text: "Dockerize your project",
    subtitle: "Learn everything to know about dockerizing Django",
    icon: <DockerIcon />
  },
  // {
  //   id: 7,
  //   text: "Scale your project",
  //   subtitle: "Power-up your Django projects with Kubernetes",
  //   icon: <KubernetesIcon />
  // },
  {
    id: 8,
    text: "Testing and CI/CD",
    subtitle: "Using Pytest, GitHub actions and more",
    icon: <TestIcon />
  },
  {
    id: 9,
    text: "Build more complex frontends",
    subtitle: "Integrate JavaScript, Vue and React the right way",
    icon: <ReactIcon />
  }
];

function CourseContentListItem({
  item
}: {
  item: { id: number; icon: ReactNode; text: string; subtitle: string };
}) {
  return (
    <div
      key={item.id}
      className="w-full px-6 sm:px-4 lg:w-1/4 md:w-1/2 text-center lg:text-left mt-4 flex flex-col justify-between"
    >
      <div className="w-16 lazy self-start py-2 mx-auto lg:mx-0">{item.icon}</div>
      <div>
        <h4 className="mb-0 font-bold">{item.text}</h4>
        <p className="mb-0">{item.subtitle}</p>
      </div>
    </div>
  );
}

function CourseContent() {
  return (
    <>
      <div className="bg-gray-100 text-center md:text-left py-16 px-4">
        <div className="container mx-auto">
          <div className="pb-4 lg:pb-8">
            <div className="lg:w-full text-center">
              <h2 className="text-2xl md:text-4xl font-bold">Complete skills development</h2>
              <p className="mt-5 text-xl leading-7 text-gray-600 sm:text-center">
                A fullstack Django syllabus
              </p>
            </div>
          </div>
          <div className="flex flex-col justify-center md:flex-row md:flex-wrap">
            {courseContentItems.map((item) => {
              return (
                <div
                  key={item.id}
                  className="w-full px-6 sm:px-4 lg:w-1/4 md:w-1/2 text-center lg:text-left mt-4 flex flex-col justify-between"
                >
                  <div className="w-16 lazy self-start py-2 mx-auto lg:mx-0">{item.icon}</div>
                  <div>
                    <h4 className="mb-0 font-bold">{item.text}</h4>
                    <p className="mb-0">{item.subtitle}</p>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="flex flex-col md:flex-row md:flex-wrap my-4">
            {secondCourseItems.map((item) => {
              return <CourseContentListItem item={item} key={item.id} />;
            })}
          </div>

          <div className="flex flex-col md:flex-row md:flex-wrap my-4">
            {thirdCourseItems.map((item) => {
              return <CourseContentListItem item={item} key={item.id} />;
            })}
          </div>
        </div>
      </div>
    </>
  );
}

function Hero() {
  return (
    <main className="mt-16 mx-auto max-w-7xl px-4 sm:mt-24 sm:px-6 lg:mt-32">
      <div className="lg:grid lg:grid-cols-12 lg:gap-8">
        <div className="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
          <h1 className="text-3xl md:text-5xl lg:text-6xl font-bold leading-tight">
            <div className="hero-content-line">
              <div className="hero-content-line-inner">Become a</div>
            </div>
            <div className="hero-content-line">
              <div className="hero-content-line-inner">Professional</div>
            </div>
            <div className="hero-content-line">
              <div className="hero-content-line-inner">Django Developer</div>
            </div>
          </h1>
          <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
            Follow a structured learning syllabus for specializing in Django
          </p>
          <NavLink
            to="/pricing"
            className="mt-8 text-white bg-red-500 hover:bg-red-600 rounded border border-red-600 shadow font-semibold px-6 py-2 inline-block transition duration-500 ease-in-out cursor-pointer text-xl"
          >
            Start learning now
          </NavLink>
          <p className="mt-3 text-sm text-gray-500">
            Join 500+ students who use JustDjango every day
          </p>
        </div>
        <div className="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
          <div className="relative mx-auto w-full lg:max-w-md">
            <DeveloperFullIcon />
          </div>
        </div>
      </div>
    </main>
  );
}

function MainBenefit() {
  return (
    <div className="border-t border-b border-navy-200 py-16 bg-navy-100 my-16 px-4 fade-in fade-in-fourth">
      <div className="container mx-auto">
        <div className="mb-8">
          <div className="w-full lg:w-8/12 text-center mx-auto">
            <h2 className="text-2xl md:text-4xl leading-tight font-bold">
              Learn Django the right way
            </h2>
            <p className="text-lg py-2 text-gray-700">
              Use our Django syllabus to help you learn Django in a structured, easy-to-follow and
              logical way.
            </p>
          </div>
        </div>

        <div className="my-16 lg:flex">
          <div className="my-8 lg:my-0 w-full lg:w-2/6 text-center mt-4 flex flex-col justify-between align-content-start px-4">
            <SyllabusIcon className="lg:w-2/3 lazy self-start mx-auto py-2 md:py-4 w-1/2 md:w-1/3 lg:w-auto loaded" />
            <div>
              <h3 className="font-semibold text-xl md:text-2xl lg:text-xl">
                Follow a structured syllabus
              </h3>
              <p className="text-gray-600">
                There&apos;s a lot to learn. We&apos;ve structured it into a syllabus that covers
                everything from getting started to advanced concepts.
              </p>
            </div>
          </div>

          <div className="my-8 lg:my-0 w-full lg:w-2/6 text-center mt-4  flex flex-col align-content-start justify-between px-4">
            <ProjectIcon className="lg:w-2/3 lazy self-start mx-auto py-2 md:py-4 w-1/2 md:w-1/3 lg:w-auto loaded" />
            <div>
              <h3 className="font-semibold text-xl md:text-2xl lg:text-xl">
                Build real-world projects
              </h3>
              <p className="text-gray-600">
                Take the course code and use it for whatever you&apos;d like. Whether it&apos;s for
                your business or part of your portfolio.
              </p>
            </div>
          </div>

          <div className="my-8 lg:my-0 w-full lg:w-2/6 text-center mt-4  flex flex-col align-content-start justify-between px-4">
            <DocumentsIcon className="lg:w-1/2 lazy self-start mx-auto py-2 md:py-4 w-1/2 md:w-1/3 lg:w-auto loaded" />
            <h3 className="font-semibold text-xl md:text-2xl lg:text-xl">
              Access summaries and other documents
            </h3>
            <p className="text-gray-600">
              View and download summaries of lessons throughout the courses.
            </p>
          </div>
        </div>

        <div className=" mt-4">
          <div className="lg:w-1/2 mx-auto text-center mt-4">
            <blockquote className="italic text-lg text-gray-700">
              &quot;JustDjango is an incredible resource that I frequently dive into when working on
              my own projects. With hours of training tutorials and a multitude of project videos, I
              have been able to hone my skills and apply what I learn to my career. The deeper parts
              of Django are no longer a mystery to me, thanks to JustDjango!&quot;
            </blockquote>
            <div className="mt-3 flex justify-center content-center items-center">
              <span className="ml-2 font-bold text-gray-900">
                - Curtis Ross, student of JustDjango
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function SmallCTA() {
  return (
    <div className="py-8 bg-navy-100 border-t border-b border-navy-200">
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row justify-center content-center items-center text-center lg:text-left">
          <div className="px-4 flex flex-col content-center my-2 md:my-4 mx-4 md:my-0">
            <h4 className="text-lg font-semibold pb-0 mb-0 mb-md-0">
              Start learning: Try a free trial for 7 days
            </h4>
            <p className="mb-0 text-gray-700">Build real-world projects with Django</p>
          </div>

          <NavLink
            to="/signup"
            className="px-4 btn btn-secundary bg-red-500 rounded text-white font-semibold border border-red-700 shadow py-2 hover:bg-red-600"
          >
            Become a Django Pro
          </NavLink>
        </div>
      </div>
    </div>
  );
}

function Testimonials() {
  return (
    <div className="testimonials py-8 lg:py-16 px-4">
      <div className="container mx-auto">
        <div className="py-8">
          <div className="lg:w-4/6 text-center mx-auto">
            <h2 className="text-2xl md:text-4xl font-bold">
              Over 1000 students have used JustDjango to level up their skills. See what they say.
            </h2>
          </div>
        </div>

        <div className="text-center flex flex-col md:flex-row">
          <div className="md:w-1/2 my-4 lg:my-0 md:px-4">
            <blockquote className="italic text-gray-700 text-sm md:text-md lg:text-lg">
              &quot;JustDjango courses to me have been a definitely life changing experience.
              Matthew’s way of teaching is simply to understand, he goes through a lot of complex
              topics and explains everything line by line, while at the same time adding best
              practices and making useful, readable, scalable, and easy to understand code.&quot;
            </blockquote>
            <div className="mt-3 flex justify-center content-center items-center">
              <span className="ml-2 font-bold text-gray-900 md:text-sm">
                - Carlos Vides, student of JustDjango
              </span>
            </div>
          </div>

          <div className="md:w-1/2  my-4 lg:my-0 md:px-4">
            <blockquote className="italic text-gray-700 text-sm md:text-md lg:text-lg">
              &quot;JustDjango is an incredible resource that I frequently dive into when working on
              my own projects. With hours of training tutorials and a multitude of project videos, I
              have been able to hone my skills and apply what I learn to my career. The deeper parts
              of Django are no longer a mystery to me, thanks to JustDjango!&quot;
            </blockquote>
            <div className="mt-3 flex justify-center content-center items-center">
              <span className="ml-2 font-bold text-gray-900 md:text-sm">
                - Curtis Ross, student of JustDjango
              </span>
            </div>
          </div>
        </div>

        <div className="logos  mt-4 pt-16">
          <div className="lg:w-full text-center">
            <span className="font-bold text-gray-900">
              Our courses have an average rating of 4.7/5
            </span>
            <ul className="list-none flex justify-center items-center content-center mt-3">
              <li className="p-4 md:px-1 text-yellow-300">
                <svg
                  className="w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
                  />
                </svg>
              </li>
              <li className="p-4 md:px-1 text-yellow-300">
                <svg
                  className="w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
                  />
                </svg>
              </li>
              <li className="p-4 md:px-1 text-yellow-300">
                <svg
                  className="w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
                  />
                </svg>
              </li>
              <li className="p-4 md:px-1 text-yellow-300">
                <svg
                  className="w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
                  />
                </svg>
              </li>
              <li className="p-4 md:px-1 text-yellow-300">
                <svg
                  className="w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
                  />
                </svg>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default function Landing(): JSX.Element {
  return (
    <div>
      <div>
        <Hero />

        <MainBenefit />

        <Features />

        <CourseContent />

        <SmallCTA />

        <Testimonials />

        <PlansContainer />
      </div>
    </div>
  );
}
