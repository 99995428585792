import React, { useContext, useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import Prism from "prismjs";
import "../../assets/css/prism.css";
import ReactMarkdown from "react-markdown";
import { FaFilePdf } from "react-icons/fa";
import api from "../../api";
import { Loader } from "../../components";
import { Lecture } from "../../models";
import { mutate } from "swr";
import { AuthContext } from "../../contexts";

const Code: React.FC<any> = (props) => {
  useEffect(() => {
    setTimeout(() => Prism.highlightAll(), 0);
  }, []);
  if (props.inline) {
    return <span className="bg-gray-200 rounded p-1 italic">{props.children}</span>;
  }
  return (
    <pre>
      <code className={`language-${props.language}`}>
        {props.value ? props.value : props.children}
      </code>
    </pre>
  );
};

const Anchor: React.FC<any> = ({ href, children }) => {
  return (
    <a className="text-indigo-500" href={href} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );
};

const Paragraph: React.FC<any> = ({ children }) => {
  return (
    <div className="py-3">
      <p className="text-gray-800">{children}</p>
    </div>
  );
};

const Heading: React.FC<any> = ({ level, children }) => {
  let comp = <h4 className="text-lg">{children}</h4>;
  if (level === 1) {
    comp = <h1 className="text-4xl">{children}</h1>;
  }
  if (level === 2) {
    comp = <h2 className="text-3xl">{children}</h2>;
  }
  if (level === 3) {
    comp = <h3 className="text-2xl">{children}</h3>;
  }
  return <div className="py-2">{comp}</div>;
};

const List: React.FC<any> = ({ children }) => {
  return (
    <div className="py-3">
      <ul className="px-4">{children}</ul>
    </div>
  );
};

const ListItem: React.FC<any> = ({ ordered, children }) => {
  return (
    <li className={`text-gray-800 mt-2 ${ordered ? "list-decimal" : "list-disc"}`}>{children}</li>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TableRow: React.FC<any> = (props) => {
  if (props.isHeader && Array.isArray(props.children)) {
    return (
      <tr className="bg-gray-100 font-medium text-gray-500 uppercase tracking-wider">
        {props.children.map((c: any, j: number) => {
          return (
            <th
              key={j}
              className="px-6 py-3 border-b border-gray-200 bg-gray-100 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
            >
              {c.props.children[0].props.value}
            </th>
          );
        })}
      </tr>
    );
  }
  return <tr className="bg-white">{props.value ? props.value : props.children}</tr>;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TableCell: React.FC<any> = (props) => {
  return props.children.map((cell: any, i: number) => {
    return (
      <td
        key={i}
        className="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900"
      >
        {props.value ? props.value : props.children}
      </td>
    );
  });
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Table: React.FC<any> = (props) => {
  return (
    <div className="py-2 flex flex-col">
      <div className="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div className="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
          <table className="min-w-full">{props.children}</table>
        </div>
      </div>
    </div>
  );
};

const BlockQuote: React.FC<any> = (props) => {
  return (
    <blockquote className="p-2 bg-gray-100 border-l-4 border-gray-400 italic">
      {props.children}
    </blockquote>
  );
};

export const renderers = {
  code: Code,
  p: Paragraph,
  h1: Heading,
  h2: Heading,
  h3: Heading,
  h4: Heading,
  h5: Heading,
  ul: List,
  ol: List,
  li: ListItem,
  a: Anchor,
  // table: Table,
  // tr: TableRow,
  // td: TableCell,
  blockquote: BlockQuote
};

const Document: React.FC<{ lecture: Lecture }> = ({ lecture }) => {
  const [read, setRead] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { courseSlug }: { courseSlug: string } = useParams();
  const { authAxios } = useContext(AuthContext);

  const handleMarkAsRead = () => {
    setLoading(true);
    authAxios
      .post(api.analytics.activities.create, {
        lecture_slug: lecture.slug,
        course_slug: courseSlug
      })
      .then(() => {
        setRead(true);
        mutate(api.courses.progress(courseSlug));
        mutate(api.sections.list(courseSlug));
        mutate(api.profiles.experience);
        mutate(api.profiles.badge);
        mutate(api.analytics.dashboard);
      })
      .catch(() => null)
      .finally(() => setLoading(false));
  };

  const markdown = lecture.content.description;
  return (
    <div>
      <div className="flex justify-between">
        <div>
          <span className="inline-flex rounded-md">
            <a
              href={lecture.content.file}
              download
              className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-gray-600 hover:bg-gray-900 focus:outline-none focus:border-gray-700 focus:bg-gray-900 transition ease-in-out duration-150"
            >
              <FaFilePdf className="-ml-1 mr-2 h-5 w-5" />
              Download
            </a>
            {!read && (
              <>
                {!lecture.completed && (
                  <button
                    onClick={() => handleMarkAsRead()}
                    type="button"
                    className="ml-3 inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150"
                  >
                    {loading && <Loader />}
                    {loading
                      ? "Submitting"
                      : `Mark as ${lecture.content.is_assignment ? "complete" : "read"}`}
                  </button>
                )}
              </>
            )}
          </span>
        </div>
        <div>
          {lecture.prev && (
            <NavLink to={`/courses/${courseSlug}/${lecture.prev}`}>
              <button
                type="button"
                className="m-2 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-50 focus:outline-none focus:border-indigo-300 focus:shadow-outline-indigo active:bg-indigo-200 transition ease-in-out duration-150"
              >
                Previous lecture
              </button>
            </NavLink>
          )}
          {lecture.next && (
            <NavLink to={`/courses/${courseSlug}/${lecture.next}`}>
              <button
                type="button"
                className="m-2 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150"
              >
                Next lecture
              </button>
            </NavLink>
          )}
        </div>
      </div>
      <div className="mt-5 bg-white overflow-hidden shadow rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <ReactMarkdown components={renderers} skipHtml={false}>
            {markdown}
          </ReactMarkdown>
        </div>
      </div>
    </div>
  );
};

export default Document;
