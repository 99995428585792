import * as Sentry from "@sentry/browser";
import { DEBUG } from "../config";

function log(message) {
  if (DEBUG) {
    console.log(message);
  }
}

const errorHandler = (err) => {
  console.log(err);
  log(err);
  if (typeof err === "string") {
    return err;
  }
  if (err.response) {
    if (err.response.status === 500) {
      log("500 error");
    } else if (err.response.status === 404) {
      log("404 error");
    } else if (err.response.status === 400) {
      log("400 error");
    }
    const { data } = err.response;
    if (data.non_field_errors) {
      if (data.non_field_errors.length === 1) {
        return data.non_field_errors[0];
      }
      return data.non_field_errors;
    }
    if (data.message) {
      return data.message;
    }
    if (data.detail) {
      return data.detail;
    }
    const first = Object.keys(data);
    return data[first];
  }
  try {
    Sentry.captureMessage(err.toString());
  } catch (e) {
    log(e);
  }
  return "An unexpected error occurred";
};

export default errorHandler;
