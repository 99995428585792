import React, { useState } from "react";
import { Form, Formik } from "formik";
import axios from "axios";
import * as Yup from "yup";
import { Loader, RequiredField, Message } from "src/components";
import api from "src/api";
import errorHandler from "src/utils";

function ContactForm(): JSX.Element {
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState(null);

  return (
    <Formik
      initialValues={{ name: "", email: "", message: "" }}
      validationSchema={Yup.object({
        name: Yup.string().required("Required").min(3, "Must be 3 characters or more"),
        email: Yup.string().required("Required").email("Invalid email address"),
        message: Yup.string().required("Required").min(30, "Must be 30 characters or more")
      })}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        setSubmitting(true);
        axios
          .post(api.general.contact, values)
          .then(() => {
            setSubmitting(false);
            setSuccess(true);
            resetForm();
          })
          .catch((err) => {
            setSubmitting(false);
            setError(errorHandler(err));
          });
      }}
    >
      {(formik) => (
        <Form>
          {success && (
            <Message
              type="SUCCESS"
              body="We have received your message and will get back to you as soon as possible"
              onDismiss={() => setSuccess(false)}
            />
          )}

          {error && <Message type="ERROR" body={error} onDismiss={() => setError(null)} />}

          <div className="mt-6 grid grid-cols-1 row-gap-6 col-gap-4 sm:grid-cols-6">
            <RequiredField label="Your name" formik={formik} fieldName="name" fieldType="text" />
            <RequiredField label="Your email" formik={formik} fieldName="email" fieldType="email" />
            <RequiredField
              label="Your message"
              formik={formik}
              fieldName="message"
              fieldType="text"
              as="textarea"
            />
          </div>
          <span className="block rounded-md shadow-sm">
            <button
              disabled={formik.isSubmitting}
              type="submit"
              className="mt-5 w-full px-6 py-3 border border-transparent text-sm leading-4 uppercase font-medium rounded-md text-white bg-red-600 hover:bg-red-900 focus:outline-none focus:border-red-700 focus:bg-red-900 transition ease-in-out duration-150"
            >
              {formik.isSubmitting && <Loader />}
              {formik.isSubmitting ? "Sending" : "Send Message"}
            </button>
          </span>
        </Form>
      )}
    </Formik>
  );
}

export default function Contact(): JSX.Element {
  return (
    <div className="bg-gray-100 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">
          Get in touch
        </h2>
      </div>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <ContactForm />
        </div>
      </div>
    </div>
  );
}
