import React, { useContext } from "react";
import { NavLink, useParams } from "react-router-dom";
import useSWR from "swr";
import { parseTime } from "src/utils";
import { RightArrowIcon, SummaryCourseItem } from "src/components";
import { Course, RoadmapProgress, CourseProgress, Merchant } from "src/models";
import { AuthContext } from "src/contexts";
import { useFetch, useAuthSWR } from "src/helpers";
import api from "src/api";

import ImageBasicRoadmap from "../assets/images/basic-django-roadmap.png";
import ImageAdvancedRoadmap from "../assets/images/advanced-django-roadmap.png";

export const SummaryCourseList: React.FC<{ courses: Course[]; title: string }> = ({
  courses,
  title
}) => {
  const { data: progress } = useAuthSWR(api.analytics.dashboard);
  let roadmap: RoadmapProgress | null;
  if (progress) {
    roadmap = progress.filter((x: RoadmapProgress) => x.title === title)[0];
  }
  return (
    <>
      {courses.map((course) => {
        let courseProgress: CourseProgress = { course_title: "", progress: 0 };
        if (progress && roadmap) {
          courseProgress = roadmap.progresses.filter(
            (courseProgress: CourseProgress) => courseProgress.course_title === course.title
          )[0];
        }
        return (
          <SummaryCourseItem key={course.id} course={course} courseProgress={courseProgress} />
        );
      })}
    </>
  );
};

function UpcomingCourseListItem({ course }: { course: Course }) {
  return (
    <li key={course.id}>
      <div className="block">
        <div className="flex items-center px-4 py-4 sm:px-6">
          <div className="min-w-0 flex-1 flex items-center">
            <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
              <div>
                <div className="text-sm leading-5 font-medium text-indigo-600 truncate">
                  {course.title}
                </div>
                <span className="inline-flex items-center px-2 py-0.5 mb-1 rounded text-xs font-medium leading-4 bg-blue-100 text-blue-800">
                  Coming soon: available on {new Date(course.upcoming_date!).toDateString()}
                </span>
                <div className="text-sm leading-5 text-gray-900">{course.description}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
}

export function CourseListItem({
  course,
  merchant
}: {
  course: Course;
  merchant?: Merchant;
}): JSX.Element {
  const twoWeeksAgo = new Date(Date.now() - 12096e5);
  const recentlyAdded = new Date(course.timestamp) > twoWeeksAgo;
  const recentlyUpdated = course.last_updated ? new Date(course.last_updated) > twoWeeksAgo : null;
  const { user, authAxios } = useContext(AuthContext);

  const { data: courseRecommendation } = useSWR<{ next_lecture_slug: string }>(
    user && !course.upcoming ? api.courses.nextLecture(course.slug) : null,
    (url) => authAxios(url).then((res) => res.data),
    { revalidateOnFocus: false }
  );

  return (
    <>
      {course.upcoming && course.upcoming_date ? (
        <UpcomingCourseListItem key={course.id} course={course} />
      ) : (
        <li key={course.id}>
          <NavLink
            to={
              user
                ? `/courses/${course.slug}/${
                    courseRecommendation
                      ? courseRecommendation.next_lecture_slug
                      : course.first_lecture_slug
                  }`
                : "/login"
            }
            className="block hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
          >
            <div className="flex items-center px-4 py-4 sm:px-6">
              <div className="min-w-0 flex-1 flex items-center">
                <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                  <div>
                    <div className="text-sm leading-5 font-medium text-indigo-600 truncate">
                      {course.title}{" "}
                      {user ? (
                        <>
                          {course.is_free && merchant && !merchant.active_basic && (
                            <span className="ml-2 inline-flex items-center px-2 py-0.5 rounded text-xs font-medium leading-4 bg-green-100 text-green-800">
                              Free
                            </span>
                          )}
                        </>
                      ) : (
                        <>
                          {course.is_free && (
                            <span className="ml-2 inline-flex items-center px-2 py-0.5 rounded text-xs font-medium leading-4 bg-green-100 text-green-800">
                              Free
                            </span>
                          )}
                        </>
                      )}
                      {recentlyAdded && (
                        <span className="ml-2 inline-flex items-center px-2 py-0.5 rounded text-xs font-medium leading-4 bg-blue-100 text-blue-800">
                          Recently added
                        </span>
                      )}
                      {recentlyUpdated && (
                        <span className="ml-2 inline-flex items-center px-2 py-0.5 rounded text-xs font-medium leading-4 bg-yellow-100 text-yellow-800">
                          Recently updated
                        </span>
                      )}
                    </div>
                    <div className="text-sm leading-5 text-gray-900">{course.description}</div>
                    <div className="mt-2 flex items-center text-sm leading-5 text-gray-500">
                      <span className="truncate">
                        {course.experience} XP | {parseTime(course.length)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <RightArrowIcon className="h-5 w-5 text-gray-400" />
              </div>
            </div>
          </NavLink>
        </li>
      )}
    </>
  );
}

export default function Roadmap(): JSX.Element {
  const { roadmapSlug } = useParams<{ roadmapSlug: string }>();
  const { authAxios } = useContext(AuthContext);
  const { user } = useContext(AuthContext);
  const { data: roadmap } = useFetch(api.roadmaps.retrieve(roadmapSlug));
  const { data: merchant } = useSWR<Merchant>(
    user ? api.memberships.summary : null,
    (url) => authAxios(url).then((res) => res.data),
    { revalidateOnFocus: false }
  );
  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-md">
      <div className="max-w-screen-xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
        {!roadmap && (
          <>
            <div className="animate-pulse flex space-x-4 max-w-4xl mx-auto">
              <div className="flex-1 space-y-4 py-1">
                <div className="max-w-sm mx-auto rounded overflow-hidden">
                  <div className="h-64 bg-gray-300 rounded-sm" />
                  <div className="space-y-2 mt-5">
                    <div className="h-4 bg-gray-300 rounded-sm w-1/2" />
                    <div className="h-3 bg-gray-300 rounded-sm" />
                    <div className="h-3 bg-gray-300 rounded-sm" />
                    <div className="h-3 bg-gray-300 rounded-sm" />
                    <div className="h-3 bg-gray-300 rounded-sm" />
                  </div>
                </div>
              </div>
            </div>
            <div className="max-w-4xl mx-auto flex flex-col mt-5">
              <div>
                {Array(10)
                  .fill(undefined)
                  .map((i, j) => {
                    return (
                      <div className="animate-pulse flex space-x-4" key={j}>
                        <div className="flex-1 space-y-4 py-4">
                          <div className="rounded overflow-hidden">
                            <div className="space-y-2 mt-5">
                              <div className="h-4 bg-gray-300 rounded-sm w-1/3" />
                              <div className="h-3 bg-gray-300 rounded-sm" />
                              <div className="h-3 bg-gray-300 rounded-sm" />
                              <div className="h-3 bg-gray-300 rounded-sm" />
                              <div
                                className="h-3 bg-gray-300 rounded-sm"
                                style={{ width: "80px" }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </>
        )}

        {roadmap && (
          <main className="mt-2 mx-auto max-w-7xl px-4 sm:mt-3 sm:px-6 lg:mt-4">
            <div className="lg:grid lg:grid-cols-12 lg:gap-8">
              <div className="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
                <h1>
                  <span className="mt-1 block text-4xl tracking-tight font-extrabold sm:text-5xl">
                    <NavLink className="block text-gray-900" to={`/roadmaps/${roadmap.slug}`}>
                      {roadmap.title} Roadmap
                    </NavLink>
                  </span>
                </h1>
                <p className="mt-3 text-gray-700 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                  {roadmap.description}
                </p>
              </div>
              <div className="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
                <div className="relative mx-auto w-full rounded-lg shadow-lg lg:max-w-md">
                  <img
                    className="w-full"
                    src={roadmap.slug === "django-basic" ? ImageBasicRoadmap : ImageAdvancedRoadmap}
                    alt={roadmap.title}
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-wrap justify-center mt-12">
              <ul>
                {roadmap.courses
                  .filter((x: { archived: boolean }) => x.archived === false)
                  .map((course: Course) => {
                    return <CourseListItem key={course.id} course={course} merchant={merchant} />;
                  })}
              </ul>
            </div>
            {roadmap.courses.filter((x: { archived: boolean }) => x.archived === true).length >
              0 && (
              <div className="py-5 mt-5 border-t border-gray-200 flex justify-center">
                <NavLink to="/archives/django-basic" className="text-gray-700 hover:text-blue-700">
                  Archives
                </NavLink>
              </div>
            )}
          </main>
        )}
      </div>
    </div>
  );
}
