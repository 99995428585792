import React, { useState } from "react";

const faqItems = [
  {
    id: 0,
    question: "How are these courses different to the ones on YouTube?",
    answer:
      "The difference is that this website provides a 'roadmap' that guides you in an ordered and structured way of " +
      "learning. The concepts covered contain everything you need to know to become a professional Django developer, most " +
      "of which is not taught in the YouTube videos."
  },
  {
    id: 1,
    question: "Is there a student discount?",
    answer:
      "Education matters to us. We do not offer discounts yet for education users, but we are working on it."
  },
  {
    id: 2,
    question: "Can I change plans later on?",
    answer:
      "Yes you can manage your subscription on your profile. There you can change your plan at any time."
  },
  {
    id: 3,
    question: "Can I try before I buy?",
    answer:
      "Yes. You get a one week trial where you can evaluate the platform. Note that the free trial does not give access to all of the courses."
  },
  {
    id: 4,
    question: "Do you offer bulk discounts?",
    answer: "Yes. For further information, please get in touch."
  },
  {
    id: 5,
    question: "What version of Django do the courses use?",
    answer:
      "Most courses are using version 2.2 which is an LTS version. Newer courses are being made with version 3."
  },
  {
    id: 6,
    question: "Can I cancel my subscription?",
    answer:
      "Yes. You can cancel your subscription at any time by going to your billing settings in your profile."
  }
  // {
  //   id: 7,
  //   question: "How does the Slack workspace work?",
  //   answer:
  //     "Once you have enrolled, we will send you an invite to join Slack. The signup process takes less than two minutes and once your account is setup, you can login to Slack online, or use their native applications."
  // }
];

export default function FAQ(): JSX.Element {
  const [activeItem, setActiveItem] = useState<number | null>(null);
  return (
    <div className="py-16 lg:py-12 bg-white">
      <div className="max-w-screen-xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto">
          <h2 className="text-center text-3xl leading-9 font-extrabold text-gray-900 sm:text-4xl sm:leading-10">
            Frequently asked questions
          </h2>
          <div className="mt-6 border-t-2 border-gray-200 pt-6">
            <dl>
              {faqItems.map((item) => {
                return (
                  <div
                    key={item.id}
                    className={item.id > 0 ? "mt-6 border-t border-gray-200 pt-6" : ""}
                  >
                    <dt className="text-lg leading-7">
                      <button
                        className="text-left w-full flex justify-between items-start text-gray-400 focus:outline-none focus:text-gray-900"
                        type="button"
                        onClick={() => setActiveItem(item.id === activeItem ? null : item.id)}
                      >
                        <span className="font-medium text-gray-900">{item.question}</span>
                        <span className="ml-6 h-7 flex items-center">
                          <svg
                            className={`h-6 w-6 transform ${
                              activeItem === item.id ? "-rotate-180" : ""
                            }`}
                            stroke="currentColor"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M19 9l-7 7-7-7"
                            />
                          </svg>
                        </span>
                      </button>
                    </dt>
                    {activeItem === item.id && (
                      <dd className="mt-2 pr-12">
                        <p className="text-base leading-6 text-gray-500">{item.answer}</p>
                      </dd>
                    )}
                  </div>
                );
              })}
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
}
