import React from "react";
import { NavLink } from "react-router-dom";

export default function PrivacyPolicy(): JSX.Element {
  return (
    <div className="bg-gray-100 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md md:max-w-xl lg:max-w-3xl">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <div className="flex justify-center">
            <div className="col-xl-7 col-lg-8">
              <p className="text-gray-500 capitalize tracking-wide">Updated DECEMBER 8, 2019</p>
              <h1 className="text-3xl">Privacy Policy</h1>
              <hr className="mt-3 mb-3" />
              <div className="prose-lg">
                <p>
                  JustDjango (&quot;us&quot;, &quot;we&quot;, or &quot;our&quot;) operates the{" "}
                  <a
                    className="text-indigo-500 hover:text-indigo-600"
                    href="https://www.justdjango.com"
                  >
                    https://www.justdjango.com
                  </a>{" "}
                  and{" "}
                  <a
                    className="text-indigo-500 hover:text-indigo-600"
                    href="https://learn.justdjango.com"
                  >
                    https://learn.justdjango.com
                  </a>{" "}
                  websites (the &quot;Service&quot;).
                </p>

                <p>
                  We use your data to provide and improve the Service. By using the Service, you
                  agree to the collection and use of information in accordance with this policy.
                  Unless otherwise defined in this Privacy Policy, terms used in this Privacy Policy
                  have the same meanings as in our Terms and Conditions, accessible from
                  http://www.justdjango.com
                </p>

                <h3>Information Collection And Use</h3>

                <p>
                  We collect several different types of information for various purposes to provide
                  and improve our Service to you.
                </p>

                <h3>Types of Data Collected</h3>

                <h5>Personal Data</h5>

                <p>
                  While using our Service, we may ask you to provide us with certain personally
                  identifiable information that can be used to contact or identify you
                  (&quot;Personal Data&quot;). Personally identifiable information may include, but
                  is not limited to:
                </p>

                <ul>
                  <li>
                    <p>Email address</p>
                  </li>
                  <li>
                    <p>First name and last name</p>
                  </li>
                  <li>
                    <p>Cookies and Usage Data</p>
                  </li>
                </ul>

                <h5>Social Network Service Data</h5>

                <p>
                  Should you choose to create an Account using your Gmail account, JustDjango gains
                  access to your Gmail account data to allow creation of your account. We do not
                  analyse, store, transfer or use your Gmail account data in any way other than for
                  the purpose of creating your account on our Service. This is purely for the
                  convenience of being able to login/signup into the website.
                </p>

                <h5>Usage Data</h5>

                <p>
                  We may also collect information how the Service is accessed and used (&quot;Usage
                  Data&quot;). This Usage Data may include information such as your computer&apos;s
                  Internet Protocol address (e.g. IP address), browser type, browser version, the
                  pages of our Service that you visit, the time and date of your visit, the time
                  spent on those pages, unique device identifiers and other diagnostic data.
                </p>

                <h5>Tracking & Cookies Data</h5>
                <p>
                  We use cookies and similar tracking technologies to track the activity on our
                  Service and hold certain information.
                </p>
                <p>
                  Cookies are files with small amount of data which may include an anonymous unique
                  identifier. Cookies are sent to your browser from a website and stored on your
                  device. Tracking technologies also used are beacons, tags, and scripts to collect
                  and track information and to improve and analyze our Service.
                </p>
                <p>
                  You can instruct your browser to refuse all cookies or to indicate when a cookie
                  is being sent. However, if you do not accept cookies, you may not be able to use
                  some portions of our Service.
                </p>
                <p>Examples of Cookies we use:</p>
                <ul>
                  <li>
                    <p>
                      <strong>Session Cookies.</strong> We use Session Cookies to operate our
                      Service.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Preference Cookies.</strong> We use Preference Cookies to remember
                      your your your preferences and various settings.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Security Cookies.</strong> We use Security Cookies for security
                      purposes.
                    </p>
                  </li>
                </ul>

                <h3>Use of Data</h3>

                <p>JustDjango uses the collected data for various purposes:</p>
                <ul>
                  <li>
                    <p>To provide and maintain the Service</p>
                  </li>
                  <li>
                    <p>To notify you about changes to our Service</p>
                  </li>
                  <li>
                    <p>
                      To allow you to participate in interactive features of our Service when you
                      choose to do so
                    </p>
                  </li>
                  <li>
                    <p>To provide customer care and support</p>
                  </li>
                  <li>
                    <p>
                      To provide analysis or valuable information so that we can improve the Service
                    </p>
                  </li>
                  <li>
                    <p>To monitor the usage of the Service</p>
                  </li>
                  <li>
                    <p>To detect, prevent and address technical issues</p>
                  </li>
                </ul>

                <h3>Transfer Of Data</h3>
                <p>
                  Your information, including Personal Data, may be transferred to — and maintained
                  on — computers located outside of your state, province, country or other
                  governmental jurisdiction where the data protection laws may differ than those
                  from your jurisdiction.
                </p>
                <p>
                  If you are located outside South Africa and choose to provide information to us,
                  please note that we transfer the data, including Personal Data, to South Africa
                  and process it there.
                </p>
                <p>
                  Your consent to this Privacy Policy followed by your submission of such
                  information represents your agreement to that transfer.
                </p>
                <p>
                  JustDjango will take all steps reasonably necessary to ensure that your data is
                  treated securely and in accordance with this Privacy Policy and no transfer of
                  your Personal Data will take place to an organization or a country unless there
                  are adequate controls in place including the security of your data and other
                  personal information.
                </p>

                <h3>Disclosure Of Data</h3>

                <h5>Legal Requirements</h5>
                <p>
                  JustDjango may disclose your Personal Data in the good faith belief that such
                  action is necessary to:
                </p>
                <ul>
                  <li>
                    <p>To comply with a legal obligation</p>
                  </li>
                  <li>
                    <p>To protect and defend the rights or property of JustDjango</p>
                  </li>
                  <li>
                    <p>
                      To prevent or investigate possible wrongdoing in connection with the Service
                    </p>
                  </li>
                  <li>
                    <p>To protect the personal safety of users of the Service or the public</p>
                  </li>
                  <li>
                    <p>To protect against legal liability</p>
                  </li>
                </ul>

                <h3>Security Of Data</h3>
                <p>
                  The security of your data is important to us, but remember that no method of
                  transmission over the Internet, or method of electronic storage is 100% secure.
                  While we strive to use commercially acceptable means to protect your Personal
                  Data, we cannot guarantee its absolute security.
                </p>

                <h3>Service Providers</h3>
                <p>
                  We may employ third party companies and individuals to facilitate our Service
                  (&quot;Service Providers&quot;), to provide the Service on our behalf, to perform
                  Service-related services or to assist us in analyzing how our Service is used.
                </p>
                <p>
                  These third parties have access to your Personal Data only to perform these tasks
                  on our behalf and are obligated not to disclose or use it for any other purpose.
                </p>

                <h3>Analytics</h3>
                <p>
                  We may use third-party Service Providers to monitor and analyze the use of our
                  Service.
                </p>
                <ul>
                  <li>
                    <p>
                      <strong>Google Analytics</strong>
                    </p>
                    <p>
                      Google Analytics is a web analytics service offered by Google that tracks and
                      reports website traffic. Google uses the data collected to track and monitor
                      the use of our Service. This data is shared with other Google services. Google
                      may use the collected data to contextualize and personalize the ads of its own
                      advertising network.
                    </p>
                    <p>
                      You can opt-out of having made your activity on the Service available to
                      Google Analytics by installing the Google Analytics opt-out browser add-on.
                      The add-on prevents the Google Analytics JavaScript (ga.js, analytics.js, and
                      dc.js) from sharing information with Google Analytics about visits activity.
                    </p>
                    <p>
                      For more information on the privacy practices of Google, please visit the
                      Google Privacy & Terms web page:
                      <a
                        href="https://policies.google.com/privacy?hl=en"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        https://policies.google.com/privacy?hl=en
                      </a>
                    </p>
                  </li>
                </ul>

                <h3>Links To Other Sites</h3>
                <p>
                  Our Service may contain links to other sites that are not operated by us. If you
                  click on a third party link, you will be directed to that third party&apos;s site.
                  We strongly advise you to review the Privacy Policy of every site you visit.
                </p>
                <p>
                  We have no control over and assume no responsibility for the content, privacy
                  policies or practices of any third party sites or services.
                </p>

                <h3>Children&apos;s Privacy</h3>
                <p>
                  Our Service does not address anyone under the age of 18 (&quot;Children&quot;).
                </p>
                <p>
                  We do not knowingly collect personally identifiable information from anyone under
                  the age of 18. If you are a parent or guardian and you are aware that your
                  Children has provided us with Personal Data, please contact us. If we become aware
                  that we have collected Personal Data from children without verification of
                  parental consent, we take steps to remove that information from our servers.
                </p>

                <h3>Changes To This Privacy Policy</h3>
                <p>
                  We may update our Privacy Policy from time to time. We will notify you of any
                  changes by posting the new Privacy Policy on this page.
                </p>
                <p>
                  We will let you know via email and/or a prominent notice on our Service, prior to
                  the change becoming effective and update the &quot;effective date&quot; at the top
                  of this Privacy Policy.
                </p>
                <p>
                  You are advised to review this Privacy Policy periodically for any changes.
                  Changes to this Privacy Policy are effective when they are posted on this page.
                </p>

                <h3>Contact Us</h3>
                <p>If you have any questions about this Privacy Policy, please contact us:</p>
                <ul>
                  <li>By email: admin@justdjango.com</li>
                  <li>
                    By visiting the{" "}
                    <NavLink to="/contact" className="text-indigo-500 hover:text-indigo-600">
                      contact page
                    </NavLink>{" "}
                    on this website
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
