import React, { useContext } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Disclosure, Menu } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { DropdownProfile, MobileDropdownProfile } from "src/components";
import { AuthContext } from "src/contexts";
import Logo from "src/assets/images/logo.svg";

export default function Navbar(): JSX.Element {
  const { user } = useContext(AuthContext);
  const { pathname } = useLocation();

  const currentClassName = "border-indigo-500 text-gray-900";
  const defaultClassName =
    "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700";

  const currentMobileClassName = "bg-indigo-50 border-indigo-500 text-indigo-700";
  const defaultMobileClassName =
    "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700";

  return (
    <Disclosure as="nav" className="relative bg-white shadow">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between h-16">
              <div className="flex">
                <NavLink to="/" className="flex-shrink-0 flex items-center">
                  <img className="block h-8 w-auto" src={Logo} alt="JustDjango" />
                </NavLink>
                <div className="hidden md:ml-6 md:flex md:space-x-8">
                  {/* Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
                  {user && (
                    <NavLink
                      to="/dashboard"
                      className={`${
                        pathname === "/dashboard" ? currentClassName : defaultClassName
                      } inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium`}
                    >
                      Dashboard
                    </NavLink>
                  )}
                  <NavLink
                    to="/roadmaps/django-basic"
                    className={`${
                      pathname === "/roadmaps/django-basic" ? currentClassName : defaultClassName
                    } inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium`}
                  >
                    Basic Roadmap
                  </NavLink>
                  <NavLink
                    to="/roadmaps/django-advanced"
                    className={`${
                      pathname === "/roadmaps/django-advanced" ? currentClassName : defaultClassName
                    } inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium`}
                  >
                    Advanced Roadmap
                  </NavLink>
                  {!user && (
                    <NavLink
                      to="/pricing"
                      className={`${
                        pathname === "/pricing" ? currentClassName : defaultClassName
                      } inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium`}
                    >
                      Pricing
                    </NavLink>
                  )}
                </div>
              </div>
              <div className="hidden md:ml-6 md:flex md:items-center">
                {!user && (
                  <ul className="flex">
                    <li>
                      <NavLink
                        className="px-4 py-2 text-gray-700 hover:text-gray-900 transition duration-500 ease-in-out hover:rounded hover:bg-gray-200 rounded text-gray-900"
                        to="/login"
                      >
                        Login
                      </NavLink>
                    </li>
                    <li className="nav-item px-2 signup">
                      <NavLink
                        className="text-white px-3 py-2 bg-red-500 hover:bg-red-600 rounded border border-red-600 shadow font-semibold transition duration-500 ease-in-out"
                        to="/signup"
                      >
                        Become a Django Pro
                      </NavLink>
                    </li>
                  </ul>
                )}

                {/* Profile dropdown */}
                <Menu as="div" className="ml-3 relative">
                  {({ open }) => <DropdownProfile open={open} />}
                </Menu>
              </div>
              <div className="-mr-2 flex items-center md:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          {/* MOBILE NAVBAR */}
          <Disclosure.Panel className="md:hidden">
            <div className="pt-2 pb-3 space-y-1">
              {user && (
                <NavLink
                  to="/dashboard"
                  className={`${
                    pathname === "/dashboard" ? currentMobileClassName : defaultMobileClassName
                  } block pl-3 pr-4 py-2 border-l-4 text-base font-medium`}
                >
                  Dashboard
                </NavLink>
              )}
              <NavLink
                to="/roadmaps/django-basic"
                className={`${
                  pathname === "/roadmaps/django-basic"
                    ? currentMobileClassName
                    : defaultMobileClassName
                } block pl-3 pr-4 py-2 border-l-4 text-base font-medium`}
              >
                Basic Roadmap
              </NavLink>
              <NavLink
                to="/roadmaps/django-advanced"
                className={`${
                  pathname === "/roadmaps/django-advanced"
                    ? currentMobileClassName
                    : defaultMobileClassName
                } block pl-3 pr-4 py-2 border-l-4 text-base font-medium`}
              >
                Advanced Roadmap
              </NavLink>
              <NavLink
                to="/pricing"
                className={`${
                  pathname === "/pricing" ? currentMobileClassName : defaultMobileClassName
                } block pl-3 pr-4 py-2 border-l-4 text-base font-medium`}
              >
                Pricing
              </NavLink>
            </div>

            <div className="pt-4 pb-3 border-t border-gray-200">
              {!user && (
                <div className="px-4">
                  <NavLink to="/signup" className="w-full flex rounded-md shadow-sm">
                    <div className="cursor-pointer w-full flex items-center justify-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red active:bg-red-700 transition ease-in-out duration-150">
                      Sign up
                    </div>
                  </NavLink>
                  <p className="mt-3 text-center text-base leading-6 font-medium text-gray-500">
                    Have an account?{" "}
                    <NavLink
                      to="/login"
                      className="cursor-pointer text-red-600 hover:text-red-500 transition ease-in-out duration-150"
                    >
                      Sign in
                    </NavLink>
                  </p>
                </div>
              )}
              {user && <MobileDropdownProfile />}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
