import React, { useContext, useState } from "react";
import { NavLink, Redirect } from "react-router-dom";
import { Formik, Form } from "formik";
import GoogleLogin, { GoogleLoginResponse, GoogleLoginResponseOffline } from "react-google-login";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { Button, RequiredField, Message, GoogleIcon } from "src/components";
import errorHandler from "src/utils";
import { googleClientID } from "src/config";
import { history } from "src/helpers";
import { AuthContext } from "src/contexts";

export function LoginForm({
  initialEmail,
  onLoginCallback,
  onGoogleLoginCallback
}: {
  initialEmail?: string;
  onLoginCallback: () => void;
  onGoogleLoginCallback: () => void;
}): JSX.Element {
  const [error, setError] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { login, googleLogin } = useContext(AuthContext);

  const handleGoogleLogin = (response: GoogleLoginResponse | GoogleLoginResponseOffline) => {
    setLoading(true);
    if ("tokenObj" in response) {
      const { tokenObj } = response;
      if (tokenObj) {
        const { access_token, id_token } = tokenObj;
        googleLogin(access_token, id_token)
          .then(() => {
            onGoogleLoginCallback();
          })
          .catch((err: any) => setError(errorHandler(err)))
          .finally(() => setLoading(false));
      } else {
        setLoading(false);
        setError("There was an error connecting to your account");
      }
    } else {
      setLoading(false);
      setError("There was an error connecting to your account");
    }
  };

  const handleGoogleLoginFailure = (error: any) => {
    setLoading(false);
    setError(errorHandler(error));
  };

  return (
    <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        {error && (
          <div className="mb-3">
            <Message type="ERROR" body={error} onDismiss={() => setError(null)} />
          </div>
        )}

        <div>
          <span className="w-full inline-flex rounded-md shadow-sm">
            <GoogleLogin
              render={(renderProps) => (
                <Button
                  disabled={renderProps.disabled}
                  onClick={renderProps.onClick}
                  type="button"
                  className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition duration-150 ease-in-out"
                >
                  <GoogleIcon />
                  Sign in with Google
                </Button>
              )}
              clientId={googleClientID}
              onSuccess={handleGoogleLogin}
              onFailure={handleGoogleLoginFailure}
              cookiePolicy="single_host_origin"
            />
          </span>
        </div>

        <div className="mt-6">
          <div className="mt-6 grid grid-cols-1 gap-3">
            <Formik
              initialValues={{
                email: initialEmail ? initialEmail : "",
                password: ""
              }}
              validationSchema={Yup.object({
                email: Yup.string().email("Invalid email address").required("Required"),
                password: Yup.string().min(6, "Must be 6 characters or more").required("Required")
              })}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);
                login(values.email, values.password)
                  .then(() => {
                    onLoginCallback();
                  })
                  .catch((err: any) => setError(errorHandler(err)))
                  .finally(() => {
                    setSubmitting(false);
                  });
              }}
            >
              {(formik) => (
                <Form>
                  <RequiredField
                    label="Email address"
                    formik={formik}
                    fieldName="email"
                    fieldType="email"
                  />
                  <div className="mt-6">
                    <RequiredField
                      label="Password"
                      formik={formik}
                      fieldName="password"
                      fieldType="password"
                    />
                  </div>

                  <div className="mt-6 flex items-center justify-between">
                    <div className="text-sm leading-5">
                      <NavLink
                        to="/forgot-password"
                        className="font-medium text-red-600 hover:text-red-500 focus:outline-none focus:underline transition ease-in-out duration-150"
                      >
                        Forgot your password?
                      </NavLink>
                    </div>
                  </div>

                  <div className="mt-6">
                    <span className="block w-full rounded-md shadow-sm">
                      <Button
                        disabled={formik.isSubmitting || loading}
                        type="submit"
                        className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red active:bg-red-700 transition duration-150 ease-in-out"
                        loading={formik.isSubmitting}
                        label={formik.isSubmitting ? "Signing in..." : "Sign in"}
                      />
                    </span>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}

export default function Login(): JSX.Element {
  const { user } = useContext(AuthContext);

  if (user) {
    return <Redirect to={{ pathname: "/dashboard" }} />;
  }

  function handleLogin() {
    toast.success("Successfully Logged in", {
      duration: 4000,
      icon: "👋",
      iconTheme: {
        primary: "#000",
        secondary: "#fff"
      }
    });
    history.push("/dashboard");
  }

  return (
    <div className="bg-gray-100 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">Login</h2>
        <p className="mt-2 text-center text-sm leading-5 text-gray-600 max-w">
          Or{" "}
          <NavLink
            to="/signup"
            className="font-medium text-red-600 hover:text-red-500 focus:outline-none focus:underline transition ease-in-out duration-150"
          >
            create an account
          </NavLink>
        </p>
      </div>
      <LoginForm onLoginCallback={handleLogin} onGoogleLoginCallback={handleLogin} />
    </div>
  );
}
