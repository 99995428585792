/* eslint-disable */
import { NavLink } from "react-router-dom";

export default function TermsAndConditions() {
  return (
    <div className="bg-gray-100 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md md:max-w-xl lg:max-w-3xl">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <div className="flex justify-center">
            <div className="col-xl-7 col-lg-8">
              <p className="text-gray-500 capitalize tracking-wide">Updated DECEMBER 8, 2019</p>
              <h1 className="text-3xl">Terms of Use</h1>
              <hr className="mt-3 mb-3" />
              <div className="prose-lg">
                <p>
                  We use your data to provide and improve the Service. By using the Service, you
                  agree to the collection and use of information in accordance with this policy.
                  Unless otherwise defined in this Privacy Policy, terms used in this Privacy Policy
                  have the same meanings as in our Terms and Conditions, accessible from
                  http://www.justdjango.com
                </p>

                <h3>Information Collection And Use</h3>

                <p>
                  THESE TERMS SET FORTH LEGALLY BINDING TERMS AND CONDITIONS THAT GOVERN YOUR USE OF
                  THE SERVICE. BY ACCESSING OR USING THE SITE OR SERVICE, YOU ARE ACCEPTING THESE
                  TERMS (ON BEHALF OF YOURSELF OR THE ENTITY THAT YOU REPRESENT), AND YOU REPRESENT
                  AND WARRANT THAT YOU HAVE THE RIGHT, AUTHORITY, AND CAPACITY TO ENTER INTO THESE
                  TERMS (ON BEHALF OF YOURSELF OR THE ENTITY THAT YOU REPRESENT). YOU MAY NOT ACCESS
                  OR USE THE SITE OR SERVICE IF YOU ARE NOT AT LEAST 18 YEARS OLD (OR THE AGE OF
                  MAJORITY IN THE JURISDICTION FROM WHICH YOU ARE ACCESSING THE SITE OR SERVICE OR
                  ARE AT LEAST 13 YEARS OLD AND HAVE YOUR PARENT OR LEGAL GUARDIAN'S CONSENT). IF
                  YOU DO NOT AGREE TO COMPLY WITH THESE TERMS, DO NOT ACCESS AND/OR USE THE SITE OR
                  SERVICE.
                </p>

                <h3>1. Account</h3>
                <h5>1.1 Account Creation</h5>

                <p>
                  In order to use certain features of the Service, you must register for an account
                  ("Account") and provide certain information about yourself. You represent and
                  warrant that: (a) all registration information you submit is truthful and
                  accurate, and (b) you will maintain the accuracy of such information. You agree
                  that all account information you provide is governed by our
                  <a href="{% url 'privacy-policy' %}">Privacy Policy</a>
                  and you consent to all actions we take with respect to your information consistent
                  with our Privacy Policy. Our Privacy Policy forms a part of these Terms. You may
                  delete your Account at any time, for any reason, by following the instructions on
                  the Service. JustDjango may suspend or terminate your Account in accordance with
                  Section 5.
                </p>

                <h5>1.2 Access Through a SNS</h5>

                <p>
                  If you access the Site through a social networking service ("SNS"), you may link
                  your Account with your SNS account. This will allow JustDjango to access your SNS
                  account, subject to the applicable terms and conditions that govern your use of
                  each SNS account. You represent that you are entitled to disclose your SNS account
                  login information to JustDjango and/or grant JustDjango access to your SNS account
                  as set forth herein and that doing so does not breach any provisions of the
                  applicable SNS account. By granting JustDjango access to any SNS accounts, you
                  understand that JustDjango may access, make available, and store (if applicable)
                  any information, data, text, software, music, sound, photographs, graphics, video,
                  messages, tags and/or other materials accessible on your SNS account through the
                  Service. You may disable the connection between your Account and your SNS account
                  at any time by accessing the "Account" section of the Service.
                </p>

                <h5>1.3 Account Responsibilities</h5>

                <p>
                  You are responsible for maintaining the confidentiality of your Account login
                  information and for all activities that occur under your Account. You agree to
                  immediately notify JustDjango of any unauthorized use, or suspected unauthorized
                  use of your Account or any other breach of security. JustDjango cannot and will
                  not be liable for any loss or damage arising from your failure to comply with the
                  above requirements.
                </p>

                <h3>2. JustDjango Plans and Purchase Terms</h3>
                <h5>2.1 Plans</h5>

                <p>
                  The features and price of your access to the Service is determined by the
                  subscription plan and account type you register for, purchase, renew, or change
                  into (each, a "Plan")—to review the features and price associated with
                  JustDjango's Plans, please visit
                  <NavLink to="/pricing">pricing</NavLink>
                </p>

                <h5>2.2 Free plan</h5>

                <p>
                  If you sign-up for a free plan ("Free Plan") through our Site, JustDjango grants
                  you a non-transferable, non-exclusive, revocable, limited license to use and
                  access the Service in strict accordance with these Terms. We reserve the right, in
                  our absolute discretion, to withdraw or to modify your access to the Service at
                  any time without prior notice and with no liability.
                </p>

                <h5>2.3 Premium Plan</h5>

                <p>
                  If you purchase a pro subscription plan ("Pro Plan"), JustDjango grants you a
                  non-transferable, non-exclusive, revocable, limited license to use and access the
                  Service solely for your own personal use for the subscription term selected (the
                  "Pro Term") in strict accordance with these Terms.
                </p>

                <h5>2.4 Fees</h5>

                <p>
                  The License (Premium) is expressly conditioned on timely payment of the
                  then-applicable license fee ("Subscription Fee"). We may include a separate charge
                  for any applicable sales, use, value-added, or excise taxes, and any other similar
                  taxes, duties or charges of any kind. We will remit taxes collected, if any, to
                  the appropriate taxing authority. If payment is not received within the required
                  time period, we may suspend your access to the Service until payment is received.
                  Except as set forth in the Terms, all Subscription fees are non-refundable. We
                  reserve the right to increase or decrease any Subscription Fee at any time;
                  however, to the extent you have paid your Subscription Fee in advance, the
                  increase or decrease will not become effective for your Plan until the end of your
                  current Term or your next renewal.
                </p>

                <h5>2.5 Auto-Renewal for Subscription Services</h5>

                <p>
                  Unless you opt out of auto-renewal, your Subscription will be automatically
                  extended following any subscription term, for the same period of time as the
                  subscription term, at the then-current rate. To change or terminate your
                  Subscription, go to your Account page or{" "}
                  <NavLink to="/contact" className="text-indigo-500 hover:text-indigo-600">
                    contact us
                  </NavLink>{" "}
                  at admin@justdjango.com. If you terminate your Subscription during the
                  subscription term, you may use your Subscription until the end of the then-current
                  term and your Subscription will not be renewed after the then-current term
                  expires. However, you won't be eligible for a prorated refund of any portion of
                  the Subscription Fee paid for the then-current subscription term.
                </p>

                <h3>3. Usage of the Service</h3>
                <h5>3.1 Restrictions</h5>

                <p>
                  The License granted is subject to the following restrictions: (a) you will not
                  license, sell, rent, lease, transfer, assign, distribute, host, or otherwise
                  commercially exploit the Service, in whole or in part, or any content displayed on
                  the Service; (b) you will not modify, make derivative works of, disassemble,
                  reverse compile or reverse engineer any part of the Service; (c) you will not
                  access the Service in order to build a similar or competitive website, product, or
                  service; and (d) except as expressly stated herein, no part of the Service may be
                  copied, reproduced, distributed, republished, downloaded, displayed, posted or
                  transmitted in any form or by any means. All copyright and other proprietary
                  notices on the Service (or on any content displayed on the Service) must be
                  retained on all copies thereof.
                </p>

                <h5>3.2 Limits</h5>

                <p>
                  We reserve the right to monitor use of the Service and to deactivate or terminate
                  your rights to use the Service (including your Account) if your usage behavior
                  exceeds normal limits, as determined in our sole discretion.
                </p>

                <h5>3.3 Modification</h5>

                <p>
                  JustDjango reserves the right, at any time without notice or liability, to change
                  or eliminate any content or feature or restrict the use of any portion of the
                  Service. Your only right with respect to any dissatisfaction is to cease use of
                  the Service. Unless otherwise indicated, any future release, update, or other
                  addition to functionality of the Service will be subject to these Terms.
                </p>

                <h5>3.4 Availability of Service</h5>

                <p>
                  You recognize that the traffic of data through the Internet may cause delays
                  during your use of or access to the Service, and accordingly, you agree not to
                  hold us liable for delays that are ordinary in the course of Internet use. You
                  further acknowledge and accept that the Service may not be available due to such
                  delays, delays caused by our upgrading, modification, or standard maintenance of
                  the Service, or any other delays outside of our control.
                </p>

                <h5>3.5 No Support or Maintenance</h5>

                <p>
                  You acknowledge and agree that JustDjango will have no obligation to provide you
                  with any support or maintenance in connection with the Service
                </p>

                <h3>4. Payment terms</h3>
                <h5>4.1 Payment</h5>

                <p>
                  Subscription holders agree to pay the monthly, or annual fee specified when you
                  purchase a Subscription. Depending on where you transact with us, the type of
                  payment method used, and where your payment method was issued, your transaction
                  with us may be subject to foreign exchange fees or differences in prices because
                  of exchange rates and other geographic-specific pricing. Any agreement you have
                  with your payment provider will govern your use of your specified payment method.
                  You agree that we may accumulate charges incurred and submit them as one or more
                  aggregate charges during or at the end of each billing cycle. Your failure to
                  terminate and/or continued use of your Subscription reaffirms that we are
                  authorized to charge you for that Subscription. We may submit those charges for
                  payment and you will be responsible for such charges. This does not waive our
                  right to seek payment directly from you.
                </p>

                <h5>4.2 Billing Authorization</h5>

                <p>
                  You may be asked to provide a credit, charge or debit card number from a card
                  issuer that we accept in order to activate your Subscription – your authorizations
                  in this section also apply to our payment processor and any other company who acts
                  as a billing agent for us. You hereby authorize us to charge your specified
                  payment method on a monthly or annual basis, in advance, for recurring
                  Subscriptions, and to charge in advance for any course based or other subscription
                  models, and/or to place a hold on your payment method with respect to any unpaid
                  charges for Subscriptions. You authorize the issuer of your selected payment
                  method to pay any amounts described herein without requiring a signed receipt. You
                  authorize us to continue to attempt to charge and/or place holds with respect to
                  all sums described herein, or any portion thereof, to your payment method until
                  such amounts are paid in full. You agree to provide updated payment information
                  upon request and any time the information you previously provided is no longer
                  valid. You acknowledge and agree that neither JustDjango, nor any JustDjango
                  agent, will have any liability whatsoever for any insufficient funds or other
                  charges incurred by you as a result of attempts to charge, and/or place holds on,
                  your specified payment method as contemplated by these Terms. If you provide a
                  debit card number instead of a credit card number, you authorize all charges
                  described herein to be applied to such debit card unless and until you provide a
                  credit card number.
                </p>

                <h5>4.3 Third-Party Payment Processors</h5>

                <p>
                  You agree to pay us, through our payment processors, all charges at the prices
                  then in effect for any purchase in accordance with the applicable payment terms
                  presented to you at the time of purchase. You agree to make payment using the
                  payment method you provide when you sign up for a Subscription. We reserve the
                  right to correct, or to instruct our payment processor or financing partner to
                  correct, any errors or mistakes, even if payment has already been requested or
                  received.
                </p>

                <h3>5. Termination and Cancellation</h3>
                <h5>5.1 Termination</h5>

                <p>
                  You agree that we may deactivate or terminate your rights to use the Service
                  (including your Account) or terminate your Plan (i) if we believe that you have
                  breached or acted inconsistently with these Terms and (ii) at any time for any
                  reason at our sole discretion if you are a subscription holder under a Free Plan.
                  You agree that any such deactivation or termination for the foregoing reasons may
                  be effected without prior notice to you and that we will not be liable to you or
                  any third party for any deactivation of your Account or termination of your Plan.
                </p>

                <h5>5.2 Cancellation Premium Plan</h5>

                <p>
                  Your Premium Plan may be cancelled at any time via your user account settings page
                  or by emailing admin@justdjango.com. After cancellation, your Premium Plan will
                  remain active to the end of your then-current Premium Term to the extent your
                  Subscription Fee is paid. We will not offer refunds on any fees or charges related
                  to your Premium Plan — this includes any partially used or unused periods for
                  which you have already paid. Your obligation to pay fees continues through the end
                  of your Premium Term.
                </p>

                <h5>5.3 Post-termination or cancellation</h5>

                <p>
                  These Terms will continue in effect until termination or expiration of your
                  Account or Plan. Upon termination or expiration your rights under these Terms,
                  your Account and right to access and use the Service will terminate immediately.
                  Termination or expiration may involve deletion of your User Content associated
                  with your Account. JustDjango will not have any liability whatsoever to you for
                  any termination of your rights under these Terms, including for termination of
                  your Account or deletion of your User Content. Even after your rights under these
                  Terms are terminated, the following Sections will remain in effect: 6.1, 5, 7, 9
                  through 13.
                </p>

                <h3>6. Intellectual property</h3>
                <h5>6.1 Ownership</h5>

                <p>
                  Excluding any User Content that you may provide, you acknowledge that all
                  intellectual property rights, including copyrights, patents, trademarks, and trade
                  secrets, in the Service and its content are owned by JustDjango or JustDjango's
                  suppliers ("Proprietary Materials"). Neither these Terms nor your access to the
                  Service transfers to you or any third party any rights, title or interest in or to
                  such Proprietary Materials, except for the limited license in Section 2.
                  JustDjango and its suppliers reserve all rights not granted in these Terms. The
                  License granted to you by these Terms is a right of use and access of the Service
                  through the Site only, and does not grant to you any right to download or store
                  any Proprietary Materials in any medium, other than (i) that downloadable content
                  that may be provided for certain courses, including exercise files, slides, and
                  sample code, and (ii) files that are automatically cached by your web browser for
                  display purposes.
                </p>

                <h5>6.2 Rights</h5>

                <p>
                  We represent, warrant, and covenant that: (i) we have and will have all rights,
                  titles, licenses, intellectual property, permissions and approvals necessary in
                  connection with our performance under these Terms to grant you the License granted
                  hereunder; and (ii) neither the Service, nor the provision or utilization thereof
                  as contemplated under these Terms, will infringe, violate, trespass or in any
                  manner contravene or breach or constitute the unauthorized use or misappropriation
                  of any intellectual property of any third party.
                </p>

                <h3>7. User Content</h3>
                <h5>7.1 User Content</h5>

                <p>
                  User Content means any information and content that a user submits to, or uses
                  with, the Service (e.g., content in the user's profile or postings). You are
                  solely responsible for your User Content. You assume all risks associated with use
                  of your User Content, including any reliance on its accuracy, completeness or
                  usefulness by others, or any disclosure of your User Content that personally
                  identifies you or any third party. You hereby represent and warrant that your User
                  Content does not and will not violate the Restrictions in Section 7.3. You may not
                  represent or imply to others that your User Content is in any way provided,
                  sponsored or endorsed by JustDjango. JustDjango is not obligated to backup any
                  User Content, and your User Content may be deleted at any time without prior
                  notice. You are solely responsible for creating and maintaining your own backup
                  copies of your User Content if you desire.
                </p>

                <h5>7.2 License</h5>

                <p>
                  You hereby grant (and represent and warrant that you have the right to grant)
                  JustDjango an irrevocable, non-exclusive, royalty-free and fully-paid-up,
                  worldwide license to reproduce, distribute, publicly display and perform, prepare
                  derivative works of, incorporate into other works, and otherwise use and exploit
                  your User Content, and to grant sublicenses of the foregoing rights, in connection
                  with the Service through any medium, whether alone or in combination with other
                  content or materials, in any manner and by any means, method or technology,
                  whether now known or hereafter created. You hereby irrevocably waive (and agree to
                  cause to be waived) any claims and assertions of moral rights or attribution with
                  respect to your User Content.
                </p>
                <h5>7.3 Restrictions</h5>
                <p>
                  (a) You will not use the Service to collect, upload, transmit, display, or
                  distribute any User Content (i) that violates any third-party right, including any
                  copyright, trademark, patent, trade secret, moral right, privacy right, right of
                  publicity, or any other intellectual property or proprietary right; (ii) that is
                  unlawful, harassing, abusive, tortious, threatening, harmful, invasive of
                  another's privacy, vulgar, defamatory, false, intentionally misleading, trade
                  libelous, pornographic, obscene, patently offensive, promotes racism, bigotry,
                  hatred, or physical harm of any kind against any group or individual or is
                  otherwise objectionable; (iii) that is harmful to minors in any way; or (iv) that
                  is in violation of any law, regulation, or obligations or restrictions imposed by
                  any third party.
                </p>
                <p>
                  (b) In addition, you agree not to: (i) upload, transmit, or distribute to or
                  through the Service any computer viruses, worms, or any software intended to
                  damage or alter a computer system or data; (ii) send through the Service
                  unsolicited or unauthorized advertising, promotional materials, junk mail, spam,
                  chain letters, pyramid schemes, or any other form of duplicative or unsolicited
                  messages, whether commercial or otherwise; (iii) use the Service to harvest,
                  collect, gather or assemble information or data regarding other users, including
                  e-mail addresses, without their consent; (iv) interfere with, disrupt, or create
                  an undue burden on servers or networks connected to the Service, or violate the
                  regulations, policies or procedures of such networks; (v) attempt to gain
                  unauthorized access to the Service (or to other computer systems or networks
                  connected to or used together with the Service), whether through password mining
                  or any other means; (vi) harass or interfere with any other user's use and
                  enjoyment of the Service; or (vi) use software or automated agents or scripts to
                  produce multiple accounts on the Service, or to generate automated searches,
                  requests, or queries to (or to strip, scrape, or mine data from) the Service
                  (provided, however, that we conditionally grant to the operators of public search
                  engines revocable permission to use spiders to copy materials from the Service for
                  the sole purpose of and solely to the extent necessary for creating publicly
                  available searchable indices of the materials, but not caches or archives of such
                  materials, subject to the parameters set forth in our robots.txt file).
                </p>
                <h5>7.4 Enforcement</h5>
                <p>
                  We reserve the right (but have no obligation) to review any User Content, and to
                  investigate and/or take appropriate action against you in our sole discretion if
                  you violate any Restrictions or any other provision of these Terms. Such action
                  may include removing or modifying your User Content, terminating your Account in
                  accordance with Section 5, and/or reporting you to law enforcement authorities.
                </p>
                <h5>7.5 Feedback</h5>
                <p>
                  If you provide JustDjango with any feedback or suggestions regarding the Service
                  ("Feedback"), you hereby assign to JustDjango all rights in such Feedback and
                  agree that JustDjango will have the right to use and fully exploit such Feedback
                  and related information in any manner it deems appropriate. JustDjango will treat
                  any Feedback you provide to JustDjango as non-confidential and non-proprietary.
                  You agree that you will not submit to JustDjango any information or ideas that you
                  consider to be confidential or proprietary.
                </p>
                <h3>8. Third-Party Links & Ads; Other Users</h3>
                <h5>8.1. Other Users</h5>
                <p>
                  Each Service user is solely responsible for its own User Content. We make no
                  guarantees regarding the accuracy, currency, suitability, or quality of any User
                  Content. Your interactions with other Service users are solely between you and
                  such users. You agree that JustDjango will not be responsible for any loss or
                  damage incurred as the result of any such interactions. If there is a dispute
                  between you and any Service user, we are under no obligation to become involved.
                </p>
                <h3>9. Indemnification</h3>
                <p>
                  You agree to indemnify and hold JustDjango (and its officers, employees, and
                  agents) harmless, including costs and attorneys' fees, from any claim or demand
                  made by any third party due to or arising out of (a) your use of the Service, (b)
                  your violation of these Terms, (c) your violation of applicable laws or
                  regulations or (d) your User Content. JustDjango reserves the right, at your
                  expense, to assume the exclusive defense and control of any matter for which you
                  are required to indemnify us, and you agree to cooperate with our defense of these
                  claims. You agree not to settle any indemnifiable matter without our prior written
                  consent. JustDjango will use reasonable efforts to notify you of any such claim,
                  action or proceeding upon becoming aware of it.
                </p>
                <h3>10. Disclaimers</h3>
                <p>
                  THE SERVICE IS PROVIDED ON AN "AS-IS" AND "AS AVAILABLE" BASIS, AND JustDjango
                  (AND OUR SUPPLIERS) EXPRESSLY DISCLAIM ANY WARRANTIES AND CONDITIONS OF ANY KIND,
                  WHETHER EXPRESS, IMPLIED, OR STATUTORY, INCLUDING ALL WARRANTIES OR CONDITIONS OF
                  MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, QUIET ENJOYMENT,
                  ACCURACY, OR NON-INFRINGEMENT. WE (AND OUR SUPPLIERS) MAKE NO WARRANTY THAT THE
                  SERVICE WILL MEET YOUR REQUIREMENTS, WILL BE AVAILABLE ON AN UNINTERRUPTED,
                  TIMELY, SECURE, OR ERROR-FREE BASIS, OR WILL BE ACCURATE, RELIABLE, FREE OF
                  VIRUSES OR OTHER HARMFUL CODE, COMPLETE, LEGAL, OR SAFE. IF APPLICABLE LAW
                  REQUIRES ANY WARRANTIES WITH RESPECT TO THE SERVICE, ALL SUCH WARRANTIES ARE
                  LIMITED IN DURATION TO NINETY (90) DAYS FROM THE DATE OF FIRST USE.
                </p>
                <p>
                  SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES, SO THE ABOVE
                  EXCLUSION MAY NOT APPLY TO YOU. SOME JURISDICTIONS DO NOT ALLOW LIMITATIONS ON HOW
                  LONG AN IMPLIED WARRANTY LASTS, SO THE ABOVE LIMITATION MAY NOT APPLY TO YOU.
                </p>
                <h3>11. Limitation on Liability</h3>
                <p>
                  TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT WILL JustDjango (OR OUR
                  SUPPLIERS) BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY LOST PROFITS, LOST DATA,
                  COSTS OF PROCUREMENT OF SUBSTITUTE PRODUCTS, OR ANY INDIRECT, CONSEQUENTIAL,
                  EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES ARISING FROM OR RELATING TO
                  THESE TERMS OR YOUR USE OF, OR INABILITY TO USE, THE SERVICE, EVEN IF JustDjango
                  HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. ACCESS TO, AND USE OF, THE
                  SERVICE IS AT YOUR OWN DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR
                  ANY DAMAGE TO YOUR DEVICE OR COMPUTER SYSTEM, OR LOSS OF DATA RESULTING THEREFROM.
                </p>
                <p>
                  TO THE MAXIMUM EXTENT PERMITTED BY LAW, NOTWITHSTANDING ANYTHING TO THE CONTRARY
                  CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY DAMAGES ARISING FROM OR RELATED TO
                  THIS AGREEMENT (FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE
                  ACTION), WILL AT ALL TIMES BE LIMITED TO A MAXIMUM OF THE AMOUNTS PAID TO US BY
                  YOU IN THE TWELVE (12) MONTHS PRIOR TO THE EVENT GIVING RISE TO THE CLAIM. THE
                  EXISTENCE OF MORE THAN ONE CLAIM WILL NOT ENLARGE THIS LIMIT. YOU AGREE THAT OUR
                  SUPPLIERS WILL HAVE NO LIABILITY OF ANY KIND ARISING FROM OR RELATING TO THIS
                  AGREEMENT
                </p>
                <p>
                  SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY FOR
                  INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION OR EXCLUSION MAY NOT
                  APPLY TO YOU.
                </p>
                <h3>12. Copyright Policy</h3>
                <p>
                  JustDjango respects the intellectual property of others and asks that users of our
                  Service do the same. In connection with the Service, we have adopted and
                  implemented a policy respecting copyright law that provides for the removal of any
                  infringing materials and for the termination, in appropriate circumstances, of
                  users of our Service who are repeat infringers of intellectual property rights,
                  including copyrights. If you believe that one of our users is, through the use of
                  our Service, unlawfully infringing the copyright(s) in a work, and wish to have
                  the allegedly infringing material removed, the following information in the form
                  of a written notification (pursuant to 17 U.S.C. § 512(c)) must be provided to us
                  by emailing admin@justdjango.com:
                </p>
                <ol>
                  <li>your physical or electronic signature;</li>
                  <li>
                    identification of the copyrighted work(s) that you claim to have been infringed;
                  </li>
                  <li>
                    identification of the material on our services that you claim is infringing and
                    that you request us to remove;
                  </li>
                  <li>sufficient information to permit us to locate such material;</li>
                  <li>your address, telephone number, and e-mail address;</li>
                  <li>
                    a statement that you have a good faith belief that use of the objectionable
                    material is not authorized by the copyright owner, its agent, or under the law;
                    and
                  </li>
                  <li>
                    a statement that the information in the notification is accurate, and under
                    penalty of perjury, that you are either the owner of the copyright that has
                    allegedly been infringed or that you are authorized to act on behalf of the
                    copyright owner.
                  </li>
                </ol>
                <p>
                  Please note that, pursuant to 17 U.S.C. § 512(f), any misrepresentation of
                  material fact in a written notification automatically subjects the complaining
                  party to liability for any damages, costs and attorney's fees incurred by us in
                  connection with the written notification and allegation of copyright infringement.
                </p>
                <h3>13. General</h3>
                <h5>13.1 Changes</h5>
                <p>
                  These Terms are subject to occasional revision, and if we make any substantial
                  changes, we notify you by sending you an e-mail to the last e-mail address you
                  provided to us (if any), and/or by prominently posting notice of the changes on
                  this page and elsewhere in the Service. In the event that the last e-mail address
                  that you have provided us is not valid, or for any reason is not capable of
                  delivering to you the notice described above, our dispatch of the e-mail
                  containing such notice will nonetheless constitute effective notice of the changes
                  described in the notice. Any changes to these Terms will be effective upon the
                  earlier of thirty (30) calendar days following our dispatch of an e-mail notice to
                  you (if applicable) or thirty (30) calendar days following our posting of notice
                  of the changes on this page and in the Service. These changes will be effective
                  immediately for new users of the Service. Continued use of the Service following
                  notice of such changes will indicate your acknowledgement of such changes and
                  agreement to be bound by the terms and conditions of such changes.
                </p>
                <h5>13.2 Governing Law and Jurisdiction</h5>
                <p>
                  These Terms are governed by and will be construed in accordance with the laws of
                  South Africa, without reference to its choice or conflicts of law principles.
                </p>
                <h5>13.3 Dispute Resolution</h5>
                <p>
                  If any dispute should arise with regard to these Terms (i) the parties agree to
                  first work in good faith to resolve such dispute, and neither party may commence
                  any action with regard to such dispute until thirty (30) days have passed from the
                  time such party has provided notice to the other party of the nature of such
                  dispute, provided that nothing herein will prevent us from seeking injunctive
                  relief in the event of your actual or threatened breach of any terms of these
                  Terms; (ii) the party prevailing in such dispute, whether by out-of-court
                  settlement or final judicial determination, will be entitled to recover from the
                  non-prevailing party all costs and expenses of such dispute incurred by such
                  prevailing party, including without limitation reasonable attorneys' fees.
                </p>
                <h5>13.4 Export</h5>
                <p>
                  The Service may be subject to Z.A. export control laws and may be subject to
                  export or import regulations in other countries. You agree not to export,
                  reexport, or transfer, directly or indirectly, any Z.A. technical data acquired
                  from JustDjango, or any products utilizing such data, in violation of the South
                  African export laws or regulations.
                </p>
                <h5>13.5 Notice</h5>
                <p>
                  Any notice which may be required to be given under these Terms, will be given: (i)
                  to you via e-mail to the e-mail address you maintain in your account settings or
                  by notifying you electronically by displaying the notice in the Service; (ii) to
                  us in any commercially reasonable manner, including certified mail, return receipt
                  requested, e-mail, or any other customary means of communication at the applicable
                  mailing address in Section 13.10, as may be updated from time to time. Any notice
                  given otherwise than in accordance with this Section will be deemed ineffective.
                </p>
                <h5>13.6 Contractor</h5>
                <p>
                  Your relationship to JustDjango is that of an independent contractor, and neither
                  party is an agent or partner of the other.
                </p>
                <h5>13.7 Assignment</h5>
                <p>
                  These Terms, and your rights and obligations herein, may not be assigned,
                  subcontracted, delegated, or otherwise transferred by you without JustDjango's
                  prior written consent, and any attempted assignment, subcontract, delegation, or
                  transfer in violation of the foregoing will be null and void. JustDjango may
                  freely assign these Terms. The terms and conditions set forth in these Terms will
                  be binding upon assignees.
                </p>
                <h5>13.8 Copyright/Trademark Information</h5>
                <p>
                  Copyright © 2019 JustDjango All rights reserved. All trademarks, logos and service
                  marks ("Marks") displayed on the Service are our property or the property of other
                  third parties. You are not permitted to use these Marks without our prior written
                  consent or the consent of such third party which may own the Marks.
                </p>
                <h5>13.9 Contact Information</h5>
                <p>
                  JustDjango Address: Pretoria, South Africa
                  <br />
                  Email: admin@justdjango.com
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
