import React, { useContext, useState } from "react";
import GoogleLogin, { GoogleLoginResponse, GoogleLoginResponseOffline } from "react-google-login";
import { LargeLoader, Message, GoogleIcon } from "src/components";
import api from "src/api";
import errorHandler from "src/utils";
import { googleClientID } from "src/config";
import { useAuthSWR } from "src/helpers";
import { AuthContext } from "src/contexts";

export function SocialAccountsForm(): JSX.Element {
  const [err, setError] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { data, error, revalidate } = useAuthSWR(api.auth.social.list);
  const { authAxios } = useContext(AuthContext);

  const handleLinkGoogle = (response: GoogleLoginResponse | GoogleLoginResponseOffline) => {
    setLoading(true);
    if ("tokenObj" in response) {
      const { tokenObj } = response;
      if (tokenObj) {
        const { access_token, id_token } = tokenObj;
        authAxios
          .post(api.auth.social.google.connect, { access_token, id_token })
          .then(() => revalidate())
          .catch((e) => setError(errorHandler(e)))
          .finally(() => setLoading(false));
      } else {
        setLoading(false);
        setError("There was an error connecting to your account");
      }
    } else {
      setLoading(false);
      setError("There was an error connecting to your account");
    }
  };

  const handleDisconnectSocialAccount = (id: number) => {
    // TODO: only allow disconnect if they have an email on this account
    setLoading(true);
    authAxios
      .post(api.auth.social.disconnect(id))
      .then(() => revalidate())
      .catch((e) => setError(errorHandler(e)))
      .finally(() => setLoading(false));
  };

  return (
    <div className="mt-4 pt-8">
      <div>
        <h3 className="text-lg leading-6 font-medium text-gray-900">Manage your Social Accounts</h3>
      </div>
      <div className="mt-6">
        {error && <Message type="ERROR" body={errorHandler(error)} noDismiss />}
        {err && <Message type="ERROR" body={err} onDismiss={() => setError(null)} />}
        {!data || (loading && <LargeLoader />)}
        {data?.length < 1 ? (
          <GoogleLogin
            render={(renderProps) => (
              <button
                type="button"
                disabled={loading}
                onClick={renderProps.onClick}
                className="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-indigo active:bg-red-700 transition duration-150 ease-in-out"
              >
                <GoogleIcon />
                Connect to Google
              </button>
            )}
            clientId={googleClientID}
            buttonText="Connect to Google"
            onSuccess={handleLinkGoogle}
            onFailure={handleLinkGoogle}
            cookiePolicy="single_host_origin"
          />
        ) : (
          <div>
            {data?.map((account: { id: number; provider: string }) => {
              return (
                <button
                  type="button"
                  key={account.id}
                  disabled={loading}
                  onClick={() => handleDisconnectSocialAccount(account.id)}
                  className="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-indigo active:bg-red-700 transition duration-150 ease-in-out"
                >
                  <GoogleIcon />
                  {`Disconnect from ${
                    account.provider.charAt(0).toUpperCase() + account.provider.slice(1)
                  }`}
                </button>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}
