import React, { ReactNode, useContext } from "react";
import { Toaster } from "react-hot-toast";
import { useLocation } from "react-router-dom";
import { Footer } from "src/components";
import { environment, PROFITWELL_AUTH_TOKEN } from "../config";
import { AuthContext } from "../contexts";
import Profitwell from "./Profitwell";
import Navbar from "./Navbar";

export function Layout({ children }: { children: ReactNode }): JSX.Element {
  const useAnalytics = environment === "production";
  const { user } = useContext(AuthContext);
  const { pathname } = useLocation();
  const showLayout =
    !pathname.includes("courses") && !pathname.includes("enroll") && !pathname.includes("payment");
  return (
    <>
      {user ? (
        <Profitwell
          authToken={PROFITWELL_AUTH_TOKEN}
          email={user.user.email}
          shouldNotLoad={!useAnalytics}
        />
      ) : (
        <Profitwell authToken={PROFITWELL_AUTH_TOKEN} shouldNotLoad={!useAnalytics} />
      )}
      {showLayout && <Navbar />}
      <Toaster position="top-center" reverseOrder={true} />
      {children}
      {showLayout && <Footer />}
    </>
  );
}
