import React, { useContext } from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { AuthContext } from "../contexts";

const CheckUser: React.FC<{ children: any }> = ({ children }) => {
  const { user } = useContext(AuthContext);
  return user ? children : <Redirect to={{ pathname: "/login" }} />;
};

export const PrivateRoute: React.FC<RouteProps> = ({ component, ...args }) => (
  <CheckUser>
    <Route {...args} component={component} render={undefined} />
  </CheckUser>
);
