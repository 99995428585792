import React from "react";

const SecureInfo: React.FC = () => (
  <div>
    <h4 className="font-bold text-gray-700">Am I secure?</h4>
    <p className="text-xs text-gray-500">
      Yes, you are secure. This website has the latest TLS encryption. You can confirm this by
      clicking on the lock icon next to the URL.
    </p>
  </div>
);

const createOptions = (): Record<string, unknown> => {
  return {
    style: {
      base: {
        fontSize: "18px",
        color: "#424770",
        fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
        fontSmoothing: "antialiased",
        letterSpacing: "0.025em",
        "::placeholder": {
          color: "#aab7c4"
        }
      },
      invalid: {
        color: "#c23d4b"
      }
    }
  };
};

export { SecureInfo, createOptions };
