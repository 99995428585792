import React, { useContext, useState } from "react";
import { NavLink, Redirect } from "react-router-dom";
import { Formik, Form } from "formik";
import GoogleLogin, { GoogleLoginResponse, GoogleLoginResponseOffline } from "react-google-login";
import * as Yup from "yup";
import { Button, RequiredField, Message, GoogleIcon } from "src/components";
import errorHandler from "src/utils";
import { googleClientID } from "src/config";
import { AuthContext } from "src/contexts";

export default function Signup(): JSX.Element {
  const [error, setError] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const { register, googleLogin } = useContext(AuthContext);

  const { user } = useContext(AuthContext);
  if (user) {
    return <Redirect to={{ pathname: "/dashboard" }} />;
  }

  const handleGoogleLogin = (response: GoogleLoginResponse | GoogleLoginResponseOffline) => {
    setLoading(true);
    if ("tokenObj" in response) {
      const { tokenObj } = response;
      if (tokenObj) {
        const { access_token, id_token } = tokenObj;
        googleLogin(access_token, id_token)
          .then(() => {
            // TODO login welcome message
          })
          .catch((err: any) => setError(errorHandler(err)))
          .finally(() => setLoading(false));
      } else {
        setLoading(false);
        setError("There was an error connecting to your account");
      }
    } else {
      setLoading(false);
      setError("There was an error connecting to your account");
    }
  };

  return (
    <div className="bg-gray-100 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">
          Create an account
        </h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          {error && (
            <div className="mb-6">
              <Message type="ERROR" body={error} onDismiss={() => setError(null)} />
            </div>
          )}
          <div>
            <span className="w-full inline-flex rounded-md shadow-sm">
              <GoogleLogin
                render={(renderProps) => (
                  <button
                    disabled={renderProps.disabled}
                    onClick={renderProps.onClick}
                    type="button"
                    className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition duration-150 ease-in-out"
                  >
                    <GoogleIcon />
                    Signup with Google
                  </button>
                )}
                clientId={googleClientID}
                onSuccess={handleGoogleLogin}
                onFailure={handleGoogleLogin}
                cookiePolicy="single_host_origin"
              />
            </span>
          </div>

          <div className="mt-6">
            <div className="mt-6 grid grid-cols-1 gap-3">
              <Formik
                initialValues={{ email: "", password: "", confirmPassword: "" }}
                validationSchema={Yup.object({
                  email: Yup.string().email("Invalid email address").required("Required"),
                  password: Yup.string()
                    .min(6, "Must be 6 characters or more")
                    .required("Required"),
                  confirmPassword: Yup.string()
                    .min(6, "Must be 6 characters or more")
                    .required("Required")
                    .oneOf([Yup.ref("password")], "Passwords must match")
                })}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                  setError(null);
                  setSubmitting(true);
                  register(values.email, values.password, values.confirmPassword)
                    .then(() => {
                      setSuccess(true);
                      resetForm();
                    })
                    .catch((err: any) => {
                      setError(errorHandler(err));
                    })
                    .finally(() => setSubmitting(false));
                }}
              >
                {(formik) => (
                  <Form>
                    {success && (
                      <div className="mt-3 mb-3">
                        <Message
                          type="SUCCESS"
                          body="Thank you for signing up. We have sent you a verification email"
                          onDismiss={() => setSuccess(false)}
                        />
                      </div>
                    )}
                    <RequiredField
                      label="Email address"
                      formik={formik}
                      fieldName="email"
                      fieldType="email"
                    />
                    <div className="mt-6">
                      <RequiredField
                        label="Password"
                        formik={formik}
                        fieldName="password"
                        fieldType="password"
                      />
                    </div>
                    <div className="mt-6">
                      <RequiredField
                        label="Confirm Password"
                        formik={formik}
                        fieldName="confirmPassword"
                        fieldType="password"
                      />
                    </div>

                    <div className="mt-6 flex items-center justify-between">
                      <div className="text-sm leading-5">
                        <NavLink
                          to="/login"
                          className="font-medium text-red-600 hover:text-red-500 focus:outline-none focus:underline transition ease-in-out duration-150"
                        >
                          Already have an account?
                        </NavLink>
                      </div>
                    </div>

                    <div className="mt-6">
                      <span className="block w-full rounded-md shadow-sm">
                        <Button
                          disabled={formik.isSubmitting || loading}
                          type="submit"
                          loading={formik.isSubmitting || loading}
                          className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red active:bg-red-700 transition duration-150 ease-in-out"
                          label={formik.isSubmitting ? "Creating your account" : "Sign up"}
                        />
                      </span>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
        <div className="px-4 py-6 sm:px-10">
          <p className="text-xs leading-5 text-gray-500">
            By signing up, you agree to our{" "}
            <NavLink to="/terms-and-conditions" className="text-blue-500 hover:text-blue-700">
              Terms
            </NavLink>{" "}
            and{" "}
            <NavLink to="/privacy-policy" className="text-blue-500 hover:text-blue-700">
              Privacy Policy
            </NavLink>{" "}
          </p>
        </div>
      </div>
    </div>
  );
}
