import React from "react";
import { NavLink } from "react-router-dom";

const ctas = {
  default: {
    heading: "Ready to become a professional Django developer?",
    subtitle: "Enroll and start learning today.",
    oneliner: "Get access to videos, summaries, projects and more"
  },
  documents: {
    heading: "Ready to become a professional Django developer?",
    subtitle: "Upgrade to Django Pro and start today.",
    oneliner: "Download all the documents, and much more."
  },
  assignments: {
    heading: "Ready to become a professional Django developer?",
    subtitle: "Upgrade to Django Pro and start today.",
    oneliner: "Complete all the assignments, and much more."
  },
  videos: {
    heading: "Ready to become a professional Django developer?",
    subtitle: "Upgrade to Django Pro and start today.",
    oneliner: "Watch all the videos, and much more."
  },
  quizzes: {
    heading: "Ready to become a professional Django developer?",
    subtitle: "Upgrade to Django Pro and start today.",
    oneliner: "Access all of the quizzes, and much more."
  },
  slack: {
    heading: "Ready to become a professional Django developer?",
    subtitle: "Upgrade to Django Pro and start today.",
    oneliner: "Join the private slack community, and much more."
  }
};

interface CTAPlaceholderProps {
  heading: string;
  subtitle: string;
  oneliner: string;
}

const CTAPlaceholder: React.FC<CTAPlaceholderProps> = ({ heading, subtitle, oneliner }) => (
  <div className="mx-auto text-center py-12 px-4 sm:px-6 md:py-16 lg:px-8 lg:py-20 bg-white">
    <div>
      <h2 className="text-3xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
        {heading}
        <br />
        <span className="text-red-600">{subtitle}</span>
      </h2>
      <p className="text-gray-500">{oneliner}</p>
      <div className="mt-8 flex justify-center">
        <div className="inline-flex rounded-md shadow">
          <NavLink
            to="/pricing"
            className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
          >
            Get started
          </NavLink>
        </div>
      </div>
    </div>
  </div>
);

interface CTAProps {
  document?: boolean;
  assignment?: boolean;
  video?: boolean;
  quiz?: boolean;
  slack?: boolean;
}

export function CTA({ document, assignment, video, quiz, slack }: CTAProps): JSX.Element {
  let cta = ctas.default;
  if (document) {
    cta = ctas.documents;
  }
  if (assignment) {
    cta = ctas.assignments;
  }
  if (video) {
    cta = ctas.videos;
  }
  if (quiz) {
    cta = ctas.quizzes;
  }
  if (slack) {
    cta = ctas.slack;
  }
  return <CTAPlaceholder heading={cta.heading} subtitle={cta.subtitle} oneliner={cta.oneliner} />;
}

CTA.defaultProps = {
  document: false,
  assignment: false,
  video: true,
  quiz: false,
  slack: false
};

const CTAs = [
  { title: "Access documents", subTitle: "Download summaries and other documents." },
  {
    title: "Build projects",
    subTitle: "Build real-world applications from scratch."
  },
  { title: "Private Slack Workspace", subTitle: "Be part of our community." }
];

export function UpgradeCTA(): JSX.Element {
  return (
    <>
      <div className="flex flex-wrap justify-center border-t-2 border-gray-200">
        <div className="px-4 py-5">
          <div className="flex">
            <div className="inline-flex rounded-md shadow">
              <NavLink
                to="/pricing"
                className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-yellow-300 hover:bg-yellow-400 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
              >
                Upgrade to Django Pro
              </NavLink>
            </div>
          </div>
        </div>
      </div>
      {CTAs.map((cta) => {
        return (
          <div key={cta.title} className="flex flex-wrap justify-center text-center">
            <div className="px-4 py-3">
              <div>
                <div>
                  <h5 className="text-lg leading-6 font-medium text-gray-900">{cta.title}</h5>
                  <p className="mt-2 text-base leading-6 text-gray-500">{cta.subTitle}</p>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
}
