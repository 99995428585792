import React from "react";
import { VisaSquareIcon } from "./icons";

interface PaymentMethodProps {
  card: any;
  cards: any;
  handleSetAsDefault?: (arg: string) => void;
  handleDeleteCard?: (arg: string) => void;
  withActions?: boolean;
}

export function PaymentMethodCard({
  card,
  cards,
  handleSetAsDefault,
  handleDeleteCard,
  withActions = true
}: PaymentMethodProps): JSX.Element {
  return (
    <div className="mt-3" key={card.id}>
      <div className="rounded-md bg-gray-100 px-6 py-5 sm:flex sm:items-start sm:justify-between">
        <div className="sm:flex sm:items-start">
          {card.brand === "visa" && <VisaSquareIcon />}
          <div className="mt-3 sm:mt-0 sm:ml-4">
            <div className="text-sm leading-5 font-medium text-gray-900">
              Ending with {card.last4}
              {card.default && (
                <span className="ml-2 inline-flex items-center px-2.5 py-1 rounded-md text-sm font-medium leading-5 bg-green-100 text-green-800">
                  Default
                </span>
              )}
            </div>
            <div className="mt-1 text-sm leading-5 text-gray-600 sm:flex sm:items-center">
              <div>
                Expires {card.exp_month}/{card.exp_year}
              </div>
            </div>
          </div>
        </div>
        {withActions && (
          <div className="mt-4 sm:mt-0 sm:ml-6 sm:flex-shrink-0">
            <span className="inline-flex rounded-md shadow-sm">
              {!card.default && (
                <button
                  onClick={() => (handleSetAsDefault ? handleSetAsDefault(card.id) : null)}
                  type="button"
                  className="mr-3 inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-100 transition ease-in-out duration-150"
                >
                  Set as default
                </button>
              )}
              {cards.length > 1 && (
                <button
                  onClick={() => (handleDeleteCard ? handleDeleteCard(card.id) : null)}
                  type="button"
                  className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-100 transition ease-in-out duration-150"
                >
                  Remove
                </button>
              )}
            </span>
          </div>
        )}
      </div>
    </div>
  );
}

PaymentMethodCard.defaultProps = {
  handleSetAsDefault: () => null,
  handleDeleteCard: () => null,
  withActions: false
};
