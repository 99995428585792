import React, { useContext, useState } from "react";
import { NavLink, Redirect, useParams } from "react-router-dom";
import { Formik, Form } from "formik";
import axios from "axios";
import { Button, Message } from "src/components";
import errorHandler from "src/utils";
import api from "src/api";
import { AuthContext } from "src/contexts";

export default function ConfirmEmail(): JSX.Element {
  const [error, setError] = useState<any>(null);
  const [success, setSuccess] = useState<boolean>(false);
  const { verifyKey }: { verifyKey: string } = useParams();

  const { user } = useContext(AuthContext);
  if (user) {
    return <Redirect to={{ pathname: "/dashboard" }} />;
  }

  return (
    <div className="bg-gray-100 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">
          Confirm your email
        </h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          {error && <Message type="ERROR" body={error} onDismiss={() => setError(null)} />}
          <Formik
            initialValues={{}}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true);
              axios
                .post(api.auth.confirmEmail, { key: verifyKey })
                .then(() => {
                  setSubmitting(false);
                  setSuccess(true);
                })
                .catch((err) => setError(errorHandler(err)))
                .finally(() => {
                  setSubmitting(false);
                });
            }}
          >
            {(formik) => (
              <Form>
                {success && (
                  <Message
                    type="SUCCESS"
                    body="Your email has been verified. You can now continue to login"
                    onDismiss={() => setSuccess(false)}
                  />
                )}
                <div className="mt-6">
                  <span className="block w-full rounded-md shadow-sm">
                    {success ? (
                      <NavLink to="/login">
                        <button
                          type="submit"
                          className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red active:bg-red-700 transition duration-150 ease-in-out"
                        >
                          Continue to login
                        </button>
                      </NavLink>
                    ) : (
                      <Button
                        disabled={formik.isSubmitting}
                        loading={formik.isSubmitting}
                        label={formik.isSubmitting ? "Submitting" : "Confirm my email"}
                        type="submit"
                        className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red active:bg-red-700 transition duration-150 ease-in-out"
                      />
                    )}
                  </span>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
