import React, { Fragment, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { FaFileAlt, FaCode, FaFilePdf, FaGithub } from "react-icons/fa";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import {
  CTA,
  Divider,
  LargeLoader,
  CircleTickIcon,
  ThreeLinesIcon,
  BackArrowIcon,
  VideoIcon,
  CircleIcon
} from "src/components";
import { useAuthFetch, useAuthSWR } from "src/helpers";
import { Lecture as LectureProps, Section, LectureContent, Course } from "src/models";
import api from "src/api";
import { Dialog, Transition } from "@headlessui/react";
import { VideoPlayer } from "./video";
import Document from "./document";
import { InformationCircleIcon, XIcon } from "@heroicons/react/outline";

const CONTENT_TYPES = {
  DOCUMENT: "DOCUMENT",
  VIDEO: "VIDEO",
  QUIZ: "QUIZ",
  PROJECT: "PROJECT"
};

function KeyboardShortcutsModal({
  open,
  setOpen
}: {
  open: boolean;
  setOpen: (arg: boolean) => void;
}) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        open={open}
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-md sm:w-full sm:p-6">
              <div className="sm:flex sm:items-start">
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                    <button
                      type="button"
                      className="bg-white rounded-md text-gray-400 hover:text-gray-500"
                      onClick={() => setOpen(false)}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:mt-0 sm:text-left">
                      <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                        Keyboard Shortcuts
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Use the following keyboard shortcuts to interact with the video player.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-2">
                    <ul className="mt-5 space-y-5">
                      <li>
                        <span className="bg-gray-100 rounded px-3 py-3 text-lg">➡️</span>
                        <span className="ml-3">Forward 5 seconds</span>
                      </li>
                      <li>
                        <span className="bg-gray-100 rounded px-3 py-3 text-lg">⬅️</span>
                        <span className="ml-3">Back 5 seconds</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

function BackToDashboardButton({ course }: { course: Course }) {
  return (
    <div className="ml-2 flex-shrink-0 flex">
      <NavLink
        to={course && course.archived ? `/archives/${course.roadmap}` : "/dashboard"}
        className="px-3 py-2 rounded-md text-lg flex-shrink-0 group block bg-gray-700 focus:outline-none hover:bg-gray-600 transition duration-300 ease-in-out"
      >
        <div className="flex items-center">
          <div>
            <BackArrowIcon className="w-4 h-4 text-gray-300 group-hover:text-white" />
          </div>
          <div className="ml-3">
            <p className="text-sm leading-5 font-medium text-gray-300 group-hover:text-white group-focus:underline transition ease-in-out duration-300">
              Back to Dashboard
            </p>
          </div>
        </div>
      </NavLink>
    </div>
  );
}

function CourseDetailContainer({ course, lecture }: { course: Course; lecture: LectureContent }) {
  function renderOrder(order: number) {
    if (order < 9) {
      return `0${order}`;
    }
    return order;
  }

  return (
    <div className="bg-indigo-500 px-6 py-5 rounded-md">
      {!course && <LargeLoader />}
      {course && (
        <>
          {lecture && (
            <div>
              <h3 className="text-2xl leading-8 font-medium text-white">
                <span>{renderOrder(lecture.order)}</span>
                <br />
                <span className="flex items-center">
                  {lecture.title}{" "}
                  <span className="ml-2 inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium leading-5 bg-gray-100 text-gray-800">
                    {lecture.experience} XP
                  </span>
                  {lecture.free && (
                    <span className="ml-2 inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium leading-5 bg-teal-100 text-teal-800">
                      Free
                    </span>
                  )}
                </span>
              </h3>
            </div>
          )}
          {lecture && lecture.links && lecture.links.length > 0 && (
            <>
              <Divider />
              <div>
                <h3 className="text-2xl leading-8 font-medium text-white">
                  <span className="inline-flex items-center">
                    <svg
                      className="h-6 w-6 mr-2"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M12.586 4.586a2 2 0 112.828 2.828l-3 3a2 2 0 01-2.828 0 1 1 0 00-1.414 1.414 4 4 0 005.656 0l3-3a4 4 0 00-5.656-5.656l-1.5 1.5a1 1 0 101.414 1.414l1.5-1.5zm-5 5a2 2 0 012.828 0 1 1 0 101.414-1.414 4 4 0 00-5.656 0l-3 3a4 4 0 105.656 5.656l1.5-1.5a1 1 0 10-1.414-1.414l-1.5 1.5a2 2 0 11-2.828-2.828l3-3z"
                        clipRule="evenodd"
                      />
                    </svg>
                    Lesson Links
                  </span>
                </h3>
                <ul>
                  {lecture.links.map((link) => {
                    return (
                      <li className="ml-10 list-disc text-white" key={link.url}>
                        <a
                          href={link.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="hover:underline duration-300 ease-in-out transition"
                        >
                          {link.title}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </>
          )}
          <Divider />
          {course.files === null && course.github_link === null ? (
            <p className="text-gray-200">There are no files for this course</p>
          ) : null}
          <h3 className="text-lg text-white font-semibold">{course.title}</h3>
          <p className="text-gray-200">{course.description}</p>
          <div className="mt-3">
            {course.files && (
              <a
                href={course.files}
                download
                className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 transition duration-300 ease-in-out"
              >
                <FaFilePdf className="-ml-1 mr-2 h-5 w-5" />
                Download course files
              </a>
            )}
            {course.github_link && (
              <a
                href={course.github_link}
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 transition duration-300 ease-in-out"
              >
                <FaGithub className="-ml-1 mr-2 h-5 w-5" />
                Finished Code
              </a>
            )}
          </div>
        </>
      )}
    </div>
  );
}

function LectureListSkeleton() {
  return (
    <div className="w-full mx-auto">
      <div className="mt-5 animate-pulse flex space-x-4">
        <div className="flex-1 space-y-4 py-1">
          <div className="h-4 bg-gray-300 rounded-sm w-3/4" />
          <div className="space-y-2">
            <div className="h-6 bg-gray-300 rounded-sm" />
          </div>
        </div>
      </div>
      <div className="mt-5 animate-pulse flex space-x-4">
        <div className="flex-1 space-y-4 py-1">
          <div className="h-4 bg-gray-300 rounded-sm w-1/2" />
          <div className="space-y-2">
            <div className="h-6 bg-gray-300 rounded-sm" />
            <div className="h-6 bg-gray-300 rounded-sm" />
            <div className="h-6 bg-gray-300 rounded-sm" />
            <div className="h-6 bg-gray-300 rounded-sm" />
          </div>
        </div>
      </div>
      <div className="mt-5 animate-pulse flex space-x-4">
        <div className="flex-1 space-y-4 py-1">
          <div className="h-4 bg-gray-300 rounded-sm w-1/4" />
          <div className="space-y-2">
            <div className="h-6 bg-gray-300 rounded-sm" />
            <div className="h-6 bg-gray-300 rounded-sm" />
          </div>
        </div>
      </div>
      <div className="mt-5 animate-pulse flex space-x-4">
        <div className="flex-1 space-y-4 py-1">
          <div className="h-4 bg-gray-300 rounded-sm w-1/2" />
          <div className="space-y-2">
            <div className="h-6 bg-gray-300 rounded-sm" />
            <div className="h-6 bg-gray-300 rounded-sm" />
            <div className="h-6 bg-gray-300 rounded-sm" />
            <div className="h-6 bg-gray-300 rounded-sm" />
          </div>
        </div>
      </div>
      <div className="mt-5 animate-pulse flex space-x-4">
        <div className="flex-1 space-y-4 py-1">
          <div className="h-4 bg-gray-300 rounded-sm w-3/4" />
          <div className="space-y-2">
            <div className="h-6 bg-gray-300 rounded-sm" />
          </div>
        </div>
      </div>
      <div className="mt-5 animate-pulse flex space-x-4">
        <div className="flex-1 space-y-4 py-1">
          <div className="h-4 bg-gray-300 rounded-sm w-1/4" />
          <div className="space-y-2">
            <div className="h-6 bg-gray-300 rounded-sm" />
            <div className="h-6 bg-gray-300 rounded-sm" />
          </div>
        </div>
      </div>
    </div>
  );
}

function LectureItem({
  lecture,
  courseSlug,
  lectureSlug
}: {
  lecture: LectureContent;
  courseSlug: string;
  lectureSlug: string;
}) {
  return (
    <NavLink
      to={`/courses/${courseSlug}/${lecture.slug}`}
      className={
        lecture.slug === lectureSlug
          ? "mt-1 group flex items-center justify-between px-5 py-3 text-base leading-6 font-medium text-white bg-gray-700 rounded-lg"
          : "mt-1 group flex items-center justify-between px-5 py-3 text-base leading-6 font-medium text-gray-400 rounded-lg hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition ease-in-out duration-150"
      }
    >
      <div className="flex">
        {lecture.slug === lectureSlug ? (
          <VideoIcon className="mr-4 h-6 w-6 text-indigo-400" />
        ) : (
          <>
            {lecture.completed ? (
              <CircleTickIcon className="mr-4 h-6 w-6 text-green-400" />
            ) : (
              <CircleIcon className="mr-4 h-6 w-6 text-gray-400 group-hover:text-white group-focus:text-white transition ease-in-out duration-150" />
            )}
          </>
        )}
        {lecture.content_type === CONTENT_TYPES.DOCUMENT && (
          <>
            {lecture.is_assignment ? (
              <FaCode className="mr-4 h-6 w-6 text-gray-400 group-hover:text-gray-500 group-focus:text-white transition ease-in-out duration-150" />
            ) : (
              <FaFileAlt className="mr-4 h-6 w-6 text-gray-400 group-hover:text-gray-500 group-focus:text-white transition ease-in-out duration-150" />
            )}
          </>
        )}
        {lecture.order}. {lecture.title}
      </div>
      <div>
        <span className="mr-4 h-6 w-6 text-sm text-gray-400 group-hover:text-white group-focus:text-white transition ease-in-out duration-150">
          {lecture.length}
        </span>
      </div>
    </NavLink>
  );
}

function LectureListComponent({
  courseSlug,
  lectureSlug
}: {
  courseSlug: string;
  lectureSlug: string;
}) {
  const { data } = useAuthSWR(api.sections.list(courseSlug));

  function calculateProgress(section: Section) {
    const completedLectures = section.lectures.filter(
      (l: { completed: boolean }) => l.completed === true
    ).length;
    return (100 * completedLectures) / section.lectures.length;
  }

  return (
    <>
      {!data && <LectureListSkeleton />}
      {data?.map((section: Section) => {
        const progress = parseFloat(calculateProgress(section).toFixed(0));
        return (
          <div key={section.order}>
            <div className="mt-3 flex items-center bg-gray-800 px-3 py-2 rounded-md">
              <div className="flex">
                <div>
                  <CircularProgressbar
                    value={progress}
                    text={`${progress}`}
                    styles={{
                      root: {
                        height: 40,
                        width: 40
                      },
                      text: {
                        fill: "#6875f5",
                        fontSize: "30px"
                      },
                      path: {
                        stroke: `#6875f5`
                      }
                    }}
                  />
                </div>
              </div>
              <h3 className="ml-3 text-lg leading-6 font-semibold text-white tracking-wider">
                Section {section.order} <br />
                <span className="text-3xl text-indigo-500">{section.title}</span>
              </h3>
            </div>
            <div className="mt-2 px-3">
              {section.lectures.map((lecture: LectureContent) => {
                return (
                  <LectureItem
                    key={lecture.slug}
                    lecture={lecture}
                    courseSlug={courseSlug}
                    lectureSlug={lectureSlug}
                  />
                );
              })}
            </div>
          </div>
        );
      })}
    </>
  );
}

const LectureList: React.FC<{ courseSlug: string; lectureSlug: string }> = React.memo(
  LectureListComponent,
  (prevProps, nextProps) => {
    if (
      prevProps.courseSlug !== nextProps.courseSlug ||
      prevProps.lectureSlug !== nextProps.lectureSlug
    ) {
      return false;
    }
    return true;
  }
);

const Lecture: React.FC<{ lecture: LectureProps }> = ({ lecture }) => {
  if (!lecture) {
    return (
      <div className="justify-center text-center">
        <div className="w-full h-full mx-auto">
          <div className="animate-pulse flex space-x-4">
            <div className="flex-1 space-y-4 py-1">
              <div className="h-8 bg-gray-300 rounded-sm w-1/4" />
              <div className="space-y-2">
                <div
                  className="bg-gray-300 rounded-sm"
                  style={{
                    height: "100%",
                    minHeight: "600px"
                  }}
                />
              </div>
              <div className="h-8 bg-gray-300 rounded-sm w-1/6" />
            </div>
          </div>
        </div>
      </div>
    );
  }
  if (lecture.content) {
    const contentType = lecture.content.content_type;
    let comp;
    if (contentType === CONTENT_TYPES.DOCUMENT) {
      comp = <CTA document />;
      if (lecture.content.is_assignment) {
        comp = <CTA assignment />;
      }
      if (lecture.content.description) {
        comp = <Document lecture={lecture} />;
        return comp;
      }
    }
    if (contentType === CONTENT_TYPES.VIDEO) {
      comp = <CTA video />;
      if (lecture.content.path) {
        comp = <VideoPlayer lecture={lecture} />;
      }
    } else {
      comp = <CTA />;
    }
    return comp;
  }
  return <CTA />;
};

export default function Classroom(): JSX.Element {
  const [open, setOpen] = useState(false);
  const { courseSlug, lectureSlug } = useParams<{ courseSlug: string; lectureSlug: string }>();
  const { data: course } = useAuthSWR(api.courses.retrieve(courseSlug));
  const { data: lecture } = useAuthFetch(
    api.lectures.retrieve(courseSlug, lectureSlug),
    null,
    lectureSlug
  );

  const [showLectures, toggleLectures] = useState(() => {
    const showLecturesFromStorage = localStorage.getItem("showLectures");
    if (showLecturesFromStorage) {
      const currentVal = JSON.parse(showLecturesFromStorage);
      if (currentVal) {
        return currentVal;
      }
      return false;
    }
    return false;
  });

  function handleToggleLectures() {
    toggleLectures(!showLectures);
    localStorage.setItem("showLectures", JSON.stringify(!showLectures));
  }

  return (
    <div className="h-screen flex overflow-hidden bg-gray-800">
      <KeyboardShortcutsModal open={open} setOpen={setOpen} />
      <Transition
        className="relative flex-1 flex flex-col max-w-md w-full bg-gray-900 pb-5"
        show={showLectures}
        enter="transition ease-in-out duration-500 transform"
        enterFrom="-translate-x-full"
        enterTo="translate-x-0"
        leave="transform transition ease-in-out duration-500 transform"
        leaveFrom="translate-x-0"
        leaveTo="-translate-x-full"
      >
        <div className="flex-1 h-0 overflow-y-auto">
          <nav className="px-2">
            <LectureList courseSlug={courseSlug} lectureSlug={lectureSlug} />
          </nav>
        </div>
      </Transition>
      <div className="flex flex-col w-0 flex-1 overflow-hidden py-6">
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <div className="flex items-center justify-between">
              <div className="flex">
                <button
                  type="button"
                  onClick={() => handleToggleLectures()}
                  className="bg-gray-800 flex items-center justify-center rounded-md focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                >
                  <span className="sr-only">Close sidebar</span>
                  <ThreeLinesIcon className="h-6 w-6 text-white" />
                </button>
                <BackToDashboardButton course={course} />
              </div>
              <div>
                <button
                  onClick={() => setOpen(true)}
                  type="button"
                  className="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Keyboard Shortcuts
                  <InformationCircleIcon className="ml-2 -mr-0.5 h-4 w-4" aria-hidden="true" />
                </button>
              </div>
            </div>
            <div className="py-4">
              <Lecture lecture={lecture} />
            </div>
            <div className="flex-1 h-0 mb-5">
              <CourseDetailContainer course={course} lecture={lecture} />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
