import React from "react";
import { NavLink } from "react-router-dom";
import Logo from "src/assets/images/logo.svg";

export const Footer: React.FC = () => (
  <footer className="py-8 text-center md:text-left">
    <div className="container mx-auto">
      <div className=" flex flex-col lg:flex-row content-center items-center justify-between">
        <div className="flex items-center flex-col lg:flex-row mx-auto">
          <img src={Logo} className="w-8 lazy" alt="Logo" data-ll-status="observed" />
          <span className="ml-0 md:ml-4 text-sm text-gray-600 py-4 lg:py-0">
            JustDjango, Copyright © {new Date().getFullYear()}
          </span>
        </div>
        <div className="navbar mx-auto">
          <ul className="navbar-nav flex flex-">
            <li className="active px-md-3 px-2">
              <NavLink
                className=" text-gray-700 text-sm hover:text-gray-900 transition duration-500 ease-in-out"
                to="/contact"
              >
                Contact
              </NavLink>
            </li>
            <li className=" px-md-3 px-2">
              <NavLink
                className=" text-gray-700 text-sm hover:text-gray-900 transition duration-500 ease-in-out"
                to="/faq"
              >
                FAQ
              </NavLink>
            </li>
            <li className=" px-md-3 px-2">
              <NavLink
                className=" text-gray-700 text-sm hover:text-gray-900 transition duration-500 ease-in-out"
                to="/pricing"
              >
                Pricing
              </NavLink>
            </li>
            <li className=" px-md-2 px-2 hidden lg:block">
              <NavLink
                className=" text-gray-700 text-sm hover:text-gray-900 transition duration-500 ease-in-out"
                to="/terms-and-conditions"
              >
                T&apos;s & C&apos;s
              </NavLink>
            </li>
            <li className=" px-md-2 px-2 hidden lg:block">
              <NavLink
                className=" text-gray-700 text-sm hover:text-gray-900 transition duration-500 ease-in-out"
                to="/privacy-policy"
              >
                Privacy Policy
              </NavLink>
            </li>
            {/* <li className=" px-md-2 px-2 signup hidden lg:block"> */}
            {/*  <NavLink */}
            {/*    className="text-md text-white px-3 py-2 bg-red-500 hover:bg-red-600 rounded border border-red-600 shadow font-semibold cursor-pointer transition duration-500 ease-in-out" */}
            {/*    to="/signup" */}
            {/*  > */}
            {/*    Signup for free */}
            {/*  </NavLink> */}
            {/* </li> */}
          </ul>
        </div>
      </div>
    </div>

    {/* <div className="bg-white"> */}
    {/*  <div className="max-w-screen-xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8"> */}
    {/*    <nav className="-mx-5 -my-2 flex flex-wrap justify-center"> */}
    {/*      <div className="px-5 py-2"> */}
    {/*        <NavLink to="/pricing" className="text-base leading-6 text-gray-500 hover:text-gray-900"> */}
    {/*          Pricing */}
    {/*        </NavLink> */}
    {/*      </div> */}
    {/*      <div className="px-5 py-2"> */}
    {/*        <NavLink to="/contact" className="text-base leading-6 text-gray-500 hover:text-gray-900"> */}
    {/*          Contact */}
    {/*        </NavLink> */}
    {/*      </div> */}
    {/*      <div className="px-5 py-2"> */}
    {/*        <NavLink */}
    {/*          to="/privacy-policy" */}
    {/*          className="text-base leading-6 text-gray-500 hover:text-gray-900" */}
    {/*        > */}
    {/*          Privacy Policy */}
    {/*        </NavLink> */}
    {/*      </div> */}
    {/*      <div className="px-5 py-2"> */}
    {/*        <NavLink */}
    {/*          to="/terms-and-conditions" */}
    {/*          className="text-base leading-6 text-gray-500 hover:text-gray-900" */}
    {/*        > */}
    {/*          Terms of use */}
    {/*        </NavLink> */}
    {/*      </div> */}
    {/*    </nav> */}
    {/*    <div className="mt-8 flex justify-center"> */}
    {/*      <a href="https://twitter.com/justdjangocode" className="text-gray-400 hover:text-gray-500"> */}
    {/*        <span className="sr-only">Twitter</span> */}
    {/*        <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24"> */}
    {/*          <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" /> */}
    {/*        </svg> */}
    {/*      </a> */}
    {/*      <a href="https://github.com/justdjango" className="ml-6 text-gray-400 hover:text-gray-500"> */}
    {/*        <span className="sr-only">GitHub</span> */}
    {/*        <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24"> */}
    {/*          <path */}
    {/*            fillRule="evenodd" */}
    {/*            d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z" */}
    {/*            clipRule="evenodd" */}
    {/*          /> */}
    {/*        </svg> */}
    {/*      </a> */}
    {/*      <a */}
    {/*        href="https://www.youtube.com/channel/UCRM1gWNTDx0SHIqUJygD-kQ" */}
    {/*        className="ml-6 text-gray-400 hover:text-gray-500" */}
    {/*      > */}
    {/*        <span className="sr-only">YouTube</span> */}
    {/*        <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24"> */}
    {/*          <path */}
    {/*            fillRule="evenodd" */}
    {/*            d="M23.495 6.205a3.007 3.007 0 0 0-2.088-2.088c-1.87-.501-9.396-.501-9.396-.501s-7.507-.01-9.396.501A3.007 3.007 0 0 0 .527 6.205a31.247 31.247 0 0 0-.522 5.805 31.247 31.247 0 0 0 .522 5.783 3.007 3.007 0 0 0 2.088 2.088c1.868.502 9.396.502 9.396.502s7.506 0 9.396-.502a3.007 3.007 0 0 0 2.088-2.088 31.247 31.247 0 0 0 .5-5.783 31.247 31.247 0 0 0-.5-5.805zM9.609 15.601V8.408l6.264 3.602z" */}
    {/*            clipRule="evenodd" */}
    {/*          /> */}
    {/*        </svg> */}
    {/*      </a> */}
    {/*    </div> */}
    {/*    <div className="mt-8"> */}
    {/*      <p className="text-center text-base leading-6 text-gray-400"> */}
    {/*        &copy; {new Date().getFullYear()}{' '} */}
    {/*        JustDjango. All rights reserved. */}
    {/*      </p> */}
    {/*    </div> */}
    {/*  </div> */}
    {/* </div> */}
  </footer>
);
