import { NavLink, useParams } from "react-router-dom";
import React, { useContext } from "react";
import useSWR from "swr";
import { AuthContext } from "src/contexts";
import { useFetch } from "src/helpers";
import api from "src/api";
import { Course, Merchant } from "src/models";
import { AppProviders } from "src/providers";
import { CourseListItem } from "./roadmap";

function Roadmap() {
  const { roadmapSlug } = useParams<{ roadmapSlug: string }>();
  const { authAxios } = useContext(AuthContext);
  const { user } = useContext(AuthContext);
  const { data: roadmap } = useFetch(api.roadmaps.retrieve(roadmapSlug));
  const { data: merchant } = useSWR<Merchant>(
    user ? api.memberships.summary : null,
    (url) => authAxios(url).then((res) => res.data),
    { revalidateOnFocus: false }
  );
  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-md">
      <div className="max-w-screen-xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
        {!roadmap && (
          <>
            <div className="animate-pulse flex space-x-4 max-w-4xl mx-auto">
              <div className="flex-1 space-y-4 py-1">
                <div className="max-w-sm mx-auto rounded overflow-hidden">
                  <div className="h-64 bg-gray-300 rounded-sm" />
                  <div className="space-y-2 mt-5">
                    <div className="h-4 bg-gray-300 rounded-sm w-1/2" />
                    <div className="h-3 bg-gray-300 rounded-sm" />
                    <div className="h-3 bg-gray-300 rounded-sm" />
                    <div className="h-3 bg-gray-300 rounded-sm" />
                    <div className="h-3 bg-gray-300 rounded-sm" />
                  </div>
                </div>
              </div>
            </div>
            <div className="max-w-4xl mx-auto flex flex-col mt-5">
              <div>
                {Array(10)
                  .fill(undefined)
                  .map((i, j) => {
                    return (
                      <div className="animate-pulse flex space-x-4" key={j}>
                        <div className="flex-1 space-y-4 py-4">
                          <div className="rounded overflow-hidden">
                            <div className="space-y-2 mt-5">
                              <div className="h-4 bg-gray-300 rounded-sm w-1/3" />
                              <div className="h-3 bg-gray-300 rounded-sm" />
                              <div className="h-3 bg-gray-300 rounded-sm" />
                              <div className="h-3 bg-gray-300 rounded-sm" />
                              <div
                                className="h-3 bg-gray-300 rounded-sm"
                                style={{ width: "80px" }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </>
        )}

        {roadmap && (
          <main className="mt-2 mx-auto max-w-7xl px-4 sm:mt-3 sm:px-6 lg:mt-4">
            <div className="sm:text-center md:max-w-2xl md:mx-auto">
              <h1>
                <span className="mt-1 block text-4xl tracking-tight font-extrabold sm:text-5xl">
                  <NavLink className="block text-gray-900" to={`/roadmaps/${roadmap.slug}`}>
                    {roadmap.title} Roadmap
                  </NavLink>
                </span>
              </h1>
              <p className="mt-3 text-gray-700 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                These are older courses that used to be part of the {roadmap.title} Roadmap
              </p>
            </div>
            <div className="flex flex-wrap justify-center mt-12">
              <ul>
                {roadmap.courses
                  .filter((x: { archived: boolean }) => x.archived === true)
                  .map((course: Course) => {
                    return <CourseListItem key={course.id} course={course} merchant={merchant} />;
                  })}
              </ul>
            </div>
          </main>
        )}
      </div>
    </div>
  );
}

export default function Index(): JSX.Element {
  return (
    <AppProviders>
      <Roadmap />
    </AppProviders>
  );
}
