import React from "react";
import { CalendarDatum, ResponsiveCalendar } from "@nivo/calendar";

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.

export function ActivityBoard({ data }: { data: CalendarDatum[] }): JSX.Element {
  const year = new Date().getFullYear();
  return (
    <ResponsiveCalendar
      data={data}
      from={`${year}-01-01`}
      to={`${year}-12-31`}
      emptyColor="#F9FAF8"
      colors={["#cabffd", "#9061f9", "#6c2bd9", "#4a1d96"]}
      margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
      yearSpacing={40}
      monthBorderColor="#ffffff"
      dayBorderWidth={2}
      dayBorderColor="#ffffff"
      legends={[
        {
          anchor: "bottom",
          direction: "row",
          translateY: 40,
          itemCount: 4,
          itemWidth: 42,
          itemHeight: 36,
          itemsSpacing: 14,
          itemDirection: "right-to-left"
        }
      ]}
    />
  );
}
