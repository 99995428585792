import React, { useContext, useState } from "react";
import { PayPalButtons } from "@paypal/react-paypal-js";
import {
  UnknownObject,
  CreateSubscriptionActions,
  OnApproveData,
  OnApproveActions,
  CreateOrderActions
} from "@paypal/paypal-js/types/components/buttons";
import { Message } from "src/components";
import { AuthContext } from "src/contexts";
import errorHandler from "src/utils";
import api from "src/api";

export function PaypalSubscribeButton({
  planID,
  callback
}: {
  planID: string;
  callback?: () => void;
}): JSX.Element {
  const [state, _setState] = useState({
    showButtons: false,
    loading: false,
    paid: false,
    error: null,
    showCancelled: false
  });

  function createSubscription(data: Record<string, unknown>, actions: CreateSubscriptionActions) {
    /* TODO: this doesn't seem bulletproof. We have to send the
        subscription ID to the Django server in the onApprove method. But what happens
        if the client lost internet right after they got redirected back from PayPal?
        The webhook wouldn't work because there's no other way to identify who the
        purchase came from.
    */

    return actions.subscription.create({
      plan_id: planID
    });
  }

  function onApprove(data: OnApproveData) {
    setState({ loading: false, paid: true }, () => {
      callback && callback();
    });

    // TODO what does the paypal Callback do?
    const { authAxios } = useContext(AuthContext);
    authAxios
      .post(api.billing.paypalCallback, { subscription_id: data.subscriptionID })
      .catch((err) => {
        setState({ error: errorHandler(err) });
      });
    return new Promise<void>((resolve) => {
      resolve();
    });
  }

  function onCancel() {
    setState({ showCancelled: true });
  }

  function onError(err: UnknownObject) {
    try {
      setState({
        error: err.message
      });
    } catch (e) {
      setState({
        error:
          "There was an error processing your payment. If this error persists please contact matt@justdjango.com"
      });
    }
  }

  function setState(obj: Record<string, unknown>, _callback?: () => void) {
    _setState({
      ...state,
      ...obj
    });
    _callback && _callback();
  }

  const { loading, paid, error, showCancelled } = state;

  return (
    <div>
      {showCancelled && (
        <Message
          type="WARNING"
          body="You cancelled your checkout process. To try again click the PayPal button below."
          onDismiss={() => setState({ error: false })}
        />
      )}
      {error && <Message type="ERROR" body={error} onDismiss={() => setState({ error: null })} />}
      {paid && <Message type="SUCCESS" body="Thank you! Your payment was successful." noDismiss />}
      <PayPalButtons
        style={{
          shape: "rect",
          color: "gold",
          layout: "horizontal",
          label: "subscribe"
        }}
        disabled={loading}
        createSubscription={(data, actions) => createSubscription(data, actions)}
        onApprove={(data) => onApprove(data)}
        onCancel={() => onCancel()}
        onError={(err) => onError(err)}
      />
    </div>
  );
}

export function PaypalPurchaseButton({
  planID,
  callback
}: {
  planID: string;
  callback?: () => void;
}): JSX.Element {
  const [state, _setState] = useState({
    showButtons: false,
    loading: false,
    paid: false,
    error: null,
    showCancelled: false
  });

  function createOrder(data: Record<string, unknown>, actions: CreateOrderActions) {
    /* TODO: this doesn't seem bulletproof. We have to send the
        subscription ID to the Django server in the onApprove method. But what happens
        if the client lost internet right after they got redirected back from PayPal?
        The webhook wouldn't work because there's no other way to identify who the
        purchase came from.
    */

    return fetch(api.billing.paypalCreateOrder, {
      method: "post",
      headers: {
        "content-type": "application/json"
      },
      body: JSON.stringify({ plan_id: planID })
    })
      .then(function (res) {
        return res.json();
      })
      .then(function (data) {
        return data.id;
      });
  }

  function onApprove(data: OnApproveData, actions: OnApproveActions) {
    return actions.order.capture().then(function () {
      setState({ loading: false, paid: true }, () => {
        callback && callback();
      });
    });
  }

  function onCancel() {
    setState({ showCancelled: true });
  }

  function onError(err: UnknownObject) {
    try {
      setState({
        error: err.message
      });
    } catch (e) {
      setState({
        error:
          "There was an error processing your payment. If this error persists please contact matt@justdjango.com"
      });
    }
  }

  function setState(obj: Record<string, unknown>, _callback?: () => void) {
    _setState({
      ...state,
      ...obj
    });
    _callback && _callback();
  }

  const { loading, paid, error, showCancelled } = state;

  return (
    <div>
      {showCancelled && (
        <Message
          type="WARNING"
          body="You cancelled your checkout process. To try again click the PayPal button below."
          onDismiss={() => setState({ showCancelled: false })}
        />
      )}
      {error && <Message type="ERROR" body={error} onDismiss={() => setState({ error: null })} />}
      {paid && <Message type="SUCCESS" body="Thank you! Your payment was successful." noDismiss />}
      <PayPalButtons
        style={{
          shape: "rect",
          color: "gold",
          layout: "horizontal",
          label: "buynow"
        }}
        disabled={loading}
        createOrder={(data, actions) => createOrder(data, actions)}
        onApprove={(data, actions) => onApprove(data, actions)}
        onCancel={() => onCancel()}
        onError={(err) => onError(err)}
      />
    </div>
  );
}
