import React, { Fragment, useContext } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";
import { useAuthSWR } from "src/helpers";
import { BADGES } from "src/utils";
import { AuthContext } from "src/contexts";
import { Loader } from "src/components";
import api from "src/api";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

function Experience() {
  const { data } = useAuthSWR(api.profiles.experience);
  return <div className="p-1 text-gray-600">{data && data.experience} XP</div>;
}

function Badge() {
  const { data } = useAuthSWR(api.profiles.badge);
  return (
    <div>
      {!data && <Loader />}
      {data && <img className="h-8 w-8 rounded-full" src={BADGES[data.badge]} alt="" />}
    </div>
  );
}

export function DropdownProfile({ open }: { open: boolean }): JSX.Element {
  const { user, logout } = useContext(AuthContext);
  return (
    <>
      {user && (
        <>
          <div className="flex">
            <Experience />
            <div>
              <div>
                <Menu.Button className="relative bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  <span className="sr-only">Open user menu</span>
                  <Badge />
                </Menu.Button>
              </div>
              <Transition
                show={open}
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <div>
                  <div className="">
                    <div className="z-10 origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-gray-900 ring-opacity-5 focus:outline-none font-normal text-sm text-gray-900 divide-y divide-gray-100">
                      <p className="py-3 px-3.5 truncate">
                        <span className="block mb-0.5 text-xs text-gray-500">Signed in as</span>
                        <span className="font-semibold">{user.user.email}</span>
                      </p>

                      <Menu.Items static className="py-1.5 px-3.5">
                        <Menu.Item>
                          {() => (
                            <NavLink
                              to="/account/profile"
                              className="group flex items-center py-1.5 hover:text-gray-600"
                            >
                              <svg
                                aria-hidden="true"
                                width="20"
                                height="20"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                className="flex-none mr-3 text-gray-400 group-hover:text-teal-600"
                              >
                                <rect x="7.75" y="5.75" width="4.5" height="4.5" rx="2.25"></rect>
                                <rect x="2.75" y="2.75" width="14.5" height="14.5" rx="7.25"></rect>
                                <path d="M14.618 15.5A5.249 5.249 0 0010 12.75a5.249 5.249 0 00-4.618 2.75"></path>
                              </svg>
                              Your Profile
                            </NavLink>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                      <div className="py-1.5 px-3.5">
                        <button
                          onClick={logout}
                          type="button"
                          className="group flex w-full items-center py-1.5 text-gray-900 hover:text-teal-600"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5 flex-none mr-3 text-gray-400 group-hover:text-teal-600"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z"
                              clipRule="evenodd"
                            />
                          </svg>
                          Sign out
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Transition>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export function MobileDropdownProfile(): JSX.Element {
  const { user, logout } = useContext(AuthContext);
  const { pathname } = useLocation();

  const currentMobileClassName = "bg-indigo-50 border-indigo-500 text-indigo-700";
  const defaultMobileClassName =
    "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700";

  return (
    <>
      {user && (
        <>
          <div className="flex items-center px-4 ">
            <div className="flex-shrink-0">
              <div className="flex">
                <Experience />
                <Badge />
              </div>
            </div>
          </div>
          <div className="mt-3 space-y-1">
            <p className="py-3 px-3.5 truncate">
              <span className="block mb-0.5 text-xs text-gray-500">Signed in as</span>
              <span className="font-semibold">{user.user.email}</span>
            </p>
            <NavLink
              to="/account/profile"
              className={`${
                pathname === "/account/profile" ? currentMobileClassName : defaultMobileClassName
              } block pl-3 pr-4 py-2 border-l-4 text-base font-medium`}
            >
              Your Profile
            </NavLink>
            <div
              role="button"
              tabIndex={0}
              onClick={logout}
              className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
            >
              Sign out
            </div>
          </div>
        </>
      )}
    </>
  );
}
