import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import Confetti from "react-confetti";
import { Divider } from "../components";
import HappyGIF from "src/assets/images/happy.gif";
import { AuthContext } from "../contexts";

export default function Success(): JSX.Element {
  const { user } = useContext(AuthContext);
  return (
    <div className="bg-gray-100 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <Confetti />
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">
          Thank you for enrolling!
        </h2>
      </div>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <div className="w-full py-5">
            <img src={HappyGIF} alt="Happy GIF" />
          </div>
          {user ? (
            <div>
              You now have lifetime access to JustDjango. Since you&apos;re logged in, go ahead and{" "}
              <NavLink to={"/dashboard"} className={"underline hover:text-gray-800"}>
                continue learning
              </NavLink>
              !
            </div>
          ) : (
            <>
              <p>
                If you already have an account,{" "}
                <NavLink to={"/login"} className={"underline hover:text-gray-800"}>
                  login now
                </NavLink>{" "}
                to get started!
              </p>
              <Divider />
              <p>
                Otherwise,{" "}
                <NavLink to={"/forgot-password"} className={"underline hover:text-gray-800"}>
                  request a password reset
                </NavLink>
                . Once your password is reset you&apos;ll be able to login.
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
