import React, { useState, ReactNode, useContext } from "react";
import { NavLink } from "react-router-dom";
import { PricingPlan } from "src/models";
import { history, useAuthSWR } from "src/helpers";
import api from "src/api";
import { AuthContext } from "src/contexts";
import { TickIcon } from "./icons";
import { Button } from "./Button";
import useSWR from "swr";
import axios from "axios";

interface CustomPlan {
  id: string;
  period: "monthly" | "lifetime";
  price: number;
  slug: string;
}

function generatePlans(basicPeriods: any = [], proPeriods: any = []) {
  return [
    {
      name: "Basic",
      subtitle: "Learn the basics through project-based courses",
      coinbaseCheckoutID: "fe798c8e-bff4-4bb3-9ca5-c330be3d0ca9",
      periods: basicPeriods,
      points: [
        <span key={1}>
          Access to the{" "}
          <NavLink to="/roadmaps/django-basic" className="text-red-500 hover:text-red-700">
            Basic Django roadmap
          </NavLink>
        </span>,
        <span key={2}>Over 25 hours of Django learning material</span>
      ],
      mostPopular: false
    },
    {
      name: "Pro",
      subtitle: "Elevate your developer skills and launch your career",
      coinbaseCheckoutID: "7d89ae5e-8baf-4df6-b646-05e74aff5eba",
      periods: proPeriods,
      points: [
        <span key={1}>
          Access to the{" "}
          <NavLink to="/roadmaps/django-basic" className="text-red-500 hover:text-red-700">
            Basic Django roadmap
          </NavLink>
        </span>,
        <span key={2}>
          Access to the{" "}
          <NavLink to="/roadmaps/django-advanced" className="text-red-500 hover:text-red-700">
            Advanced Django roadmap
          </NavLink>
        </span>,
        <span key={3}>Over 60 hours of Django learning material</span>
      ],
      mostPopular: true
    }
  ];
}

function PricingPlanCard({ plan, period }: { plan: PricingPlan; period: "monthly" | "lifetime" }) {
  const activePlan = plan.periods.filter((x) => x.period === period)[0];
  const { user } = useContext(AuthContext);
  const { data: membership } = useAuthSWR(user ? api.memberships.retrieve : null);

  function handleSubmit() {
    if (activePlan.period === "lifetime") {
      history.push(`/payment/${activePlan.slug}`);
    } else {
      history.push(`/enroll/${activePlan.slug}`);
    }
  }

  return (
    <div className="border border-gray-200 bg-white rounded-lg shadow-sm divide-y divide-gray-200">
      <div className="p-6">
        <div className="flex justify-between items-center">
          <h2 className="text-lg leading-6 font-medium text-gray-900">{plan.name}</h2>
          {plan.mostPopular && (
            <div className="inline-flex items-center px-3 py-1 rounded-md  font-medium bg-purple-100 text-purple-800">
              Most Popular
            </div>
          )}
        </div>
        <p className="mt-4 text-sm leading-5 text-gray-500">{plan.subtitle}</p>
        <div className="mt-8 flex w-full justify-between">
          <div className="flex items-end text-4xl leading-10 font-extrabold text-gray-900">
            ${activePlan.price}
            <span className="ml-1 text-base leading-6 font-medium text-gray-500">
              {activePlan.period === "lifetime" ? "Billed once off" : "/mo"}
            </span>
          </div>
        </div>

        {membership && membership.planID === activePlan.id && membership.status !== "canceled" ? (
          <div className="mt-8 w-full text-center bg-purple-600 border border-transparent rounded-md py-2 text-sm leading-5 font-semibold text-white">
            Currently enrolled
          </div>
        ) : (
          <Button
            type="button"
            onClick={handleSubmit}
            className="mt-8 w-full bg-red-600 border border-transparent rounded-md py-2 text-sm leading-5 font-semibold text-white hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red active:bg-red-700 transition duration-300 ease-in-out hover:bg-red-600 transform hover:-translate-y-1"
            label="Enroll"
          />
        )}
      </div>
      <div className="pt-6 pb-8 px-6">
        <h3 className="text-xs leading-4 font-medium text-gray-900 tracking-wide uppercase">
          What&apos;s included
        </h3>
        <ul className="mt-6 space-y-4">
          {plan.points.map((point: ReactNode, i: number) => {
            return (
              <li className="flex space-x-3" key={i}>
                <TickIcon className="flex-shrink-0 h-5 w-5 text-green-500" />
                <span className="text-sm leading-5 text-gray-500">{point}</span>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}

export function PlansContainer(): JSX.Element {
  const [period, setPeriod] = useState<"monthly" | "lifetime">("lifetime");
  const { data: plans } = useSWR<{
    monthly_basic: CustomPlan;
    monthly_pro: CustomPlan;
    lifetime_basic: CustomPlan;
    lifetime_pro: CustomPlan;
  }>(api.billing.pricing, (url) => axios(url).then((res) => res.data));

  if (!plans) {
    return <PlansLoader />;
  }

  const customPlans = generatePlans(
    [plans.monthly_basic, plans.lifetime_basic],
    [plans.monthly_pro, plans.lifetime_pro]
  );

  return (
    <div className="py-16 lg:py-12 bg-gray-100">
      <div className="max-w-screen-xl mx-auto pt-12 px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:flex-col sm:align-center">
          <h1 className="text-5xl leading-none font-extrabold text-gray-900 sm:text-center">
            Pricing Plans
          </h1>
          <p className="mt-5 text-xl leading-7 text-gray-600 sm:text-center">
            Get a package that suits you
          </p>
          <div className="relative mt-6 bg-gray-100 rounded-lg p-0.5 flex self-center sm:mt-8">
            <button
              onClick={() => setPeriod("monthly")}
              type="button"
              className={`${
                period === "monthly"
                  ? "relative bg-white border-gray-200 rounded-md shadow-sm py-2 w-1/2 text-sm leading-5 font-medium text-gray-700 whitespace-no-wrap hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:z-10 active:bg-gray-100 active:text-gray-800 transition ease-in-out duration-150 sm:w-auto sm:px-8"
                  : "relative border border-transparent rounded-md py-2 w-1/2 text-sm leading-5 font-medium text-gray-700 whitespace-no-wrap hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:z-10 active:bg-gray-100 active:text-gray-800 transition ease-in-out duration-150 sm:w-auto sm:px-8"
              }`}
            >
              Monthly billing
            </button>
            <button
              onClick={() => setPeriod("lifetime")}
              type="button"
              className={`${
                period === "lifetime"
                  ? "relative bg-white border-gray-200 rounded-md shadow-sm py-2 w-1/2 text-sm leading-5 font-medium text-gray-700 whitespace-no-wrap hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:z-10 active:bg-gray-100 active:text-gray-800 transition ease-in-out duration-150 sm:w-auto sm:px-8"
                  : "relative border border-transparent rounded-md py-2 w-1/2 text-sm leading-5 font-medium text-gray-700 whitespace-no-wrap hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:z-10 active:bg-gray-100 active:text-gray-800 transition ease-in-out duration-150 sm:w-auto sm:px-8"
              }`}
            >
              Lifetime deal
            </button>
          </div>
        </div>
        <div className="mt-12 space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-4xl xl:mx-auto xl:grid-cols-2">
          {customPlans.map((plan: PricingPlan) => (
            <PricingPlanCard key={plan.name} plan={plan} period={period} />
          ))}
        </div>
      </div>
    </div>
  );
}

export function PlansLoader(): JSX.Element {
  const plans = generatePlans();

  return (
    <div className="py-16 lg:py-12 bg-gray-100">
      <div className="max-w-screen-xl mx-auto pt-12 px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:flex-col sm:align-center">
          <h1 className="text-5xl leading-none font-extrabold text-gray-900 sm:text-center">
            Pricing Plans
          </h1>
          <p className="mt-5 text-xl leading-7 text-gray-600 sm:text-center">
            Get a package that suits you
          </p>
          <div className="relative bg-gray-100 rounded-lg p-0.5 flex self-center sm:mt-8">
            <div className="py-6">
              <div className="w-full mx-auto">
                <div className="animate-pulse flex space-x-4">
                  <div className="flex-1 space-y-4 py-1">
                    <div className="h-8 bg-gray-300 rounded-sm" style={{ width: "150px" }} />
                  </div>
                </div>
              </div>
            </div>
            <div className="ml-3 py-6">
              <div className="w-full mx-auto">
                <div className="animate-pulse flex space-x-4">
                  <div className="flex-1 space-y-4 py-1">
                    <div className="h-8 bg-gray-300 rounded-sm" style={{ width: "150px" }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-12 space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-4xl xl:mx-auto xl:grid-cols-2">
          {plans.map((plan: { name: string; subtitle: string; points: ReactNode[] }) => (
            <div
              className="border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200"
              key={plan.name}
            >
              <div className="p-6">
                <h2 className="text-lg leading-6 font-medium text-gray-900">{plan.name}</h2>
                <p className="mt-4 text-sm leading-5 text-gray-500">{plan.subtitle}</p>
                <div className="mt-8 flex w-full justify-between">
                  <div className="flex items-end text-4xl leading-10 font-extrabold text-gray-900">
                    <div className="w-full mx-auto">
                      <div className="animate-pulse flex space-x-4">
                        <div className="flex-1 space-y-4 py-1">
                          <div className="space-y-2">
                            <div
                              className="h-12 bg-gray-300 rounded-sm"
                              style={{ width: "80px" }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-full mx-auto">
                  <div className="animate-pulse flex space-x-4">
                    <div className="flex-1 space-y-4 py-1">
                      <div className="space-y-2">
                        <div className="h-12 bg-gray-300 rounded-sm" />
                        <div className="h-12 bg-gray-300 rounded-sm" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pt-6 pb-8 px-6">
                <h3 className="text-xs leading-4 font-medium text-gray-900 tracking-wide uppercase">
                  What&apos;s included
                </h3>
                <ul className="mt-6 space-y-4">
                  {plan.points.map((point: ReactNode, i: number) => {
                    return (
                      <li className="flex space-x-3" key={i}>
                        <TickIcon className="flex-shrink-0 h-5 w-5 text-green-500" />
                        <span className="text-sm leading-5 text-gray-500">{point}</span>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
