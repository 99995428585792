import React, { ReactNode } from "react";
import { AuthContextProvider } from "src/contexts";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { PAYPAL_CLIENT_ID } from "./config";

export function AppProviders({ children }: { children: ReactNode }): JSX.Element {
  return (
    <AuthContextProvider>
      <PayPalScriptProvider options={{ "client-id": PAYPAL_CLIENT_ID, vault: true }}>
        {children}
      </PayPalScriptProvider>
    </AuthContextProvider>
  );
}
