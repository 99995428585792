import React, { useContext, useEffect, useState } from "react";
import { Redirect, useParams, NavLink } from "react-router-dom";
import { mutate } from "swr";
import { CardElement, Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import { history, useAuthSWR, useOldFetch, WithTransition } from "src/helpers";
import {
  Button,
  CarrotDownIcon,
  Divider,
  Message,
  PaymentMethodCard,
  TickIcon
} from "src/components";
import { AuthContext } from "src/contexts";
import errorHandler, { PLANS } from "src/utils";
import api from "src/api";
import { toast } from "react-hot-toast";
import Logo from "../assets/images/logo.svg";
import { createOptions } from "../containers/Forms/common";
import { STRIPE_PUBLIC_KEY } from "../config";
import { LoginForm } from "./auth";

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

interface CheckoutFormAuthProps {
  callback: () => void;
  plan: any;
  membership: any;
  allowDefault?: boolean;
  payingDueInvoice?: boolean;
}

interface PaymentFormProps {
  callback: () => void;
  plan: any;
  membership: any;
  allowDefault?: boolean;
  payingDueInvoice?: boolean;
}

function CheckoutForm({ callback, plan, membership, allowDefault = true }: PaymentFormProps) {
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);
  const [processing, setProcessing] = useState<boolean>(false);
  const [useAnotherCard, setUsingNewCard] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");

  const { user, authAxios } = useContext(AuthContext);

  const [mustLogin, setMustLogin] = useState(!user);

  useEffect(() => {
    setMustLogin(!user);
  }, [user]);

  let emailTimeout: any;
  let planID: null | string = null;
  if (plan) {
    planID = plan.stripe_id;
  }

  const stripe = useStripe();
  const elements = useElements();

  const handleChange = ({ error }: { error: any }) => {
    if (error && error.message) setError(error.message);
  };

  const handleSuccess = () => {
    setError(null);
    setSuccess(true);
    setProcessing(false);
    localStorage.removeItem("latestInvoicePaymentIntentStatus");
    localStorage.removeItem("latestInvoiceId");
    localStorage.removeItem("planId");
    toast.success("Payment Successful!", {
      duration: 4000,
      icon: "🎉",
      iconTheme: {
        primary: "#000",
        secondary: "#fff"
      }
    });
    mutate(api.memberships.retrieve);
    mutate(api.memberships.transactions.list);
    mutate(api.memberships.summary);
    // mutate(api.slack.status);
    callback();
  };

  function checkEmail(val: string) {
    clearTimeout(emailTimeout);
    setEmail(val);
    emailTimeout = setTimeout(function () {
      axios.post(api.account.exists, { email: val }).then((res) => {
        if (res.data.exists) {
          setMustLogin(true);
        }
      });
    }, 1000);
  }

  function handleLogin() {
    setMustLogin(false);
  }

  function retryInvoiceWithNewPaymentMethod(paymentMethodId: string, invoiceId: string) {
    return (
      authAxios
        .post(api.billing.payDueInvoice, {
          paymentMethodId,
          invoiceId
        })
        .then((response) => {
          return response.data;
        })
        // If the card is declined, display an error to the user.
        .then((result) => {
          if (result.error) {
            // The card had an error when trying to attach it to a customer
            throw result;
          }
          return result;
        })
        // Normalize the result to contain the object returned
        // by Stripe. Add the addional details we need.
        .then((result) => {
          return {
            // Use the Stripe 'object' property on the
            // returned result to understand what object is returned.
            invoice: result,
            paymentMethodId,
            isRetry: true
          };
        })
        // Some payment methods require a customer to be on session
        // to complete the payment process. Check the status of the
        // payment intent to handle these actions.
        .then(handlePaymentThatRequiresCustomerAction)
        // No more actions required. Provision your service for the user.
        .then(handleSuccess)
        .catch((error) => {
          // An error has happened. Display the failure to the user here.
          // We utilize the HTML element we created.
          setError(errorHandler(error));
          setProcessing(false);
          setUsingNewCard(true);
        })
    );
  }

  interface Subscription {
    status: string;
    latest_invoice: {
      id: string;
      payment_intent: {
        status: string;
        client_secret: string;
      };
    };
  }

  interface Invoice {
    payment_intent: {
      status: string;
      client_secret: string;
    };
  }

  function handlePaymentThatRequiresCustomerAction({
    subscription,
    invoice,
    paymentMethodId,
    isRetry
  }: any):
    | { subscription: Subscription; invoice: Invoice; paymentMethodId: string }
    | { subscription: Subscription; paymentMethodId: string }
    | PromiseLike<
        { subscription: Subscription; invoice: Invoice; paymentMethodId: string } | undefined
      >
    | undefined {
    if (subscription && subscription.status === "active") {
      // subscription is active, no customer actions required.
      return { subscription, paymentMethodId };
    }

    // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
    // If it's a retry, the payment intent will be on the invoice itself.
    const paymentIntent = invoice
      ? invoice.payment_intent
      : subscription!.latest_invoice.payment_intent;

    if (
      paymentIntent.status === "requires_action" ||
      (isRetry === true && paymentIntent.status === "requires_payment_method")
    ) {
      return stripe!
        .confirmCardPayment(paymentIntent.client_secret, {
          payment_method: paymentMethodId
        })
        .then((result) => {
          if (result.error) {
            // start code flow to handle updating the payment details
            // Display error message in your UI.
            // The card was declined (i.e. insufficient funds, card has expired, etc)
            if (result.error.message) {
              throw result.error.message;
            }
            throw result;
          } else if (result.paymentIntent.status === "succeeded") {
            // There's a risk of the customer closing the window before callback
            // execution. To handle this case, set up a webhook endpoint and
            // listen to invoice.paid. This webhook endpoint returns an Invoice.
            return {
              subscription,
              invoice,
              paymentMethodId
            };
          }
        });
    }
    // No customer action needed
    return { subscription, paymentMethodId };
  }

  function handleRequiresPaymentMethod({ subscription, paymentMethodId }: any) {
    if (subscription.status === "active") {
      // subscription is active, no customer actions required.
      return { subscription, paymentMethodId };
    }
    if (subscription.latest_invoice.payment_intent.status === "requires_payment_method") {
      // Store the latest invoice ID and status
      localStorage.setItem("planId", planID!);
      localStorage.setItem("latestInvoiceId", subscription.latest_invoice.id);
      localStorage.setItem(
        "latestInvoicePaymentIntentStatus",
        subscription.latest_invoice.payment_intent.status
      );
      throw {
        message: "Your card was declined. Please try a different card to complete the payment"
      };
    } else {
      return { subscription, paymentMethodId };
    }
  }

  function createSubscription({ paymentMethodID }: { paymentMethodID: string }) {
    setProcessing(true);
    return (
      authAxios
        .post(api.payment.createPaymentMethod, {
          paymentMethod: paymentMethodID,
          plan: planID,
          email
        })
        .then((response) => {
          return response.data;
        })
        // If the card is declined, display an error to the user.
        .then((result) => {
          if (result.error) {
            // The card had an error when trying to attach it to a customer
            throw result;
          }
          return result;
        })
        // Normalize the result to contain the object returned
        // by Stripe. Add the additional details we need.
        .then((result) => {
          return {
            // Use the Stripe 'object' property on the
            // returned result to understand what object is returned.
            subscription: result,
            paymentMethodId: paymentMethodID
          };
        })
        // Some payment methods require a customer to do additional
        // authentication with their financial institution.
        // Eg: 2FA for cards.
        .then(handlePaymentThatRequiresCustomerAction)
        // If attaching this card to a Customer object succeeds,
        // but attempts to charge the customer fail. You will
        // get a requires_payment_method error.
        .then(handleRequiresPaymentMethod)
        // No more actions required. Provision your service for the user.
        .then(handleSuccess)
        .catch((error) => {
          // An error has happened. Display the failure to the user here.
          // We utilize the HTML element we created.
          setError(errorHandler(error));
          setProcessing(false);
          setUsingNewCard(true);
        })
    );
  }

  async function handleNewCardPayment({ invoiceId }: { invoiceId?: string | null }) {
    // Step 1: Create a payment method
    setError(null);
    setProcessing(true);
    const cardElement = elements!.getElement(CardElement);
    const { error, paymentMethod } = await stripe!.createPaymentMethod({
      type: "card",
      card: cardElement!,
      billing_details: {
        email: email || undefined
      }
    });
    if (error) {
      setError(error.message);
      setProcessing(false);
    } else if (paymentMethod) {
      if (invoiceId) {
        // Retry Invoice
        retryInvoiceWithNewPaymentMethod(paymentMethod.id, invoiceId);
      } else {
        // Create subscription
        await createSubscription({ paymentMethodID: paymentMethod.id });
      }
    }
  }

  async function handleDefaultPayment({ invoiceId }: { invoiceId?: string | null }) {
    if (invoiceId) {
      // Retry Invoice
      retryInvoiceWithNewPaymentMethod(membership.default_payment_method_id, invoiceId);
    } else {
      // Create subscription
      await createSubscription({ paymentMethodID: membership.default_payment_method_id });
    }
  }

  async function handleSubmit(ev: any) {
    ev.preventDefault();

    const latestInvoicePaymentIntentStatus = localStorage.getItem(
      "latestInvoicePaymentIntentStatus"
    );
    const failedPlanId = localStorage.getItem("planId");

    if (latestInvoicePaymentIntentStatus === "requires_payment_method" && failedPlanId === planID) {
      const invoiceId = localStorage.getItem("latestInvoiceId");

      // create new payment method & retry payment on invoice with new payment method
      if (useAnotherCard) {
        await handleNewCardPayment({ invoiceId });
      } else {
        // or use existing payment method to pay for invoice
        await handleDefaultPayment({ invoiceId });
      }
    } else if (useAnotherCard) {
      // create new payment method & create subscription
      await handleNewCardPayment({ invoiceId: undefined });
    } else {
      // use default payment method
      await handleDefaultPayment({ invoiceId: undefined });
    }
  }

  useEffect(() => {
    if (membership) {
      if (membership.cards.length >= 1) {
        setUsingNewCard(false);
      } else {
        setUsingNewCard(true);
      }
    } else {
      setUsingNewCard(true);
    }
  }, [membership]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function hasFailedInvoice() {
    const failedPlanId = localStorage.getItem("planId");
    return failedPlanId === planID;
  }

  return (
    <div>
      {error && <Message type="ERROR" body={error} onDismiss={() => setError(null)} />}
      {/* {hasFailedInvoice() && ( */}
      {/*  <p className="text-lg py-2 text-red-500"> */}
      {/*    Your previous payment failed. Please try again or use another card */}
      {/*  </p> */}
      {/* )} */}
      <div>
        {plan && (
          <>
            <WithTransition show={success && !mustLogin}>
              <div className="w-full inline-block align-bottom px-4 pt-5 pb-4 text-left overflow-hidden transform transition-all sm:my-8 sm:align-middle">
                <div>
                  <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-500">
                    <TickIcon className="h-6 w-6 text-white" />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                      Payment successful. Thank you!
                    </h3>
                    <div className="mt-2">
                      <div className="text-sm leading-5 text-gray-500">
                        {membership ? (
                          "You are being redirected to your account..."
                        ) : (
                          <div>
                            <span>Please</span>{" "}
                            <NavLink
                              to="/forgot-password"
                              className="text-indigo-500 hover:text-indigo-600 transition duration-300 ease-in-out"
                            >
                              reset your password
                            </NavLink>{" "}
                            to confirm your account
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </WithTransition>
            <WithTransition show={!success && !mustLogin}>
              <form onSubmit={handleSubmit}>
                {membership && membership.cards.length >= 1 && allowDefault ? (
                  <div className="mt-3">
                    <div className="w-full mb-3">
                      <label
                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        htmlFor="grid-state"
                      >
                        Payment Method
                      </label>
                      <div className="relative">
                        <select
                          onChange={() => setUsingNewCard(!useAnotherCard)}
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="grid-state"
                        >
                          <option>Default card</option>
                          <option>New card</option>
                        </select>
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                          <CarrotDownIcon className="fill-current h-4 w-4" />
                        </div>
                      </div>
                    </div>
                    {useAnotherCard ? (
                      <label>
                        <CardElement onChange={handleChange} options={createOptions()} />
                      </label>
                    ) : (
                      <>
                        {membership.cards.map((card: { id: number; default: boolean }) => {
                          return (
                            <div key={card.id}>
                              {card.default && (
                                <PaymentMethodCard
                                  card={card}
                                  cards={membership.cards}
                                  withActions={false}
                                />
                              )}
                            </div>
                          );
                        })}
                      </>
                    )}
                  </div>
                ) : (
                  <div className="mt-3">
                    {!membership && (
                      <div className="py-3">
                        <label
                          htmlFor="email"
                          className="block text-sm font-medium leading-5 text-gray-600"
                        >
                          Email
                        </label>
                        <div className="mt-1 relative rounded-md shadow-sm">
                          <input
                            id="email"
                            type="email"
                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            placeholder="your@email.com"
                            required
                            value={email}
                            onChange={(e) => checkEmail(e.target.value)}
                          />
                        </div>
                      </div>
                    )}
                    <div className="py-3">
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium leading-5 text-gray-600"
                      >
                        Card Info
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <CardElement onChange={handleChange} options={createOptions()} />
                      </div>
                    </div>
                  </div>
                )}
                <Button
                  type="submit"
                  disabled={processing}
                  loading={processing}
                  label={processing ? "Processing…" : "Make Payment"}
                  className={`${
                    processing
                      ? "cursor-not-allowed bg-red-300"
                      : "bg-red-500 hover:bg-red-700 focus:outline-none hover:shadow-outline-red transition duration-300 ease-in-out"
                  } mt-3 w-full items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white transform`}
                />
              </form>
            </WithTransition>
            <WithTransition show={mustLogin}>
              <div className="sm:mx-auto sm:w-full sm:max-w-md text-center">
                <h2 className="mt-6 text-3xl leading-9 font-bold text-gray-900">
                  Login to continue
                </h2>
                <p className="mt-2">
                  Or{" "}
                  <NavLink to="/signup" className="text-blue-500 hover:text-blue-600">
                    create an account
                  </NavLink>
                </p>
              </div>
              <LoginForm
                initialEmail={email}
                onLoginCallback={handleLogin}
                onGoogleLoginCallback={handleLogin}
              />
            </WithTransition>
          </>
        )}
      </div>
    </div>
  );
}

export function CheckoutFormAuth({
  callback,
  plan,
  membership,
  allowDefault = true
}: CheckoutFormAuthProps): JSX.Element {
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm
        plan={plan}
        callback={callback}
        membership={membership}
        allowDefault={allowDefault}
      />
    </Elements>
  );
}

export default function Enroll(): JSX.Element {
  const { planID } = useParams<{ planID: string }>();
  const { user } = useContext(AuthContext);
  const { authAxios } = useContext(AuthContext);

  const [error, setError] = useState(null);
  const [proRata, setProRata] = useState<number>(0);
  const [fetchingProRata, setFetchProRata] = useState(false);
  // const [invoiceDue, setInvoiceDue] = useState<number>(0);
  const [total, setTotal] = useState<number | string>(0);
  const [finishedFetchingMembership, toggleFFM] = useState(false);

  const { data: membership } = useAuthSWR(user ? api.memberships.retrieve : null);
  const { data: merchant } = useAuthSWR(user ? api.memberships.summary : null);
  const { data: plan } = useOldFetch(api.payment.getPlanDetails(planID), null);

  let status: null | string = null;
  if (membership) {
    status = membership.status;
  }

  useEffect(() => {
    if (!user) {
      toggleFFM(true);
      return;
    }
    if (user && membership) {
      toggleFFM(true);
    }
  }, [membership]);

  useEffect(() => {
    let total = 0;
    if (plan) total = plan.amount;
    if (proRata) total = proRata;
    setTotal(total);
  }, [plan, proRata]);

  useEffect(() => {
    async function fetchProRata() {
      if (membership && status && status !== "canceled") {
        try {
          setFetchProRata(true);
          const response = await authAxios.get(api.billing.previewPackageChange(planID));
          setProRata(response.data.preview / 100);
          setFetchProRata(false);
        } catch (err) {
          setFetchProRata(false);
          setError(errorHandler(err));
        }
      }
    }
    fetchProRata();
  }, [membership, status]);

  useEffect(() => {
    // not sure how to handle the due invoice
    async function fetchDueInvoice() {
      if (membership) {
        try {
          await authAxios.get(api.billing.fetchDueInvoice);
          // setInvoiceDue(response.data.amount);
        } catch (err) {
          setError(errorHandler(err));
        }
      }
    }
    fetchDueInvoice();
  }, [membership]);

  // **
  // Important to keep this redirect condition below the useEffects
  // because it will cause a conditional hook
  // **

  // eslint-disable-next-line sonarjs/no-collapsible-if
  if (membership && merchant && membership.planID === planID) {
    if (merchant.status === "active" || merchant.status === "trialing") {
      return <Redirect to="/pricing" />;
    }
  }

  function successfulCheckoutCallback() {
    setTimeout(() => {
      if (user) {
        history.push("/account/transactions");
      }
    }, 2000);
  }

  function proRataDisplay() {
    return proRata.toLocaleString(navigator.language, {
      minimumFractionDigits: 2
    });
  }

  function planAmountDisplay() {
    return plan ? `${plan.amount}.00` : null;
  }

  function totalAmountDisplay() {
    if (typeof total === "string" && total.includes(".")) {
      return total;
    }
    if (typeof total === "number" && total % 1 !== 0) {
      return total;
    }
    return `${total}.00`;
  }

  const showProRata = planID !== PLANS.DJANGO_FREE_TRIAL;

  return (
    <div className="relative bg-white lg:h-screen lg:flex lg:justify-center">
      <div className="absolute inset-0">
        <div className="absolute inset-y-0 left-0 w-1/2 bg-gray-100" />
      </div>
      <div className="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">
        <div className="bg-gray-100 py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
          <div
            className="max-w-lg mx-auto h-full flex flex-col justify-center"
            style={{ minWidth: "300px" }}
          >
            <NavLink
              to="/pricing"
              className="text-gray-500 text-sm hover:text-gray-600 transition duration-300 ease-in-out"
            >
              <div className="flex flex-row items-center">
                <span>← Back to</span>
                <img
                  className="ml-3 cursor-pointer h-6 w-auto sm:h-8"
                  src={Logo}
                  alt="JustDjango"
                />
              </div>
            </NavLink>
            {error && <Message type="ERROR" body={error} onDismiss={() => setError(null)} />}
            {!plan && (
              <div className="w-full mx-auto">
                <div className="animate-pulse flex space-x-4">
                  <div className="flex-1 space-y-4 py-1">
                    <div className="h-4 bg-gray-300 rounded-sm w-1/3" />
                    <div className="h-12 bg-gray-300 rounded-sm w-1/4" />
                    <div className="space-y-2">
                      <div className="h-12 bg-gray-300 rounded-sm" />
                      <div className="h-12 bg-gray-300 rounded-sm" />
                      <div className="h-12 bg-gray-300 rounded-sm" />
                    </div>
                  </div>
                </div>
              </div>
            )}
            {plan && (
              <>
                <p className="mt-10 leading-6 text-gray-500">Enroll for {plan.name}</p>
                <div className="mt-2 flex flex-row items-center">
                  <div>
                    <h2 className="text-3xl leading-8 font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-9">
                      ${plan.amount}
                    </h2>
                  </div>
                  {plan.period === "Monthly" && (
                    <div className="ml-2 text-xs leading-4">
                      per
                      <br />
                      month
                    </div>
                  )}
                </div>
                <div className="mt-8 text-base leading-6 text-gray-500">
                  {fetchingProRata && (
                    <div className="py-3 w-full mx-auto">
                      <div className="animate-pulse flex space-x-4">
                        <div className="flex-1 space-y-4 py-1">
                          <div className="space-y-2">
                            <div className="h-12 bg-gray-300 rounded-sm flex justify-center items-center text-center">
                              <p className="text-xl text-gray-900">Applying Pro Rata</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {proRata > 0 && showProRata ? (
                    <div className="py-3 mt-3 border-gray-200 border-t">
                      <div className="flex flex-row justify-between">
                        <div>
                          <p className="text-lg text-gray-800 font-semibold">Prorata</p>
                        </div>
                        <div>
                          <p className="text-lg text-gray-800 font-semibold">${proRataDisplay()}</p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="flex flex-row justify-between">
                      <div>
                        <p className="text-lg text-gray-800 font-semibold">{plan.name}</p>
                        <p className="text-sm">
                          Billed {plan.period === "Monthly" ? "monthly" : "one time"}
                        </p>
                      </div>
                      <div>
                        <p className="text-lg text-gray-800 font-semibold">
                          ${planAmountDisplay()}
                        </p>
                      </div>
                    </div>
                  )}

                  <div className="py-3 mt-3 border-gray-200 border-t">
                    <div className="flex flex-row justify-between">
                      <div>
                        <p className="text-lg text-gray-800 font-semibold">Subtotal</p>
                      </div>
                      <div>
                        <p className="text-lg text-gray-800 font-semibold">
                          ${proRata > 0 && showProRata ? proRataDisplay() : planAmountDisplay()}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="py-3 mt-3 border-gray-200 border-t">
                    <div className="flex flex-row justify-between">
                      <div>
                        <p className="text-lg text-gray-800 font-semibold">Total due today</p>
                      </div>
                      <div>
                        <p className="text-lg text-gray-800 font-semibold">
                          ${totalAmountDisplay()}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="mt-3" />
                  <Divider />
                  <div>
                    <p className="text-xs text-gray-500">
                      By enrolling you agree to our{" "}
                      <a
                        target="_blank"
                        rel="noreferrer noopener"
                        href="/terms-and-conditions"
                        className="text-blue-500 hover:text-blue-700"
                      >
                        Terms and Conditions
                      </a>{" "}
                      and{" "}
                      <a
                        target="_blank"
                        rel="noreferrer noopener"
                        href="/privacy-policy"
                        className="text-blue-500 hover:text-blue-700"
                      >
                        Privacy Policy
                      </a>
                    </p>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="bg-white py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
          <div className="max-w-lg mx-auto lg:max-w-none h-full flex flex-col justify-center">
            {!finishedFetchingMembership && (
              <div className="w-full mx-auto">
                <div className="animate-pulse flex space-x-4">
                  <div className="flex-1 space-y-4 py-1">
                    <div className="h-4 bg-gray-300 rounded-sm" style={{ width: "80px" }} />
                    <div className="space-y-2">
                      <div className="h-12 bg-gray-300 rounded-sm" />
                      <div className="h-12 bg-gray-300 rounded-sm" />
                    </div>
                  </div>
                </div>
              </div>
            )}
            {finishedFetchingMembership && (
              <CheckoutFormAuth
                plan={plan}
                membership={membership}
                callback={() => successfulCheckoutCallback()}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
