import React from "react";
import { NavLink } from "react-router-dom";

export default function NotFound(): JSX.Element {
  return (
    <div className="bg-gray-100 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">404</h2>
      </div>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white text-center py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <p className="text-black text-xl font-bold">
            We couldn&apos;t find what you were looking for{" "}
            <span role="img" aria-label="Confused?">
              🤔
            </span>
          </p>
          <NavLink to="/">
            <button
              type="button"
              className="w-full mt-3 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
            >
              Head home?
            </button>
          </NavLink>
        </div>
      </div>
    </div>
  );
}
