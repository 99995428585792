import React, { useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import { FaRocket } from "react-icons/fa";
import { AccountShell, CancelSubscriptionForm, PaymentForm } from "src/containers";
import errorHandler, { PLANS } from "src/utils";
import api from "src/api";
import { history, useAuthSWR } from "src/helpers";
import { AuthContext } from "src/contexts";
import { Button, CTA, Divider, Message } from "src/components";
import { Plan, Status } from "src/models";

const DJANGO_FREE_TRIAL_PLAN = "django-free-trial";

const PaypalWaitMessage = () => (
  <h4 className="text-lg">
    Please allow five minutes while we wait for the payment notification from PayPal. If your
    account has not been updated within 5 minutes of your payment, please{" "}
    <NavLink to="/contact" className="text-indigo-500 hover:text-indigo-600">
      contact us
    </NavLink>
    .
  </h4>
);

const FreeTrialUser = ({
  membershipDetails
}: {
  membershipDetails: { paymentMethod: string; status: string; nextBillingDate: string };
}) => {
  return (
    <>
      <div className="mb-5">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Free Trial</h3>
        <div className="mt-2 max-w-xl text-sm leading-5 text-gray-500">
          {membershipDetails.paymentMethod === "PayPal" &&
          membershipDetails.status === "pending" ? (
            <PaypalWaitMessage />
          ) : (
            <p>
              Your free trial
              {membershipDetails.status === "trialing" ? " ends" : " ended"} on{" "}
              {new Date(membershipDetails.nextBillingDate).toDateString()}
            </p>
          )}
        </div>
      </div>
      <Divider />
      <CustomerPortalSection />
      <Divider />
      {membershipDetails.paymentMethod === "PayPal" &&
      membershipDetails.status === "pending" ? null : (
        <CTA />
      )}
    </>
  );
};

const CancelledUser = ({
  member
}: {
  member: {
    planID: string;
  };
}) => {
  return (
    <>
      <div className="mb-5">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Membership ended</h3>
        <div className="mt-2 max-w-xl text-sm leading-5 text-gray-500">
          <p>Your membership has ended</p>
          {member.planID !== PLANS.DJANGO_FREE_TRIAL && (
            <NavLink to="/pricing">
              <button
                type="button"
                className="mt-5 inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
              >
                <FaRocket className="fill-current w-4 h-4 mr-2" />
                Reactivate your membership
              </button>
            </NavLink>
          )}
        </div>
      </div>
      <Divider />
      <CustomerPortalSection />
      <Divider />
      {member.planID === PLANS.DJANGO_FREE_TRIAL && <CTA />}
    </>
  );
};

function CustomerPortalSection() {
  const { authAxios } = useContext(AuthContext);
  const [loadingPortal, setLoadingPortal] = useState(false);
  const [error, setError] = useState(null);

  function handleCustomerPortal() {
    setLoadingPortal(true);
    authAxios
      .post(api.billing.customerPortalSession)
      .then((res) => {
        window.location = res.data.url;
      })
      .catch((err) => {
        setError(errorHandler(err));
      })
      .finally(() => {
        setLoadingPortal(false);
      });
  }

  return (
    <>
      {error && (
        <div className="py-3 mb-3">
          <Message type="ERROR" body={error} onDismiss={() => setError(null)} />
        </div>
      )}
      <div>
        <h3 className="text-lg leading-6 font-medium text-gray-900">Customer Portal</h3>
        <div className="mt-2 max-w-xl text-sm leading-5 text-gray-500">
          <p>Manage your payment methods</p>
        </div>
      </div>

      <div className="mt-5">
        <Button
          onClick={() => handleCustomerPortal()}
          type="button"
          className="mt-3 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-navy-600 hover:bg-navy-500 focus:outline-none focus:border-navy-500 focus:bg-navy-500 transition ease-in-out duration-150"
          label="Launch Portal"
          loading={loadingPortal}
        />

        {/* {membershipDetails && membershipDetails.cards && (
          <PaymentMethods
            paypal={membershipDetails.paymentMethod === "PayPal"}
            cards={membershipDetails.cards}
            handleFetchData={() => handleFetchData()}
          />
        )} */}
      </div>
    </>
  );
}

const EnrolledUser = ({
  membershipDetails,
  handleFetchData
}: {
  membershipDetails: {
    paymentMethod: string;
    status: string;
    nextBillingDate: string;
    cancellation?: boolean;
    planName: string;
    planID: string;
  };
  handleFetchData: () => Promise<boolean>;
}) => {
  const [stoppingCancellation, setStopping] = useState(false);
  const [error, setError] = useState(null);
  const { authAxios } = useContext(AuthContext);

  const handleStopCancellation = () => {
    setStopping(true);
    authAxios
      .post(api.memberships.stopCancellation)
      .then(() => {
        setStopping(false);
        handleFetchData();
      })
      .catch((err) => {
        setStopping(false);
        setError(errorHandler(err));
      });
  };

  return (
    <div>
      {error && <Message type="ERROR" body={error} onDismiss={() => setError(null)} />}
      {membershipDetails.paymentMethod === "PayPal" && membershipDetails.status === "pending" && (
        <div className="mb-3">
          <PaypalWaitMessage />
        </div>
      )}
      {!membershipDetails.cancellation && (
        <div>
          <h3 className="text-lg leading-6 font-medium text-gray-900">Membership</h3>
          <div className="mt-2 max-w-xl text-sm leading-5 text-gray-500">
            <p>
              Your next billing date is {new Date(membershipDetails.nextBillingDate).toDateString()}
            </p>
          </div>
        </div>
      )}
      <div className={`${!membershipDetails.cancellation ? "mt-5" : ""}`}>
        <p className="text-md text-gray-800">
          Current package:{" "}
          <span className="font-bold text-gray-600">{membershipDetails.planName}</span>
        </p>
      </div>

      <NavLink to="/pricing">
        <button
          type="button"
          className="mt-5 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-aqua-600 hover:bg-aqua-500 focus:outline-none focus:border-aqua-500 focus:bg-aqua-500 transition ease-in-out duration-150"
        >
          <FaRocket className="fill-current w-4 h-4 mr-2" />
          {membershipDetails.planID === DJANGO_FREE_TRIAL_PLAN
            ? "Upgrade package"
            : "Change package"}
        </button>
      </NavLink>

      <Divider />

      <CustomerPortalSection />

      <Divider />

      <div>
        {membershipDetails.cancellation ? (
          <>
            <Button
              onClick={() => handleStopCancellation()}
              disabled={stoppingCancellation}
              loading={stoppingCancellation}
              type="button"
              className={`${
                stoppingCancellation ? "bg-red-500" : "bg-red-600 hover:bg-red-500"
              } inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-base leading-6 font-medium text-white shadow-sm focus:outline-none focus:border-red-700 focus:shadow-outline-red transition ease-in-out duration-150 sm:text-sm sm:leading-5`}
              label={stoppingCancellation ? "Stopping cancellation" : "Stop cancellation"}
            />
            <p className="mt-2 text-gray-500">
              Your access to the content on JustDjango will end on{" "}
              {new Date(membershipDetails.nextBillingDate).toDateString()}
            </p>
          </>
        ) : (
          <CancelSubscriptionForm callback={() => handleFetchData()} />
        )}
      </div>
    </div>
  );
};

const PastDueUser = ({ planID, status }: { planID: Plan; status: Status }) => {
  return (
    <>
      <div className="mb-5">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Get back to learning!</h3>
        <div className="mt-2 max-w-xl text-sm leading-5 text-gray-500">
          <p>
            Your previous payment failed. To continue with your membership, pay with a different
            card.
          </p>
        </div>
        <div className="mt-3">
          <PaymentForm
            planID={planID}
            callback={() => history.push("/account/transactions")}
            currentPlan={planID}
            status={status}
            allowDefault={false}
            payingDueInvoice
          />
        </div>
      </div>
    </>
  );
};

function SkeletonLoader() {
  return (
    <div className="w-full mx-auto">
      <div className="animate-pulse flex space-x-4">
        <div className="flex-1 space-y-4 py-1">
          <div className="h-4 bg-gray-300 rounded-sm" style={{ width: "100px" }} />
          <div className="h-3 bg-gray-300 rounded-sm w-1/3" />
          <div className="mt-2 h-3 bg-gray-300 rounded-sm w-1/3" />
          <div className="space-y-2">
            <div className="h-12 bg-gray-300 rounded-sm w-1/4" />
          </div>
        </div>
      </div>
      <Divider />
      <div className="animate-pulse flex space-x-4">
        <div className="flex-1 space-y-4 py-1">
          <div className="h-4 bg-gray-300 rounded-sm" style={{ width: "100px" }} />
          <div className="h-3 bg-gray-300 rounded-sm w-1/3" />
          <div className="space-y-2">
            <div className="h-12 bg-gray-300 rounded-sm w-1/4" />
          </div>
        </div>
      </div>
      <Divider />
      <div className="animate-pulse flex space-x-4">
        <div className="flex-1 space-y-4 py-1">
          <div className="h-4 bg-gray-300 rounded-sm" style={{ width: "100px" }} />
          <div className="h-3 bg-gray-300 rounded-sm w-1/3" />
          <div className="mt-2 h-3 bg-gray-300 rounded-sm w-1/3" />
          <div className="space-y-2">
            <div className="h-12 bg-gray-300 rounded-sm w-1/4" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default function AccountSubscription(): JSX.Element {
  const { data, error, revalidate } = useAuthSWR(api.memberships.retrieve);
  const member: {
    planID: Plan;
    status: Status;
    paymentMethod: string;
    nextBillingDate: string;
    cancellation?: boolean;
    planName: string;
    lifetime: boolean;
  } = data;
  return (
    <AccountShell>
      <div className="pb-5 border-b border-gray-200">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Subscription</h3>
        <p className="mt-2 max-w-4xl text-sm text-gray-500">
          Manage your subscription and payment methods.
        </p>
      </div>
      <div className="mt-6">
        {error && <Message type="ERROR" body={error} noDismiss />}
        {!member && <SkeletonLoader />}
        {member && (
          <>
            {member.lifetime ? (
              <EnrolledUser membershipDetails={member} handleFetchData={() => revalidate()} />
            ) : (
              <>
                {member.status === "past_due" && (
                  <PastDueUser status={member.status} planID={member.planID} />
                )}
                {member.status === "canceled" && <CancelledUser member={member} />}
                {(member.status === "active" || member.status === "trialing") && (
                  <>
                    {member.planID === PLANS.DJANGO_FREE_TRIAL && (
                      <FreeTrialUser membershipDetails={member} />
                    )}
                    {member.planID !== PLANS.DJANGO_FREE_TRIAL && (
                      <EnrolledUser
                        membershipDetails={member}
                        handleFetchData={() => revalidate()}
                      />
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
    </AccountShell>
  );
}
