import React from "react";
import { NavLink, useLocation } from "react-router-dom";

const links = [
  {
    sectionTitle: "Account",
    subSections: [{ title: "Profile", path: "/account/profile" }]
  },
  {
    sectionTitle: "Billing",
    subSections: [
      { title: "Subscription", path: "/account/subscription" },
      { title: "Transactions", path: "/account/transactions" }
    ]
  }
];

export function AccountShell({ children }: { children: any }): JSX.Element {
  const { pathname } = useLocation();
  return (
    <div className="max-w-7xl mx-auto px-4 py-4 sm:px-6 lg:px-8">
      <div className="flex flex-wrap">
        <div className="w-full sm:w-1/3 md:w-1/3 lg:w-1/4 mb-4">
          <nav>
            <div className="pt-5">
              <div>
                {links.map((section, i) => {
                  return (
                    <div key={section.sectionTitle} className={i > 0 ? "mt-5" : ""}>
                      <h3 className="px-3 text-xs leading-4 font-semibold text-gray-500 uppercase tracking-wider">
                        {section.sectionTitle}
                      </h3>
                      {section.subSections.map((subSection) => {
                        return (
                          <NavLink
                            key={subSection.path}
                            to={subSection.path}
                            className={
                              pathname === subSection.path
                                ? "group flex items-center px-3 py-2 text-sm leading-5 font-medium text-gray-600 bg-gray-300 rounded-md hover:text-gray-900 focus:outline-none focus:text-gray-900 focus:bg-gray-100 transition ease-in-out duration-150"
                                : "group flex items-center px-3 py-2 text-sm leading-5 font-medium text-gray-600 rounded-md hover:text-gray-900 focus:outline-none hover:bg-gray-100 focus:text-gray-900 focus:bg-gray-100 transition ease-in-out duration-150"
                            }
                          >
                            <span className="truncate">{subSection.title}</span>
                          </NavLink>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            </div>
          </nav>
        </div>
        <div className="w-full sm:w-2/3 md:w-2/3 lg:w-3/4 mb-4">
          <div className="px-5 py-5">{children}</div>
        </div>
      </div>
    </div>
  );
}
