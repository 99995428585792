import React, { useContext, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { CardElement, Elements, useStripe, useElements } from "@stripe/react-stripe-js";
import "../../assets/css/stripe.css";
import { Divider, Message } from "src/components";
import errorHandler from "src/utils";
import api from "src/api";
import { AuthContext } from "src/contexts";
import { STRIPE_PUBLIC_KEY } from "src/config";
import { createOptions, SecureInfo } from "./common";

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

function CheckoutForm({ callback }: { callback: (paymentMethod: any) => void }) {
  const [error, setError] = useState<any>(null);
  const [processing, setProcessing] = useState<boolean>(false);
  const { authAxios } = useContext(AuthContext);
  const stripe = useStripe();
  const elements = useElements();

  const handleChange = ({ error }: { error: any }) => {
    if (error) setError(error.message);
  };

  const handleError = (err: any) => {
    setError(errorHandler(err));
    setProcessing(false);
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (
    ev: React.FormEvent<HTMLFormElement>
  ) => {
    ev.preventDefault();
    setError(null);
    setProcessing(true);
    const cardElement = elements!.getElement(CardElement);
    const { error, paymentMethod } = await stripe!.createPaymentMethod({
      type: "card",
      card: cardElement!
    });

    if (error) {
      handleError(error.message);
    } else if (paymentMethod) {
      authAxios
        .post(api.billing.addPaymentMethod, { paymentMethod: paymentMethod.id })
        .then(() => {
          setError(null);
          setProcessing(false);
          callback(paymentMethod);
        })
        .catch((err: any) => handleError(err))
        .finally(() => setProcessing(false));
    }
  };

  return (
    <div className="sr-form-row">
      <div className="px-4 py-4 mt-3 mb-3">
        <form onSubmit={handleSubmit}>
          <label>
            <CardElement onChange={handleChange} {...createOptions()} />
          </label>
          {error && <Message type="ERROR" body={error} onDismiss={() => setError(null)} />}
          <button
            disabled={processing}
            type="submit"
            className={`mt-3 ${
              processing ? "bg-gray-200 cursor-not-allowed" : "bg-gray-300 hover:bg-gray-400"
            } w-full text-center text-gray-800 font-bold py-2 px-4 rounded items-center transition ease-in-out duration-300`}
          >
            <span>{processing ? "Processing…" : "Add Card"}</span>
          </button>
          <Divider />
          <SecureInfo />
        </form>
      </div>
    </div>
  );
}

export function AddCardForm({
  callback
}: {
  callback: (paymentMethod: string) => void;
}): JSX.Element {
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm callback={callback} />
    </Elements>
  );
}
