import React, { useContext, useState } from "react";
import { NavLink, Redirect, useParams } from "react-router-dom";
import { Formik, Form } from "formik";
import axios from "axios";
import * as Yup from "yup";
import { Loader, RequiredField, Message } from "src/components";
import api from "src/api";
import { AuthContext } from "src/contexts";

export default function ConfirmPasswordReset(): JSX.Element {
  const [error, setError] = useState<any>(null);
  const [success, setSuccess] = useState<boolean>(false);
  const { uid, token } = useParams<{ uid: string; token: string }>();

  const { user } = useContext(AuthContext);
  if (user) {
    return <Redirect to={{ pathname: "/dashboard" }} />;
  }

  return (
    <div className="bg-gray-100 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">
          Change your password
        </h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          {error && <Message type="ERROR" body={error} onDismiss={() => setError(null)} />}
          <Formik
            initialValues={{ password: "", confirmPassword: "" }}
            validationSchema={Yup.object({
              password: Yup.string().min(8, "Must be 8 characters or more").required("Required"),
              confirmPassword: Yup.string()
                .min(8, "Must be 8 characters or more")
                .required("Required")
                .oneOf([Yup.ref("password")], "Passwords must match")
            })}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              setSubmitting(true);
              axios
                .post(api.auth.resetPasswordConfirm, {
                  new_password1: values.password,
                  new_password2: values.confirmPassword,
                  uid,
                  token
                })
                .then(() => {
                  setSubmitting(false);
                  setSuccess(true);
                })
                .catch(() => {
                  const err =
                    "You did not request to reset your password. Click the 'forgot password' link below to reset your password.";
                  setError(err);
                })
                .finally(() => {
                  setSubmitting(false);
                  resetForm();
                });
            }}
          >
            {(formik) => (
              <Form>
                {success && (
                  <Message
                    type="SUCCESS"
                    body="Your password has successfully been reset. You can continue to login."
                    onDismiss={() => setSuccess(false)}
                  />
                )}

                <RequiredField
                  label="New password"
                  formik={formik}
                  fieldName="password"
                  fieldType="password"
                />

                <div className="mt-6">
                  <RequiredField
                    label="Confirm password"
                    formik={formik}
                    fieldName="confirmPassword"
                    fieldType="password"
                  />
                </div>

                <div className="mt-6 flex items-center justify-between">
                  <div className="text-sm leading-5">
                    <NavLink
                      to="/login"
                      className="font-medium text-red-600 hover:text-red-500 focus:outline-none focus:underline transition ease-in-out duration-150"
                    >
                      Already have an account?
                    </NavLink>
                  </div>
                </div>

                <div className="mt-6">
                  <span className="block w-full rounded-md shadow-sm">
                    {formik.isSubmitting ? (
                      <button
                        disabled
                        type="button"
                        className="disabled w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red active:bg-red-700 transition duration-150 ease-in-out"
                      >
                        <Loader />
                        Submitting
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red active:bg-red-700 transition duration-150 ease-in-out"
                      >
                        Submit
                      </button>
                    )}
                  </span>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
