import React from "react";
import { NavLink } from "react-router-dom";
import { Line } from "rc-progress";
import { parseTime } from "src/utils";
import { Course, CourseProgress } from "src/models";
import { CircleTickIcon } from "./icons";
import { useAuthSWR } from "../helpers";
import api from "../api";

export function SummaryCourseItem({
  course,
  courseProgress
}: {
  course: Course;
  courseProgress: CourseProgress;
}): JSX.Element {
  const { data: courseRecommendation } = useAuthSWR(api.courses.nextLecture(course.slug));

  return (
    <li>
      <NavLink
        to={`/courses/${course.slug}/${
          courseRecommendation ? courseRecommendation.next_lecture_slug : course.first_lecture_slug
        }`}
        className="block hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
      >
        <div className="flex items-center px-1 py-3">
          <div className="min-w-0 flex-1 flex items-center">
            <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-1 md:gap-4">
              <div>
                <div className="text-sm leading-5 font-medium text-indigo-600 truncate">
                  {course.title}
                </div>
                <div className="text-sm leading-5 text-gray-900">
                  {courseProgress.progress < 100 && (
                    <Line
                      percent={courseProgress.progress ? courseProgress.progress : 0}
                      strokeWidth={1}
                      strokeColor="#252f3f"
                    />
                  )}
                </div>
                <div className="mt-2 flex justify-between text-sm leading-5 text-gray-500">
                  <span className="truncate">
                    {course.experience} XP | {parseTime(course.length)}
                  </span>
                  <span className="float-right right-0">
                    {courseProgress.progress === 100 ? (
                      <div className="flex items-center text-sm leading-5 text-gray-500">
                        <CircleTickIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400" />
                        Completed
                      </div>
                    ) : (
                      <>{courseProgress.progress ? courseProgress.progress : 0}%</>
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </NavLink>
    </li>
  );
}
